import { HTML_TAGS } from '~/constants/regex'

export const capitalize = (string: string) => {
  if (typeof string !== 'string') {
    console.error('capitalize: input is not a string')
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const revertUpperCase = (text: string) => {
  try {
    return text
      .split('.')
      .map((txt: string) => (txt == txt.toUpperCase() ? capitalize(txt.toLowerCase()) : txt))
      .join('. ')
  } catch (e) {
    console.error(e)
  }
  return text
}

export const capitalizeAll = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
}

export const removeHtmlTagsFromString = (string: string): string => string.replace(HTML_TAGS, ' ')

export const addHtmlTagFromString = (input: string, target: string, tag: string): string => {
  const openingTag = `<${tag}>`
  const closingTag = `</${tag}>`
  const regex = new RegExp(target, 'g')

  return input.replace(regex, `${openingTag}${target}${closingTag}`)
}
