import { fetchTranslation } from '~/api/translations.ts'
import { getLanguageCodeFromRoute } from '~/utils/languages.js'

// i18n by default try to use browser language pt for Eric, it's only in case where there is no language set in the URL(default language)
export default defineNuxtRouteMiddleware(async (to, from) => {
  const pageId = to.meta.pageId // new shop routes do not have item id,  shop translations come with common translations

  if (!pageId) {
    // No page id mean that there will be no translation to fetch
    console.error('No pageId found in route meta:', to.path)
    return // Exits if no pageId is found
  }

  const nuxtApp = useNuxtApp()
  const languageCode = getLanguageCodeFromRoute(to)

  try {
    const pageTranslation = await fetchTranslation({ id: pageId, languageCode })
    if (!pageTranslation) {
      console.error('No translation found for', `pageId:${pageId}, localeCode: ${languageCode}`)
      return // Exits if no translation file is found
    }

    const translation = nuxtApp.$i18n.getLocaleMessage(languageCode)
    nuxtApp.$i18n.setLocaleMessage(languageCode, { ...translation, ...pageTranslation }) // Merges and sets the locale messages
  } catch (e) {
    console.error(e) // Logs any errors encountered during fetching or setting the translation
  }
})
