import { getCategory } from '@/utils/shop/categories.js'
import { getSubCategory } from '@/utils/shop/subCategories.js'
import { getMetalById } from '@/utils/shop/metals.js'

let gglTrackFirstCall = true
let gglTrackLastCalls = {}
const ga_options = {}
let ga_event_lastStep = ''

export const resetGglTrackCalls = () => {
  gglTrackLastCalls = {}
}

export const setGglTrackFirstCall = (value) => {
  gglTrackFirstCall = Boolean(value)
}

export const gglInit = (type, ...params) => {
  gglTrackLastCalls[type] = params
}

export const gglTrack = (type, ...params) => {
  params = params.filter((v) => v !== null)

  if (
    gglTrackFirstCall ||
    (gglTrackLastCalls[type] &&
      params.length === gglTrackLastCalls[type].length &&
      params.every((value, index) => value === gglTrackLastCalls[type][index]))
  ) {
    gglTrackFirstCall = false
    return
  }

  gglInit(type, ...params)

  try {
    let category = type.replace(/(^.*\[|\].*$)/g, '')
    let changeType = type.replace(/\[.+\]/i, '')
    const acceptedChangeTypes = ['FilterChanged', 'DiamondFilterChanged']

    if (changeType && acceptedChangeTypes.includes(changeType)) {
      let valueText = ''
      if (category && category.length > 0 && params && params.length > 0) {
        let values = params[params.length - 1]
        if (values) {
          if (values.includes(',')) {
            values = values.split(',')
            if (values && values.length > 0) {
              valueText = `${values[0]} to ${values[values.length - 1]}`
            } else {
              valueText = values[0]
            }
          } else {
            valueText = values
          }
          window?.DataLayer?.productListingFilterInteraction(category, valueText, 'Add')
        }
      }
    }

    window?.gglTrack[type.replace(/\[.+\]/i, '')](...params)
  } catch (e) {
    console.warn(`error calling gglTrack.${type}`, params)
  }
}

export const ga_event_init = (productCategory, configurable) => {
  ga_options.productCategory = productCategory
  ga_options.configurable = configurable

  ga_event({
    category: `product experience | ${productCategory}`,
    action: `start with | ${configurable}`,
    label: '',
    value: 0,
    nonInteraction: 'true',
    pageType: 'Shop',
  })
}

export const ga_event_quick_init = (category, action) => {
  ga_event({
    category: `${category}`,
    action: `${action}`,
    label: '',
    value: 0,
    nonInteraction: 'true',
    pageType: 'Shop',
  })
}

export const ga_event_popups = (action, label) => {
  ga_event({
    category: '',
    action: action,
    label: label,
    value: 0,
    nonInteraction: 'true',
    pageType: 'Shop',
  })
}

export const ga_event = (event) => {
  if (!event.label) {
    event.label = ''
  }

  window.dataLayer = window.dataLayer || []
  dataLayerPush({
    event: 'ga_event',
    ga_event: event,
    eventCallback: function (containerId) {
      if (containerId === 'GTM-P86RX2') {
        // <<callback code>>
      }
    },
  })

  if (event && event.category === 'virtual ring styler interaction') {
    ga4_virtualRingStylerInteractions(event.action, event.value)
  }
}

export const ga_event_flow = (step) => {
  if (step !== ga_event_lastStep) {
    ga_event({
      category: `product experience | ${ga_options.productCategory}`,
      action: `flow | start with | ${ga_options.configurable}`,
      label: `step completed | ${step}`,
      value: 0,
      nonInteraction: 'true',
    })
  }

  ga_event_lastStep = step
}

export const dataLayerPush = (data) => {
  try {
    if (window?.DataLayer) {
      window.DataLayer.push(data)
    }
  } catch (error) {
    console.warn(`error calling datalayer.dataLayerPush`, data)
  }
}

export const ga4_virtualRingStylerInteractions = (action, selection) => {
  try {
    dataLayerPush({
      event: 'virtualRingStylerInteractions',
      virtualRingStyler: {
        action: action,
        selection: selection,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_virtualRingStylerInteractions`, action, selection)
  }
}

export const ga4FilterReset = (category = '', value) => {
  try {
    let valueText = ''
    if (value) {
      if (value.includes(',')) {
        value = value.split(',')
        valueText = `${value[0]} to ${value[value.length - 1]}`
      } else if (value.length === 0) {
        valueText = ''
      } else {
        valueText = value
      }
    }
    window?.DataLayer?.productListingFilterInteraction(category, valueText, 'Remove')
  } catch (error) {
    console.warn(`error calling datalayer.${category}`, value)
  }
}

export const ga4_madeByYouStepChange = () => {
  const nuxtApp = window.useNuxtApp() // window mean that this useNuxtApp one can be used only on client side
  const { t } = nuxtApp.$i18n
  const route = nuxtApp._route
  try {
    const { name, query } = route
    let step = '1'
    let stepName = ''
    let category = t(getCategory(query.category).label)

    if (name === 'item' || name === 'category') {
      if (query.diamond) step = '2'
      else step = '1'

      stepName = 'Select Your Setting'
    } else if (name.indexOf('diamond') !== -1) {
      if (query.diamond) step = '1'
      else step = '2'
      stepName = 'Diamond'
    } else if (name === 'item-finish') {
      step = '3'
      stepName = 'Finish'
    }

    dataLayerPush({
      event: 'madeByYouStepChange',
      madeByYou: {
        stepNumber: step,
        stepName: stepName,
        productType: category,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.madeByYouStepChange`, route)
  }
}

export const ga4_productReviewInteraction = (action) => {
  try {
    dataLayerPush({
      event: 'productReviewInteraction',
      productReview: {
        action: action,
      },
    })
  } catch (error) {
    console.warn(`error calling datalayer.ga4_productReviewInteraction`, action)
  }
}

export const ga4_productObject = (event = '', item = null, index = '', items = null, diamonds = null) => {
  try {
    if (item) {
      const product = getProductDataLayer(item, index)

      dataLayerPush({
        event: event,
        product: [
          {
            ...product,
          },
        ],
      })
    } else if (items && items.length > 0) {
      let products = []
      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        const product = getProductDataLayer(item, index)
        products.push(product)
      }

      dataLayerPush({
        event: event,
        product: products,
      })
    } else if (diamonds && diamonds.length > 0) {
      let products = []
      for (let index = 0; index < diamonds.length; index++) {
        const diamond = diamonds[index]
        const product = getDiamondDataLayer(diamond, index)
        products.push(product)
      }

      dataLayerPush({
        event: event,
        product: products,
      })
    }
  } catch (error) {
    console.log(error)
    console.warn(`error calling datalayer.ga4_productObject`, event)
  }
}

export const getProductDataLayer = (item, index) => {
  const nuxtApp = window.useNuxtApp() // window mean that this useNuxtApp one can be used only on client side

  const { t } = nuxtApp.$i18n
  const route = nuxtApp._route
  const { query } = route
  let category = capitalize(t(getCategory(query.category).label)) || ''
  let itemCategory = capitalize(t(getCategory(item.Category || item.CategoryId).item_label)) || ''
  let subCategory = capitalize(t(getSubCategory(item.SubCategory || item.SubCategoryId).label)) || ''
  let itemSubCategory = capitalize(t(getSubCategory(item.SubCategory || item.SubCategoryId).item_label)) || ''
  let metal = capitalize(t(getMetalById(item.Metal || query.metal).label))
  let price = item.Price.Price
  let name = item.Name
  let discount = ''

  if (query && query.diamond) {
    name = `${name} with ${query.diamond}`
  } else if (price && price.DiamondsPrice && price.DiamondsPrice.length > 0) {
    let diamonds = price.DiamondsPrice.map((d) => d.UniqueCode).join(', ')
    name = `${name} with ${diamonds}`
  }

  if (query && query['discount-code']) {
    discount = query['discount-code']
  }

  return {
    id: item.Id,
    name: name,
    brand: '77Diamonds',
    price: price.TotalPrice.FinalPrice.NumericPrice.WithVat,
    quantity: '1',
    index: index.toString(),
    promotionName: '',
    discount: discount,
    affiliation: '',
    itemListName: category || itemCategory || '',
    categories: [itemSubCategory, itemCategory, subCategory],
    variant: [metal, item.DiamondShape ? 'Diamond' : ''],
  }
}

export const getDiamondDataLayer = (item, index) => {
  const route = useRoute()
  const { query } = route
  let shape = item.ShapeName
  let stoneType = item.StoneTypeName
  let price = item.FinalSalePrice
  let name = item.Code
  let discount = ''

  if (query && query['discount-code']) {
    discount = query['discount-code']
  }

  return {
    id: item.Id,
    name: name,
    brand: '77Diamonds',
    price: price,
    quantity: '1',
    index: index.toString(),
    promotionName: '',
    discount: discount,
    affiliation: '',
    itemListName: stoneType,
    categories: ['Diamond'],
    variant: [shape, stoneType],
  }
}
