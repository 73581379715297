import { default as addressesIGZCkwNaoqMeta } from "/src/pages/account/addresses.vue?macro=true";
import { default as ordersF1jXyywrUuMeta } from "/src/pages/account/orders.vue?macro=true";
import { default as privacyaTLU6InpJAMeta } from "/src/pages/account/privacy.vue?macro=true";
import { default as profile4xkbZgafKZMeta } from "/src/pages/account/profile.vue?macro=true";
import { default as bespoke_45jewellery4QrHxVP7J4Meta } from "/src/pages/bespoke-jewellery.vue?macro=true";
import { default as bespoke_45standout_45pieces0xvUJlJ6VkMeta } from "/src/pages/bespoke-standout-pieces.vue?macro=true";
import { default as index4E3N5yzW7cMeta } from "/src/pages/checkout/index.vue?macro=true";
import { default as cushion_45cut_45diamondsMjTpfcAnoCMeta } from "/src/pages/contour/cushion-cut-diamonds.vue?macro=true";
import { default as index6DYUkaVRdpMeta } from "/src/pages/contour/index.vue?macro=true";
import { default as contact71Rz830P7VMeta } from "/src/pages/customer-care/contact.vue?macro=true";
import { default as customer_45care9rqGcfFi8SMeta } from "/src/pages/customer-care/customer-care.vue?macro=true";
import { default as delivery_45and_45returnsSmuhRzs1mpMeta } from "/src/pages/customer-care/delivery-and-returns.vue?macro=true";
import { default as faqAaQGpbjedXMeta } from "/src/pages/customer-care/faq.vue?macro=true";
import { default as finance_45calculator0UvxeF1EDXMeta } from "/src/pages/customer-care/finance-calculator.vue?macro=true";
import { default as terms_45conditionsMMKCzXZeIIMeta } from "/src/pages/customer-care/terms-conditions.vue?macro=true";
import { default as customer_45careOdTE0K9no0Meta } from "/src/pages/customer-care.vue?macro=true";
import { default as diamond_45jewelleryaTnquFRy8QMeta } from "/src/pages/diamond-jewellery.vue?macro=true";
import { default as diamondsZSRK9W2p7zMeta } from "/src/pages/diamonds.vue?macro=true";
import { default as _77diamonds_45press1HaEIkx1wYMeta } from "/src/pages/education/diamond-education/77diamonds-press.vue?macro=true";
import { default as about_4577diamondsw3d5Fwoq4bMeta } from "/src/pages/education/diamond-education/about-77diamonds.vue?macro=true";
import { default as anatomy_45of_45a_45diamondKDwHf7DGzNMeta } from "/src/pages/education/diamond-education/anatomy-of-a-diamond.vue?macro=true";
import { default as awards_45detailsxMKpp7x4pjMeta } from "/src/pages/education/diamond-education/awards-details.vue?macro=true";
import { default as awardsYoSh66TkpzMeta } from "/src/pages/education/diamond-education/awards.vue?macro=true";
import { default as canadian_45diamonds2VOU9iCF2pMeta } from "/src/pages/education/diamond-education/canadian-diamonds.vue?macro=true";
import { default as careershFQmXK4dYZMeta } from "/src/pages/education/diamond-education/careers.vue?macro=true";
import { default as clarity_45enhanced_45diamondscV4P7me4guMeta } from "/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue?macro=true";
import { default as compare_45diamond_45certificatesxEsX9RG1oAMeta } from "/src/pages/education/diamond-education/compare-diamond-certificates.vue?macro=true";
import { default as cost6ozuBJSlf2Meta } from "/src/pages/education/diamond-education/cost.vue?macro=true";
import { default as diamond_45buying_45guideGZh1b8WPHiMeta } from "/src/pages/education/diamond-education/diamond-buying-guide.vue?macro=true";
import { default as diamond_45carat_45weighta3NL3YESYyMeta } from "/src/pages/education/diamond-education/diamond-carat-weight.vue?macro=true";
import { default as diamond_45certificatesFor2vqS7ZEMeta } from "/src/pages/education/diamond-education/diamond-certificates.vue?macro=true";
import { default as diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta } from "/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue?macro=true";
import { default as diamond_45clarity_45gradingVaHtQ0Cum7Meta } from "/src/pages/education/diamond-education/diamond-clarity-grading.vue?macro=true";
import { default as diamond_45clarity6TuJiBiLm8Meta } from "/src/pages/education/diamond-education/diamond-clarity.vue?macro=true";
import { default as diamond_45colourZxx2jRBd2MMeta } from "/src/pages/education/diamond-education/diamond-colour.vue?macro=true";
import { default as diamond_45cut_45gradingAmAANIKe6JMeta } from "/src/pages/education/diamond-education/diamond-cut-grading.vue?macro=true";
import { default as diamond_45cut_45process1c1Q83k8V5Meta } from "/src/pages/education/diamond-education/diamond-cut-process.vue?macro=true";
import { default as diamond_45cut7O4tfrzeGnMeta } from "/src/pages/education/diamond-education/diamond-cut.vue?macro=true";
import { default as diamond_45fluorescence78IftR77O9Meta } from "/src/pages/education/diamond-education/diamond-fluorescence.vue?macro=true";
import { default as diamond_45grading_45labsFUVIJ3Cms8Meta } from "/src/pages/education/diamond-education/diamond-grading-labs.vue?macro=true";
import { default as diamond_45inclusionsFZ76RqsMSyMeta } from "/src/pages/education/diamond-education/diamond-inclusions.vue?macro=true";
import { default as diamond_45shapesh5NiDRFuzeMeta } from "/src/pages/education/diamond-education/diamond-shapes.vue?macro=true";
import { default as engagement_45buying_45guideu9ZaZfmYrTMeta } from "/src/pages/education/diamond-education/engagement-buying-guide.vue?macro=true";
import { default as ethical_45diamonds92QNeSVTOgMeta } from "/src/pages/education/diamond-education/ethical-diamonds.vue?macro=true";
import { default as fancy_45coloured_45diamondsV32sAu20k5Meta } from "/src/pages/education/diamond-education/fancy-coloured-diamonds.vue?macro=true";
import { default as hearts_45arrowswfOY44xKKxMeta } from "/src/pages/education/diamond-education/hearts-arrows.vue?macro=true";
import { default as how_45are_45diamonds_45cutpj69rrIjOvMeta } from "/src/pages/education/diamond-education/how-are-diamonds-cut.vue?macro=true";
import { default as how_45are_45diamonds_45formed5C6zMSF9GtMeta } from "/src/pages/education/diamond-education/how-are-diamonds-formed.vue?macro=true";
import { default as indexEyNi2vxOyuMeta } from "/src/pages/education/diamond-education/index.vue?macro=true";
import { default as our_45storyVpRIh9HvHkMeta } from "/src/pages/education/diamond-education/our-story.vue?macro=true";
import { default as ring_45size_45chartEQaQIyTSabMeta } from "/src/pages/education/diamond-education/ring-size-chart.vue?macro=true";
import { default as asscher_45cut_45diamondsq10W5OJc38Meta } from "/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue?macro=true";
import { default as cushion_45cut_45diamondsu9bB9UWG2aMeta } from "/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue?macro=true";
import { default as emerald_45cut_45diamondsUjVMFP8BVWMeta } from "/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue?macro=true";
import { default as heart_45cut_45diamondsO0iXVVR7roMeta } from "/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue?macro=true";
import { default as marquise_45cut_45diamondswGUzXwhTvgMeta } from "/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue?macro=true";
import { default as oval_45cut_45diamondsWKywHggYmjMeta } from "/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue?macro=true";
import { default as pear_45shape_45diamondsjNfS1pVLPrMeta } from "/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue?macro=true";
import { default as princess_45cut_45diamonds7eeGYHoXWKMeta } from "/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue?macro=true";
import { default as radiant_45cut_45diamondsASR0NsemcqMeta } from "/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue?macro=true";
import { default as round_45diamondsHMSruvXralMeta } from "/src/pages/education/diamond-education/shapes/round-diamonds.vue?macro=true";
import { default as trillion_45cut_45diamondsoVzGPJdptLMeta } from "/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue?macro=true";
import { default as testimonialsOP6UGSIAzNMeta } from "/src/pages/education/diamond-education/testimonials.vue?macro=true";
import { default as indexwXj5m7cZh6Meta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue?macro=true";
import { default as introductionioT4hAg28aMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue?macro=true";
import { default as mixed_45cutKWTO0JrL7DMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue?macro=true";
import { default as modified_45brilliant_45cut4W7QXTA9YyMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue?macro=true";
import { default as old_45mine_45old_45european_45cutMoBfHQBlGhMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue?macro=true";
import { default as round_45brilliant_45cutyrMYHzCbVVMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue?macro=true";
import { default as step_45cutqoz9sE34rXMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue?macro=true";
import { default as diamond_45educationMW6QW1Y00NMeta } from "/src/pages/education/diamond-education.vue?macro=true";
import { default as failedbrcbjYHVDlMeta } from "/src/pages/failed.vue?macro=true";
import { default as finance_45informationRY9DSBrTx3Meta } from "/src/pages/finance-information.vue?macro=true";
import { default as forgot_45passwordhkQDFGWAYaMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as guideoeMlliXxVDMeta } from "/src/pages/gemstones/emerald/guide.vue?macro=true";
import { default as guide80BdYUPhY2Meta } from "/src/pages/gemstones/guide.vue?macro=true";
import { default as guideESek2eBSWXMeta } from "/src/pages/gemstones/ruby/guide.vue?macro=true";
import { default as guideas5mkEiMedMeta } from "/src/pages/gemstones/sapphire/guide.vue?macro=true";
import { default as here_45comes_45the_45supriseGsU0MlTvfeMeta } from "/src/pages/here-comes-the-suprise.vue?macro=true";
import { default as impressum0XojDA8GKUMeta } from "/src/pages/impressum.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as lab_45grown_45diamondsSlRXO0kcG2Meta } from "/src/pages/lab-grown-diamonds.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as logoutJAIe3EGR5DMeta } from "/src/pages/logout.vue?macro=true";
import { default as loose_45gemstoneshUlS3UyeQqMeta } from "/src/pages/loose-gemstones.vue?macro=true";
import { default as offersBesHVGJGRAMeta } from "/src/pages/offers.vue?macro=true";
import { default as our_45ethicsusrpBUV75EMeta } from "/src/pages/our-ethics.vue?macro=true";
import { default as our_45promisesjpQkpMkdr3Meta } from "/src/pages/our-promises.vue?macro=true";
import { default as page_45not_45found0jy11wTsNJMeta } from "/src/pages/page-not-found.vue?macro=true";
import { default as paypal_45returnt7dgfnL2lhMeta } from "/src/pages/paypal-return.vue?macro=true";
import { default as guide34XRnI4JRSMeta } from "/src/pages/pearls/guide.vue?macro=true";
import { default as privacy_4577diamondsgsAtERu5cXMeta } from "/src/pages/privacy-77diamonds.vue?macro=true";
import { default as register9mc1FDQpDSMeta } from "/src/pages/register.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as cupids_45Ideali5m78ucfVsMeta } from "/src/pages/seo-pages/cupids-Ideal.vue?macro=true";
import { default as greenland_45rubiesc3Qqr92tB3Meta } from "/src/pages/seo-pages/greenland-rubies.vue?macro=true";
import { default as shapeOKFO3FtHaAMeta } from "/src/pages/seo-pages/shape.vue?macro=true";
import { default as shop_45bagTYRQ3TpGKfMeta } from "/src/pages/shop-bag.vue?macro=true";
import { default as indexVxo5R7N7rlMeta } from "/src/pages/shop/diamonds/index.vue?macro=true";
import { default as indexSK19o12TcXMeta } from "/src/pages/shop/index.vue?macro=true";
import { default as shop2DZ7gStQw1Meta } from "/src/pages/shop.vue?macro=true";
import { default as showroomAQgyL4X8EGMeta } from "/src/pages/showroom.vue?macro=true";
import { default as showroomsogvA3KQX1HMeta } from "/src/pages/showrooms.vue?macro=true";
import { default as successrwNTsVp6BfMeta } from "/src/pages/success.vue?macro=true";
import { default as taxdutyBR7X1rnfxPMeta } from "/src/pages/taxduty.vue?macro=true";
export default [
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___ar",
    path: addressesIGZCkwNaoqMeta?.path ?? "/ar/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ar"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___bg",
    path: addressesIGZCkwNaoqMeta?.path ?? "/bg/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"bg"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___cs",
    path: addressesIGZCkwNaoqMeta?.path ?? "/cs/account/adresy",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"cs"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___dk",
    path: addressesIGZCkwNaoqMeta?.path ?? "/dk/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"dk"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___nl",
    path: addressesIGZCkwNaoqMeta?.path ?? "/nl/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"nl"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___en",
    path: addressesIGZCkwNaoqMeta?.path ?? "/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"en"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___fi",
    path: addressesIGZCkwNaoqMeta?.path ?? "/fi/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"fi"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___fr",
    path: addressesIGZCkwNaoqMeta?.path ?? "/fr/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"fr"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___de",
    path: addressesIGZCkwNaoqMeta?.path ?? "/de/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"de"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___it",
    path: addressesIGZCkwNaoqMeta?.path ?? "/it/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"it"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___cn",
    path: addressesIGZCkwNaoqMeta?.path ?? "/cn/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"cn"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___no",
    path: addressesIGZCkwNaoqMeta?.path ?? "/no/konto/adresser",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"no"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___pl",
    path: addressesIGZCkwNaoqMeta?.path ?? "/pl/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"pl"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___pt",
    path: addressesIGZCkwNaoqMeta?.path ?? "/pt/conta/moradas",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"pt"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___ro",
    path: addressesIGZCkwNaoqMeta?.path ?? "/ro/conturi/adrese",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ro"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___ru",
    path: addressesIGZCkwNaoqMeta?.path ?? "/ru/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ru"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___es",
    path: addressesIGZCkwNaoqMeta?.path ?? "/es/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"es"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___se",
    path: addressesIGZCkwNaoqMeta?.path ?? "/se/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"se"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: addressesIGZCkwNaoqMeta?.name ?? "758_account-addresses___hu",
    path: addressesIGZCkwNaoqMeta?.path ?? "/hu/account/addresses",
    meta: {...(addressesIGZCkwNaoqMeta || {}), ...{"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"hu"}},
    alias: addressesIGZCkwNaoqMeta?.alias || [],
    redirect: addressesIGZCkwNaoqMeta?.redirect || undefined,
    component: () => import("/src/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___ar",
    path: ordersF1jXyywrUuMeta?.path ?? "/ar/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ar"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___ar",
    path: ordersF1jXyywrUuMeta?.path ?? "/ar/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ar"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___bg",
    path: ordersF1jXyywrUuMeta?.path ?? "/bg/smetka/por-chki",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"bg"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___bg",
    path: ordersF1jXyywrUuMeta?.path ?? "/bg/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"bg"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___cs",
    path: ordersF1jXyywrUuMeta?.path ?? "/cs/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"cs"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___cs",
    path: ordersF1jXyywrUuMeta?.path ?? "/cs/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"cs"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___dk",
    path: ordersF1jXyywrUuMeta?.path ?? "/dk/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"dk"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___dk",
    path: ordersF1jXyywrUuMeta?.path ?? "/dk/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"dk"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___nl",
    path: ordersF1jXyywrUuMeta?.path ?? "/nl/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"nl"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___nl",
    path: ordersF1jXyywrUuMeta?.path ?? "/nl/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"nl"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___en",
    path: ordersF1jXyywrUuMeta?.path ?? "/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"en"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___en",
    path: ordersF1jXyywrUuMeta?.path ?? "/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"en"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___fi",
    path: ordersF1jXyywrUuMeta?.path ?? "/fi/tili/tilaukset",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"fi"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___fi",
    path: ordersF1jXyywrUuMeta?.path ?? "/fi/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"fi"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___fr",
    path: ordersF1jXyywrUuMeta?.path ?? "/fr/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"fr"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___fr",
    path: ordersF1jXyywrUuMeta?.path ?? "/fr/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"fr"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___de",
    path: ordersF1jXyywrUuMeta?.path ?? "/de/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"de"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___de",
    path: ordersF1jXyywrUuMeta?.path ?? "/de/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"de"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___it",
    path: ordersF1jXyywrUuMeta?.path ?? "/it/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"it"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___it",
    path: ordersF1jXyywrUuMeta?.path ?? "/it/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"it"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___cn",
    path: ordersF1jXyywrUuMeta?.path ?? "/cn/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"cn"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___cn",
    path: ordersF1jXyywrUuMeta?.path ?? "/cn/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"cn"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___no",
    path: ordersF1jXyywrUuMeta?.path ?? "/no/konto/bestillinger",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"no"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___no",
    path: ordersF1jXyywrUuMeta?.path ?? "/no/konto/bestilling",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"no"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___pl",
    path: ordersF1jXyywrUuMeta?.path ?? "/pl/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"pl"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___pl",
    path: ordersF1jXyywrUuMeta?.path ?? "/pl/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"pl"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___pt",
    path: ordersF1jXyywrUuMeta?.path ?? "/pt/conta/encomendas",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"pt"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___pt",
    path: ordersF1jXyywrUuMeta?.path ?? "/pt/conta/encomenda",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"pt"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___ro",
    path: ordersF1jXyywrUuMeta?.path ?? "/ro/conturi/ordine",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ro"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___ro",
    path: ordersF1jXyywrUuMeta?.path ?? "/ro/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ro"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___ru",
    path: ordersF1jXyywrUuMeta?.path ?? "/ru/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ru"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___ru",
    path: ordersF1jXyywrUuMeta?.path ?? "/ru/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ru"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___es",
    path: ordersF1jXyywrUuMeta?.path ?? "/es/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"es"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___es",
    path: ordersF1jXyywrUuMeta?.path ?? "/es/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"es"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___se",
    path: ordersF1jXyywrUuMeta?.path ?? "/se/account/orders",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"se"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___se",
    path: ordersF1jXyywrUuMeta?.path ?? "/se/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"se"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "756_account-orders___hu",
    path: ordersF1jXyywrUuMeta?.path ?? "/hu/account/orderok",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"hu"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersF1jXyywrUuMeta?.name ?? "757_account-orders___hu",
    path: ordersF1jXyywrUuMeta?.path ?? "/hu/account/order",
    meta: {...(ordersF1jXyywrUuMeta || {}), ...{"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"hu"}},
    alias: ordersF1jXyywrUuMeta?.alias || [],
    redirect: ordersF1jXyywrUuMeta?.redirect || undefined,
    component: () => import("/src/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___ar",
    path: privacyaTLU6InpJAMeta?.path ?? "/ar/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"ar"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___bg",
    path: privacyaTLU6InpJAMeta?.path ?? "/bg/kaunt/-poveritelnost",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"bg"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___cs",
    path: privacyaTLU6InpJAMeta?.path ?? "/cs/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"cs"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___dk",
    path: privacyaTLU6InpJAMeta?.path ?? "/dk/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"dk"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___nl",
    path: privacyaTLU6InpJAMeta?.path ?? "/nl/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"nl"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___en",
    path: privacyaTLU6InpJAMeta?.path ?? "/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"en"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___fi",
    path: privacyaTLU6InpJAMeta?.path ?? "/fi/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"fi"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___fr",
    path: privacyaTLU6InpJAMeta?.path ?? "/fr/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"fr"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___de",
    path: privacyaTLU6InpJAMeta?.path ?? "/de/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"de"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___it",
    path: privacyaTLU6InpJAMeta?.path ?? "/it/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"it"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___cn",
    path: privacyaTLU6InpJAMeta?.path ?? "/cn/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"cn"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___no",
    path: privacyaTLU6InpJAMeta?.path ?? "/no/konto/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"no"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___pl",
    path: privacyaTLU6InpJAMeta?.path ?? "/pl/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"pl"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___pt",
    path: privacyaTLU6InpJAMeta?.path ?? "/pt/conta/privacidade",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"pt"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___ro",
    path: privacyaTLU6InpJAMeta?.path ?? "/ro/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"ro"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___ru",
    path: privacyaTLU6InpJAMeta?.path ?? "/ru/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"ru"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___es",
    path: privacyaTLU6InpJAMeta?.path ?? "/es/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"es"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___se",
    path: privacyaTLU6InpJAMeta?.path ?? "/se/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"se"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyaTLU6InpJAMeta?.name ?? "759_account-privacy___hu",
    path: privacyaTLU6InpJAMeta?.path ?? "/hu/account/privacy",
    meta: {...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"}},
    alias: privacyaTLU6InpJAMeta?.alias || [],
    redirect: privacyaTLU6InpJAMeta?.redirect || undefined,
    component: () => import("/src/pages/account/privacy.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___ar",
    path: profile4xkbZgafKZMeta?.path ?? "/ar/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ar"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___bg",
    path: profile4xkbZgafKZMeta?.path ?? "/bg/akaunt/profil",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"bg"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___cs",
    path: profile4xkbZgafKZMeta?.path ?? "/cs/ucet/profil",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"cs"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___dk",
    path: profile4xkbZgafKZMeta?.path ?? "/dk/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"dk"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___nl",
    path: profile4xkbZgafKZMeta?.path ?? "/nl/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"nl"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___en",
    path: profile4xkbZgafKZMeta?.path ?? "/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"en"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___fi",
    path: profile4xkbZgafKZMeta?.path ?? "/fi/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"fi"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___fr",
    path: profile4xkbZgafKZMeta?.path ?? "/fr/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"fr"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___de",
    path: profile4xkbZgafKZMeta?.path ?? "/de/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"de"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___it",
    path: profile4xkbZgafKZMeta?.path ?? "/it/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"it"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___cn",
    path: profile4xkbZgafKZMeta?.path ?? "/cn/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"cn"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___no",
    path: profile4xkbZgafKZMeta?.path ?? "/no/konto/profil",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"no"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___pl",
    path: profile4xkbZgafKZMeta?.path ?? "/pl/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"pl"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___pt",
    path: profile4xkbZgafKZMeta?.path ?? "/pt/conta/perfil",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"pt"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___ro",
    path: profile4xkbZgafKZMeta?.path ?? "/ro/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ro"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___ru",
    path: profile4xkbZgafKZMeta?.path ?? "/ru/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ru"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___es",
    path: profile4xkbZgafKZMeta?.path ?? "/es/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"es"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___se",
    path: profile4xkbZgafKZMeta?.path ?? "/se/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"se"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profile4xkbZgafKZMeta?.name ?? "755_account-profile___hu",
    path: profile4xkbZgafKZMeta?.path ?? "/hu/account/profile",
    meta: {...(profile4xkbZgafKZMeta || {}), ...{"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"hu"}},
    alias: profile4xkbZgafKZMeta?.alias || [],
    redirect: profile4xkbZgafKZMeta?.redirect || undefined,
    component: () => import("/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___ar",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/ar/hasb_attalb.html",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ar"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___bg",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/bg/bizhuta-po-por-chka",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"bg"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___cs",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/cs/sperky-na-miru",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"cs"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___dk",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/dk/skraeddersyede-smykker",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"dk"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___nl",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/nl/maatwerk-sieraden",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"nl"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___en",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/bespoke-jewellery",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"en"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___fi",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/fi/erikoisvalmisteiset-korut",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"fi"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___fr",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/fr/bijoux-sur-mesure",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"fr"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___de",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/de/individueller-schmuck",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"de"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___it",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/it/bespoke.html",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"it"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___cn",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/cn/te-zhi.html",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"cn"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___no",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/no/-skreddersydde-smykker",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"no"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___pl",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/pl/bizuteria-na-zamowienie",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"pl"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___pt",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/pt/bespoke-jewellery",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"pt"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___ro",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/ro/bijuterii-la-comanda",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ro"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___ru",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/rukonsul-tacija.html",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ru"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___es",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/es/a-medida",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"es"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___se",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/se/skraddarsydda-smycken",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"se"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45jewellery4QrHxVP7J4Meta?.name ?? "32_bespoke-jewellery___hu",
    path: bespoke_45jewellery4QrHxVP7J4Meta?.path ?? "/hu/megrendelt-ekszerek",
    meta: {...(bespoke_45jewellery4QrHxVP7J4Meta || {}), ...{"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"hu"}},
    alias: bespoke_45jewellery4QrHxVP7J4Meta?.alias || [],
    redirect: bespoke_45jewellery4QrHxVP7J4Meta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-jewellery.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___ar",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/ar/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___bg",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/bg/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___cs",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/cs/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___dk",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/dk/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___nl",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/nl/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___en",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___fi",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/fi/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___fr",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/fr/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___de",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/de/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___it",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/it/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___cn",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/cn/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___no",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/no/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___pl",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/pl/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___pt",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/pt/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___ro",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/ro/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___ru",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/ru/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___es",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/es/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___se",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/se/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.name ?? "bespoke-standout-pieces___hu",
    path: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.path ?? "/hu/bespoke-standout-pieces",
    meta: bespoke_45standout_45pieces0xvUJlJ6VkMeta || {},
    alias: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.alias || [],
    redirect: bespoke_45standout_45pieces0xvUJlJ6VkMeta?.redirect || undefined,
    component: () => import("/src/pages/bespoke-standout-pieces.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___ar",
    path: index4E3N5yzW7cMeta?.path ?? "/ar/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___bg",
    path: index4E3N5yzW7cMeta?.path ?? "/bg/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___cs",
    path: index4E3N5yzW7cMeta?.path ?? "/cs/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___dk",
    path: index4E3N5yzW7cMeta?.path ?? "/dk/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___nl",
    path: index4E3N5yzW7cMeta?.path ?? "/nl/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___en",
    path: index4E3N5yzW7cMeta?.path ?? "/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___fi",
    path: index4E3N5yzW7cMeta?.path ?? "/fi/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___fr",
    path: index4E3N5yzW7cMeta?.path ?? "/fr/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___de",
    path: index4E3N5yzW7cMeta?.path ?? "/de/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___it",
    path: index4E3N5yzW7cMeta?.path ?? "/it/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___cn",
    path: index4E3N5yzW7cMeta?.path ?? "/cn/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___no",
    path: index4E3N5yzW7cMeta?.path ?? "/no/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___pl",
    path: index4E3N5yzW7cMeta?.path ?? "/pl/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___pt",
    path: index4E3N5yzW7cMeta?.path ?? "/pt/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___ro",
    path: index4E3N5yzW7cMeta?.path ?? "/ro/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___ru",
    path: index4E3N5yzW7cMeta?.path ?? "/ru/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___es",
    path: index4E3N5yzW7cMeta?.path ?? "/es/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___se",
    path: index4E3N5yzW7cMeta?.path ?? "/se/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout___hu",
    path: index4E3N5yzW7cMeta?.path ?? "/hu/checkout",
    meta: index4E3N5yzW7cMeta || {},
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect || undefined,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___ar",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/ar/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___bg",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/bg/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___cs",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/cs/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___dk",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/dk/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___nl",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/nl/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___en",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___fi",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/fi/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___fr",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/fr/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___de",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/de/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___it",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/it/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___cn",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/cn/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___no",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/no/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___pl",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/pl/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___pt",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/pt/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___ro",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/ro/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___ru",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/ru/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___es",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/es/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___se",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/se/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsMjTpfcAnoCMeta?.name ?? "contour-cushion-cut-diamonds___hu",
    path: cushion_45cut_45diamondsMjTpfcAnoCMeta?.path ?? "/hu/contour/cushion-cut-diamonds",
    meta: cushion_45cut_45diamondsMjTpfcAnoCMeta || {},
    alias: cushion_45cut_45diamondsMjTpfcAnoCMeta?.alias || [],
    redirect: cushion_45cut_45diamondsMjTpfcAnoCMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___ar",
    path: index6DYUkaVRdpMeta?.path ?? "/ar/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___bg",
    path: index6DYUkaVRdpMeta?.path ?? "/bg/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___cs",
    path: index6DYUkaVRdpMeta?.path ?? "/cs/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___dk",
    path: index6DYUkaVRdpMeta?.path ?? "/dk/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___nl",
    path: index6DYUkaVRdpMeta?.path ?? "/nl/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___en",
    path: index6DYUkaVRdpMeta?.path ?? "/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___fi",
    path: index6DYUkaVRdpMeta?.path ?? "/fi/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___fr",
    path: index6DYUkaVRdpMeta?.path ?? "/fr/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___de",
    path: index6DYUkaVRdpMeta?.path ?? "/de/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___it",
    path: index6DYUkaVRdpMeta?.path ?? "/it/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___cn",
    path: index6DYUkaVRdpMeta?.path ?? "/cn/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___no",
    path: index6DYUkaVRdpMeta?.path ?? "/no/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___pl",
    path: index6DYUkaVRdpMeta?.path ?? "/pl/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___pt",
    path: index6DYUkaVRdpMeta?.path ?? "/pt/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___ro",
    path: index6DYUkaVRdpMeta?.path ?? "/ro/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___ru",
    path: index6DYUkaVRdpMeta?.path ?? "/ru/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___es",
    path: index6DYUkaVRdpMeta?.path ?? "/es/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___se",
    path: index6DYUkaVRdpMeta?.path ?? "/se/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: index6DYUkaVRdpMeta?.name ?? "contour___hu",
    path: index6DYUkaVRdpMeta?.path ?? "/hu/contour",
    meta: index6DYUkaVRdpMeta || {},
    alias: index6DYUkaVRdpMeta?.alias || [],
    redirect: index6DYUkaVRdpMeta?.redirect || undefined,
    component: () => import("/src/pages/contour/index.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___ar",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/ar/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___ar",
    path: contact71Rz830P7VMeta?.path ?? "/ar/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ar"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___ar",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___ar",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/ar/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ar"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___ar",
    path: faqAaQGpbjedXMeta?.path ?? "/ar/alasala-alshaah.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ar"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___ar",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/ar/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ar"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___ar",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/ar/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ar"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___bg",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/bg/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___bg",
    path: contact71Rz830P7VMeta?.path ?? "/bg/kontakt",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"bg"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___bg",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___bg",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/bg/dostavka-i-vr-shchane",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"bg"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___bg",
    path: faqAaQGpbjedXMeta?.path ?? "/bg/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"bg"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___bg",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/bg/finansov-kalkulator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"bg"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___bg",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/bg/usloviya-na-usloviyata",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"bg"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___cs",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/cs/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___cs",
    path: contact71Rz830P7VMeta?.path ?? "/cs/kontakt",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"cs"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___cs",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___cs",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/cs/dodani-a-vraceni-zbozi",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"cs"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___cs",
    path: faqAaQGpbjedXMeta?.path ?? "/cs/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"cs"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___cs",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/cs/financni-kalkulacka",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"cs"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___cs",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/cs/podminky",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"cs"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___dk",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/dk/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___dk",
    path: contact71Rz830P7VMeta?.path ?? "/dk/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"dk"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___dk",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___dk",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/dk/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"dk"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___dk",
    path: faqAaQGpbjedXMeta?.path ?? "/dk/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"dk"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___dk",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/dk/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"dk"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___dk",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/dk/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"dk"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___nl",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/nl/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___nl",
    path: contact71Rz830P7VMeta?.path ?? "/nl/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"nl"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___nl",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___nl",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/nl/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"nl"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___nl",
    path: faqAaQGpbjedXMeta?.path ?? "/nl/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"nl"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___nl",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/nl/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"nl"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___nl",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/nl/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"nl"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___en",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___en",
    path: contact71Rz830P7VMeta?.path ?? "/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"en"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___en",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___en",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"en"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___en",
    path: faqAaQGpbjedXMeta?.path ?? "/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"en"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___en",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"en"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___en",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"en"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___fi",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/fi/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___fi",
    path: contact71Rz830P7VMeta?.path ?? "/fi/yhteystiedot",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"fi"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___fi",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___fi",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/fi/toimitus-ja-palautus",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"fi"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___fi",
    path: faqAaQGpbjedXMeta?.path ?? "/fi/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"fi"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___fi",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/fi/rahoituslaskuri",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"fi"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___fi",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/fi/ehtojen-ehdot",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"fi"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___fr",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/fr/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___fr",
    path: contact71Rz830P7VMeta?.path ?? "/fr/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"fr"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___fr",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___fr",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/fr/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"fr"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___fr",
    path: faqAaQGpbjedXMeta?.path ?? "/fr/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"fr"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___fr",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/fr/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"fr"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___fr",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/fr/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"fr"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___de",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/de/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___de",
    path: contact71Rz830P7VMeta?.path ?? "/de/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"de"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___de",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___de",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/de/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"de"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___de",
    path: faqAaQGpbjedXMeta?.path ?? "/de/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"de"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___de",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/de/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"de"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___de",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/de/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"de"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___it",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/it/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___it",
    path: contact71Rz830P7VMeta?.path ?? "/it/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"it"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___it",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___it",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/it/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"it"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___it",
    path: faqAaQGpbjedXMeta?.path ?? "/it/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"it"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___it",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/it/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"it"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___it",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/it/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"it"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___cn",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/cn/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___cn",
    path: contact71Rz830P7VMeta?.path ?? "/cn/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"cn"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___cn",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___cn",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/cn/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"cn"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___cn",
    path: faqAaQGpbjedXMeta?.path ?? "/cn/chang-jian-wen-ti.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"cn"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___cn",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/cn/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"cn"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___cn",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/cn/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"cn"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___no",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/no/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___no",
    path: contact71Rz830P7VMeta?.path ?? "/no/kontakt",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"no"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___no",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___no",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/no/levering-og-returnering",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"no"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___no",
    path: faqAaQGpbjedXMeta?.path ?? "/no/spoersmal",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"no"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___no",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/no/finanskalkulator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"no"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___no",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/no/vilkar-og-betingelser",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"no"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___pl",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/pl/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___pl",
    path: contact71Rz830P7VMeta?.path ?? "/pl/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"pl"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___pl",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___pl",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/pl/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"pl"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___pl",
    path: faqAaQGpbjedXMeta?.path ?? "/pl/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"pl"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___pl",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/pl/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"pl"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___pl",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/pl/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"pl"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___pt",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/pt/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___pt",
    path: contact71Rz830P7VMeta?.path ?? "/pt/contactos",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"pt"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___pt",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___pt",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/pt/entraga-e-devolucoes",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"pt"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___pt",
    path: faqAaQGpbjedXMeta?.path ?? "/pt/faq.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"pt"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___pt",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/pt/calculadora-financiamento",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"pt"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___pt",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/pt/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"pt"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___ro",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/ro/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___ro",
    path: contact71Rz830P7VMeta?.path ?? "/ro/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ro"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___ro",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___ro",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/ro/livrare-si-retururi",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ro"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___ro",
    path: faqAaQGpbjedXMeta?.path ?? "/ro/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ro"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___ro",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/ro/calculator-financiar",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ro"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___ro",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/ro/conditii-de-utilizare",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ro"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___ru",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/ru/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___ru",
    path: contact71Rz830P7VMeta?.path ?? "/ru/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ru"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___ru",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___ru",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/ru/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ru"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___ru",
    path: faqAaQGpbjedXMeta?.path ?? "/ru/chasto-zadavaemye-voprosy.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ru"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___ru",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/ru/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ru"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___ru",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/ru/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ru"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___es",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/es/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___es",
    path: contact71Rz830P7VMeta?.path ?? "/es/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"es"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___es",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___es",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/es/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"es"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___es",
    path: faqAaQGpbjedXMeta?.path ?? "/es/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"es"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___es",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/es/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"es"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___es",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/es/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"es"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___se",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/se/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___se",
    path: contact71Rz830P7VMeta?.path ?? "/se/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"se"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___se",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___se",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/se/delivery-and-returns",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"se"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___se",
    path: faqAaQGpbjedXMeta?.path ?? "/se/fakta.html",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"se"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___se",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/se/finance-calculator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"se"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___se",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/se/terms-conditions",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"se"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: customer_45careOdTE0K9no0Meta?.name ?? "customer-care___hu",
    path: customer_45careOdTE0K9no0Meta?.path ?? "/hu/customer-care",
    children: [
  {
    name: contact71Rz830P7VMeta?.name ?? "740_customer-care-contact___hu",
    path: contact71Rz830P7VMeta?.path ?? "/hu/contact",
    meta: {...(contact71Rz830P7VMeta || {}), ...{"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"hu"}},
    alias: contact71Rz830P7VMeta?.alias || [],
    redirect: contact71Rz830P7VMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/contact.vue").then(m => m.default || m)
  },
  {
    name: customer_45care9rqGcfFi8SMeta?.name ?? "customer-care-customer-care___hu",
    path: customer_45care9rqGcfFi8SMeta?.path ?? "customer-care",
    meta: customer_45care9rqGcfFi8SMeta || {},
    alias: customer_45care9rqGcfFi8SMeta?.alias || [],
    redirect: customer_45care9rqGcfFi8SMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/customer-care.vue").then(m => m.default || m)
  },
  {
    name: delivery_45and_45returnsSmuhRzs1mpMeta?.name ?? "766_customer-care-delivery-and-returns___hu",
    path: delivery_45and_45returnsSmuhRzs1mpMeta?.path ?? "/hu/szallitas-es-visszaru",
    meta: {...(delivery_45and_45returnsSmuhRzs1mpMeta || {}), ...{"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"hu"}},
    alias: delivery_45and_45returnsSmuhRzs1mpMeta?.alias || [],
    redirect: delivery_45and_45returnsSmuhRzs1mpMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue").then(m => m.default || m)
  },
  {
    name: faqAaQGpbjedXMeta?.name ?? "60_customer-care-faq___hu",
    path: faqAaQGpbjedXMeta?.path ?? "/hu/faq",
    meta: {...(faqAaQGpbjedXMeta || {}), ...{"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"hu"}},
    alias: faqAaQGpbjedXMeta?.alias || [],
    redirect: faqAaQGpbjedXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/faq.vue").then(m => m.default || m)
  },
  {
    name: finance_45calculator0UvxeF1EDXMeta?.name ?? "765_customer-care-finance-calculator___hu",
    path: finance_45calculator0UvxeF1EDXMeta?.path ?? "/hu/penzugyi-kalkulator",
    meta: {...(finance_45calculator0UvxeF1EDXMeta || {}), ...{"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"hu"}},
    alias: finance_45calculator0UvxeF1EDXMeta?.alias || [],
    redirect: finance_45calculator0UvxeF1EDXMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/finance-calculator.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsMMKCzXZeIIMeta?.name ?? "739_customer-care-terms-conditions___hu",
    path: terms_45conditionsMMKCzXZeIIMeta?.path ?? "/hu/terms-feltetelek",
    meta: {...(terms_45conditionsMMKCzXZeIIMeta || {}), ...{"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"hu"}},
    alias: terms_45conditionsMMKCzXZeIIMeta?.alias || [],
    redirect: terms_45conditionsMMKCzXZeIIMeta?.redirect || undefined,
    component: () => import("/src/pages/customer-care/terms-conditions.vue").then(m => m.default || m)
  }
],
    meta: customer_45careOdTE0K9no0Meta || {},
    alias: customer_45careOdTE0K9no0Meta?.alias || [],
    redirect: customer_45careOdTE0K9no0Meta?.redirect || undefined,
    component: () => import("/src/pages/customer-care.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___ar",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/ar/mojouhraat-maasyah.html",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ar"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___bg",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/bg/-bizhuta-s-diamanti",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"bg"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___cs",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/cs/diamantove-sperky",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"cs"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___dk",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/dk/diamantsmykker",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"dk"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___nl",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/nl/diamanten-sieraden",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"nl"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___en",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/diamond-jewellery",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"en"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___fi",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/fi/diamondikoru",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"fi"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___fr",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/fr/bijoux-en-diamant",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"fr"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___de",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/de/diamantschmuck",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"de"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___it",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/it/gioielli-diamanti",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"it"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___cn",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/cn/zuan-shi-shou-shi.html",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"cn"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___no",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/no/diamantsmykker",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"no"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___pl",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/pl/bizuteria-z-diamentami",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"pl"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___pt",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/pt/joias-de-diamantes",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"pt"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___ro",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/ro/bijuterii-de-diamant",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ro"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___ru",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/ru/brilliantovye-izdelija",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ru"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___es",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/es/joyas-de-diamantes",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"es"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___se",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/se/diamantsmycken",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"se"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamond_45jewelleryaTnquFRy8QMeta?.name ?? "175_diamond-jewellery___hu",
    path: diamond_45jewelleryaTnquFRy8QMeta?.path ?? "/hu/gyemant-ekszerek",
    meta: {...(diamond_45jewelleryaTnquFRy8QMeta || {}), ...{"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"hu"}},
    alias: diamond_45jewelleryaTnquFRy8QMeta?.alias || [],
    redirect: diamond_45jewelleryaTnquFRy8QMeta?.redirect || undefined,
    component: () => import("/src/pages/diamond-jewellery.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___ar",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/ar/almaasaat_online.html",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ar"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___bg",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/bg/diamanti",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"bg"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___cs",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/cs/diamanty",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"cs"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___dk",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/dk/diamanter",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"dk"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___nl",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/nl/diamanten",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"nl"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___en",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/diamonds",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"en"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___fi",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/fi/timantit",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"fi"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___fr",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/fr/diamants",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"fr"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___de",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/de/diamanten",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"de"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___it",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/it/diamanti_online.html",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"it"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___cn",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/cn/zuan-shi-zai-xian.html",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"cn"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___no",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/no/diamanter",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"no"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___pl",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/pl/diamenty",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"pl"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___pt",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/pt/diamantes",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"pt"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___ro",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/ro/diamante",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ro"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___ru",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/ru/brillianty_onlajn.html",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ru"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___es",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/es/diamantes_en_linea",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"es"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___se",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/se/diamanter",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"se"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    name: diamondsZSRK9W2p7zMeta?.name ?? "177_diamonds___hu",
    path: diamondsZSRK9W2p7zMeta?.path ?? "/hu/diamonds",
    meta: {...(diamondsZSRK9W2p7zMeta || {}), ...{"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"hu"}},
    alias: diamondsZSRK9W2p7zMeta?.alias || [],
    redirect: diamondsZSRK9W2p7zMeta?.redirect || undefined,
    component: () => import("/src/pages/diamonds.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/ar/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___ar",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/ar/matbou-at-77daaymoundz",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ar"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___ar",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/ar/hawl-77daaymoundz.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ar"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___ar",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/ar/tashreeh-almaasa.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ar"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___ar",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/ar/tafaaseel-aljawaaiz.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ar"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___ar",
    path: awardsYoSh66TkpzMeta?.path ?? "/ar/jawaaiz",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ar"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___ar",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/ar/almaas-alkanadee.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ar"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___ar",
    path: careershFQmXK4dYZMeta?.path ?? "/ar/wazaaif.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ar"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___ar",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/ar/almaasaat-mohsnt-alnaqaa-.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ar"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___ar",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/ar/moqaarnt-shahaadaat-almaas.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ar"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___ar",
    path: cost6ozuBJSlf2Meta?.path ?? "/ar/alttaklfa.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ar"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___ar",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/ar/khwaatm-alkhotbah/daleel-sharaa-almaas",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ar"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___ar",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/ar/wazn-almaasa-belqeeraat.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ar"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___ar",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/ar/shahaadaat-almaas.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ar"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___ar",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/ar/nasihat-alkhobraa-nqaa-almas.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ar"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___ar",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/ar/taqyeem-naqaa-almaasa.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ar"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___ar",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/ar/naqaa-almaasa.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ar"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___ar",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/ar/lawn-almaasa.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ar"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___ar",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/ar/taqyeem-qat--almaasa.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ar"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___ar",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/ar/-amalyt-_qata-almaas.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ar"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___ar",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/ar/qat--almaasa.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ar"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___ar",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/ar/almaasaat-mtouhjah.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ar"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___ar",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/ar/mokhtbraat-tsneef-almaas.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ar"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___ar",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/ar/shawaa-ib-almaas.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ar"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___ar",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/ar/ashkaal-almaas.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ar"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___ar",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/ar/khwaatem-alkotbah/dleel-shraa-khotbah",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ar"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___ar",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/ar/mas-akhlaqi.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ar"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___ar",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/ar/almasat-molonah-fakhrah.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ar"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___ar",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/ar/ashm-qloub.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ar"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___ar",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/ar/kif-yoqta-almaas.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ar"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___ar",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/ar/kif-yoshkl-almaas.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ar"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___ar",
    path: indexEyNi2vxOyuMeta?.path ?? "/ar/tawaia-belmaas.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ar"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___ar",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/ar/qisatona",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ar"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___ar",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/ar/khwaatm-alkhotba/jadoul_-maqaas-alkhaatm",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ar"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___ar",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/ar/almaasaat-qata-morba.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ar"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___ar",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/ar/almaasaat-qat-wasaada.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ar"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___ar",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/ar/almaasaat-qata-zomorodi.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ar"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___ar",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/ar/almaasaat-qta-qlb.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ar"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___ar",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/ar/almaasaat-qta-maarkeez.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ar"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___ar",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/ar/-almaasaat-qata-baydaawee.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ar"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___ar",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/ar/-almaasaat-qata-komthree.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ar"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___ar",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/ar/-almaasaat-qata-brinses.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ar"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___ar",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/ar/-almaasaat-qata-mostateel.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ar"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___ar",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/ar/-almaasaat-mostadeera.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ar"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___ar",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/ar/-almaasaat-qata-treelyoun.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ar"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___ar",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/ar/tazkyaat.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ar"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___ar",
    path: indexwXj5m7cZh6Meta?.path ?? "/ar/ashkaal-qata-almaas.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ar"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___ar",
    path: introductionioT4hAg28aMeta?.path ?? "/ar/moqdmah.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ar"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ar",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/ar/qata-mokhtlt.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ar"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ar",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/ar/qata-albareelyaant-alma-dl.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ar"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ar",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/ar/qata-awroubee-qadeem-manjmee-qadeem.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ar"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ar",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/ar/qata-mostadeer-motaalq.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ar"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___ar",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/ar/qata-modrj.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ar"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/bg/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___bg",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/bg/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"bg"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___bg",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/bg/okolo-77-diamanta",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"bg"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___bg",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/bg/anatomiya-na-diamant",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"bg"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___bg",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/bg/podrobnosti-za-nagradite",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"bg"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___bg",
    path: awardsYoSh66TkpzMeta?.path ?? "/bg/nagradi",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"bg"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___bg",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/bg/kanadski-diamanti",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"bg"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___bg",
    path: careershFQmXK4dYZMeta?.path ?? "/bg/kariera",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"bg"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___bg",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/bg/podobreni-diamanti",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"bg"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___bg",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/bg/sravnyavane-na-diamanteni-sertifikati",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"bg"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___bg",
    path: cost6ozuBJSlf2Meta?.path ?? "/bg/cost",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"bg"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___bg",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/bg/angazhirashchi-pr-steni/r-kovodstvo-za-pokupka-na-diamanti",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"bg"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___bg",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/bg/karatovo-teglo-na-diamanta",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"bg"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___bg",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/bg/diamanteni-sertifikati",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"bg"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___bg",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/bg/ekspertni-s-veti-za-chistotata-na-diamantite",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"bg"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___bg",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/bg/klasifikaciya-na-chistotata-na-diamanta",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"bg"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___bg",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/bg/yasnota-na-diamanta",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"bg"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___bg",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/bg/cvyat-na-diamant",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"bg"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___bg",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/bg/klasifikaciya-s-diamanteno-shlifovane",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"bg"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___bg",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/bg/proces-na-diamanteno-ryazane",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"bg"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___bg",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/bg/diamanteno-shlifovane",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"bg"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___bg",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/bg/diamantena-fluorescenciya",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"bg"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___bg",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/bg/laboratorii-za-klasifikaciya-na-diamanti",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"bg"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___bg",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/bg/diamanteni-vklyuchvaniya",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"bg"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___bg",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/bg/diamanteni-formi",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"bg"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___bg",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/bg/-pr-steni-za-godezh/-r-kovodstvo-za-pokupka-na-pr-steni-za-godezh",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"bg"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___bg",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/bg/etichni-diamanti",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"bg"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___bg",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/bg/cvetni-diamanti",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"bg"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___bg",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/bg/strelki-za-s-rce",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"bg"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___bg",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/bg/kak-se-shlifovat-diamantite",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"bg"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___bg",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/bg/kak-se-obrazuvat-diamantite",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"bg"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___bg",
    path: indexEyNi2vxOyuMeta?.path ?? "/bg/obrazovanie-s-diamanti",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"bg"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___bg",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/bg/nashata-istoriya",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"bg"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___bg",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/bg/-godezhni-pr-steni/-diagrama-na-razmerite-na-pr-stenite",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"bg"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___bg",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/bg/diamanti,-shlifovani-po-metoda-na-asher",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"bg"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___bg",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/bg/diamanti-s-shlifovana-v-zglavnica",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"bg"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___bg",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/bg/diamanti-s-diamanteno-shlifovane",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"bg"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___bg",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/bg/diamanti-s-shlifovano-s-rce",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"bg"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___bg",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/bg/diamanti-s-brilyantna-shlifovka",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"bg"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___bg",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/bg/ovalno-shlifovani-diamanti",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"bg"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___bg",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/bg/diamanti-s-forma-na-krusha",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"bg"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___bg",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/bg/diamanti-s-shlifovka-princesa",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"bg"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___bg",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/bg/diamanti-s-radiantsko-shlifovane",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"bg"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___bg",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/bg/kr-gli-diamanti",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"bg"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___bg",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/bg/trilionno-shlifovani-diamanti",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"bg"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___bg",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/bg/prepor-ki",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"bg"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___bg",
    path: indexwXj5m7cZh6Meta?.path ?? "/bg/vidove-diamanteni-shlifovki",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"bg"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___bg",
    path: introductionioT4hAg28aMeta?.path ?? "/bg/v-vedenie",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"bg"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___bg",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/bg/smesen-razrez",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"bg"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___bg",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/bg/modificiran-brilyanten-razrez",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"bg"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___bg",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/bg/old-mine-old-european-cut",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"bg"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___bg",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/bg/kr-g-l-brilyant",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"bg"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___bg",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/bg/-st-pka-cut",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"bg"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/cs/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___cs",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/cs/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"cs"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___cs",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/cs/o-77-diamantech",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"cs"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___cs",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/cs/anatomie-diamantu",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"cs"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___cs",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/cs/podrobnosti-o-ocenenich",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"cs"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___cs",
    path: awardsYoSh66TkpzMeta?.path ?? "/cs/odmeny",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"cs"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___cs",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/cs/kanadske-diamanty",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"cs"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___cs",
    path: careershFQmXK4dYZMeta?.path ?? "/cs/kariera",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"cs"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___cs",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/cs/zvysena-pruhlednost-diamantu",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"cs"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___cs",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/cs/porovnani-diamantovych-certifikatu",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"cs"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___cs",
    path: cost6ozuBJSlf2Meta?.path ?? "/cs/naklady",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"cs"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___cs",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/cs/snubni-prsteny/pruvodce-nakupem-diamantu",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"cs"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___cs",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/cs/karatova-hmotnost-diamantu",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"cs"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___cs",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/cs/diamantove-certifikaty",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"cs"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___cs",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/cs/odborne-poradenstvi-o-cistote-diamantu",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"cs"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___cs",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/cs/trideni-cistoty-diamantu",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"cs"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___cs",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/cs/cistota-diamantu",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"cs"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___cs",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/cs/diamantova-barva",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"cs"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___cs",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/cs/trideni-diamantu",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"cs"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___cs",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/cs/proces-brouseni-diamantu",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"cs"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___cs",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/cs/diamantovy-brus",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"cs"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___cs",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/cs/diamantova-fluorescence",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"cs"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___cs",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/cs/laboratore-pro-trideni-diamantu",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"cs"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___cs",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/cs/diamantove-inkluze",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"cs"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___cs",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/cs/diamantove-tvary",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"cs"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___cs",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/cs/pruvodce-nakupem-zasnubnich-prstenu/zasnoubeni",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"cs"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___cs",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/cs/eticke-diamanty",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"cs"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___cs",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/cs/fantasticke-barevne-diamanty",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"cs"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___cs",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/cs/sipky-srdce",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"cs"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___cs",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/cs/jak-se-brousi-diamanty",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"cs"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___cs",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/cs/jak-vznikaji-diamanty",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"cs"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___cs",
    path: indexEyNi2vxOyuMeta?.path ?? "/cs/diamantove-vzdelani",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"cs"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___cs",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/cs/nas-pribeh",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"cs"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___cs",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/cs/snubni-prsteny/tabulka-velikosti-prstenu",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"cs"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___cs",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/cs/asscheruv-brus",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"cs"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___cs",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/cs/brousene-diamanty-ve-tvaru-polstare",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"cs"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___cs",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/cs/emeraldove-brousene-diamanty",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"cs"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___cs",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/cs/brousene-diamanty",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"cs"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___cs",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/cs/marquise-cut-diamanty",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"cs"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___cs",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/cs/ovalny-brus-diamantu",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"cs"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___cs",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/cs/-diamanty-ve-tvaru-hrusky",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"cs"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___cs",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/cs/princess-cut-diamonds",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"cs"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___cs",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/cs/radiantni-diamanty",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"cs"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___cs",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/cs/kulate-diamanty",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"cs"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___cs",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/cs/trilion-cut-diamonds",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"cs"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___cs",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/cs/zkusenosti",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"cs"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___cs",
    path: indexwXj5m7cZh6Meta?.path ?? "/cs/typy-diamantovych-brusu",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"cs"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___cs",
    path: introductionioT4hAg28aMeta?.path ?? "/cs/uvod",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"cs"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___cs",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/cs/smiseny-rez",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"cs"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___cs",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/cs/modifikovany-briliantovy-rez",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"cs"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___cs",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/cs/stary-dul-stary-evropsky-strih",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"cs"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___cs",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/cs/kulaty-briliantovy-brus",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"cs"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___cs",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/cs/krokovy-rez",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"cs"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/dk/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___dk",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/dk/77diamonds-presse.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"dk"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___dk",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/dk/om-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"dk"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___dk",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/dk/en-diamants-anatomi.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"dk"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___dk",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/dk/priser-detaljer.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"dk"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___dk",
    path: awardsYoSh66TkpzMeta?.path ?? "/dk/priser",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"dk"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___dk",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/dk/canadiske-diamanter.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"dk"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___dk",
    path: careershFQmXK4dYZMeta?.path ?? "/dk/karriere.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"dk"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___dk",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/dk/klarhedsfremhaevende-diamanter.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"dk"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___dk",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/dk/sammenlign-diamantcertifikater.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"dk"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___dk",
    path: cost6ozuBJSlf2Meta?.path ?? "/dk/pris.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"dk"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___dk",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/dk/forlovelsesringe/kobsguide-til-diamanter",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"dk"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___dk",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/dk/diamantens-karatvaegt.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"dk"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___dk",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/dk/diamantcertifikater.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"dk"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___dk",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/dk/diamantklarhed-ekspertrad.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"dk"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___dk",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/dk/diamant-klarhedsgradering.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"dk"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___dk",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/dk/diamantklarhed.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"dk"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___dk",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/dk/diamant-farve.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"dk"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___dk",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/dk/diamant-slibningsgrader.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"dk"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___dk",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/dk/diamantslibningsprocessen.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"dk"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___dk",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/dk/diamant-slibning.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"dk"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___dk",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/dk/diamant-fluorescence.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"dk"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___dk",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/dk/diamantgraderingslaboratorie.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"dk"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___dk",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/dk/diamant-indeslutninger.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"dk"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___dk",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/dk/diamantform.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"dk"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___dk",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/dk/forlovelsesringe/kobeguide-til-forlovelsesringe",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"dk"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___dk",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/dk/etiske-diamanter.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"dk"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___dk",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/dk/fancy-farvede-diamanter.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"dk"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___dk",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/dk/hjerte-pile.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"dk"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___dk",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/dk/hvordan-bliver-diamanter-slebet.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"dk"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___dk",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/dk/hvordan-skabes-diamanter.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"dk"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___dk",
    path: indexEyNi2vxOyuMeta?.path ?? "/dk/diamant-uddannelse.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"dk"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___dk",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/dk/our-story",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"dk"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___dk",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/dk/forlovelsesringe/ringstorrelsestabel",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"dk"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___dk",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/dk/asscher-slebede-diamanter.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"dk"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___dk",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/dk/pude-slebne-diamanter.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"dk"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___dk",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/dk/smaragd-slebne-diamanter.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"dk"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___dk",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/dk/hjerte-slebne-diamanter.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"dk"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___dk",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/dk/marquise-slebne-diamanter.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"dk"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___dk",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/dk/oval-slebne-diamanter.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"dk"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___dk",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/dk/paereformede-diamanter.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"dk"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___dk",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/dk/prinssese-slebne-diamanter.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"dk"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___dk",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/dk/radiant-slebne-diamanter.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"dk"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___dk",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/dk/runde-diamanter.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"dk"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___dk",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/dk/trillion-slebne-diamanter.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"dk"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___dk",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/dk/kundereferencer.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"dk"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___dk",
    path: indexwXj5m7cZh6Meta?.path ?? "/dk/typer-af-diamant-slibninger.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"dk"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___dk",
    path: introductionioT4hAg28aMeta?.path ?? "/dk/introduktion.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"dk"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___dk",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/dk/mix-slib.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"dk"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___dk",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/dk/modificered-brilliantslibning.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"dk"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___dk",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/dk/old-mine-slib-old-european-slib.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"dk"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___dk",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/dk/rund-brilliantslibning.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"dk"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___dk",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/dk/trappe-slibning.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"dk"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/nl/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___nl",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/nl/77diamonds-media.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"nl"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___nl",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/nl/over-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"nl"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___nl",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/nl/anatomie-van-een-diamant.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"nl"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___nl",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/nl/awards-details.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"nl"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___nl",
    path: awardsYoSh66TkpzMeta?.path ?? "/nl/awards",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"nl"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___nl",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/nl/canadese-diamanten.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"nl"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___nl",
    path: careershFQmXK4dYZMeta?.path ?? "/nl/carrires.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"nl"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___nl",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/nl/zuiverheid-verbeterde-diamanten.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"nl"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___nl",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/nl/vergelijk-diamant-certificaten.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"nl"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___nl",
    path: cost6ozuBJSlf2Meta?.path ?? "/nl/prijs.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"nl"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___nl",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/nl/verlovingsringen/diamanten-koopgids",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"nl"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___nl",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/nl/diamant-karaatgewicht.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"nl"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___nl",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/nl/diamant-certificaten.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"nl"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___nl",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/nl/diamant-zuiverheid-expert-advies.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"nl"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___nl",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/nl/diamant-zuiverheid-gradatie.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"nl"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___nl",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/nl/diamant-zuiverheid.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"nl"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___nl",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/nl/diamant-kleur.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"nl"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___nl",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/nl/diamant-slijpkwaliteit-gradatie.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"nl"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___nl",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/nl/diamant-slijpen-proces.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"nl"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___nl",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/nl/diamant-slijpvorm.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"nl"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___nl",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/nl/diamant-fluorescentie.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"nl"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___nl",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/nl/diamant-beoordeling-lab.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"nl"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___nl",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/nl/diamant-insluitsels.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"nl"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___nl",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/nl/diamantvormen.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"nl"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___nl",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/nl/verlovingsringen/koopgids-verloving",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"nl"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___nl",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/nl/ethische-diamanten.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"nl"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___nl",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/nl/fancy-gekleurde-diamanten.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"nl"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___nl",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/nl/hearts-arrows.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"nl"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___nl",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/nl/hoe-worden-diamanten-geslepen.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"nl"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___nl",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/nl/hoe-worden-diamanten-gevormd.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"nl"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___nl",
    path: indexEyNi2vxOyuMeta?.path ?? "/nl/diamant-educatie.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"nl"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___nl",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/nl/ons-verhaal",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"nl"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___nl",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/nl/verlovingsringen/ringmaat-tabel",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"nl"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___nl",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/nl/asscher-geslepen-diamanten.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"nl"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___nl",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/nl/cushion-geslepen-diamanten.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"nl"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___nl",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/nl/emerald-geslepen-diamanten.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"nl"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___nl",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/nl/hart-geslepen-diamanten.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"nl"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___nl",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/nl/marquise-geslepen-diamanten.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"nl"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___nl",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/nl/ovaal-geslepen-diamanten.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"nl"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___nl",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/nl/peervormige-diamanten.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"nl"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___nl",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/nl/princess-geslepen-diamanten.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"nl"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___nl",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/nl/radiant-geslepen-diamanten.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"nl"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___nl",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/nl/ronde-diamanten.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"nl"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___nl",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/nl/triljoen-geslepen-diamanten.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"nl"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___nl",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/nl/getuigenissen.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"nl"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___nl",
    path: indexwXj5m7cZh6Meta?.path ?? "/nl/type-diamant-slijpvormen.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"nl"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___nl",
    path: introductionioT4hAg28aMeta?.path ?? "/nl/introductie.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"nl"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___nl",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/nl/gemengde-slijpvorm.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"nl"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___nl",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/nl/gemodificeerd-briljant-slijpvorm.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"nl"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___nl",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/nl/oude-mijn-oud-europese-slijpvorm.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"nl"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___nl",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/nl/ronde-briljant-slijpvorm.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"nl"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___nl",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/nl/step-slijpvorm.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"nl"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___en",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/77diamonds-press.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"en"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___en",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/about-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"en"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___en",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/anatomy-of-a-diamond.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"en"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___en",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/awards-details.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"en"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___en",
    path: awardsYoSh66TkpzMeta?.path ?? "/awards",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"en"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___en",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/canadian-diamonds.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"en"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___en",
    path: careershFQmXK4dYZMeta?.path ?? "/careers.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"en"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___en",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/clarity-enhanced-diamonds.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"en"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___en",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/compare-diamond-certificates.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"en"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___en",
    path: cost6ozuBJSlf2Meta?.path ?? "/cost.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"en"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___en",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/engagement-rings/diamond-buying-guide",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"en"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___en",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/diamond-carat-weight.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"en"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___en",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/diamond-certificates.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"en"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___en",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/diamond-clarity-expert-advice.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"en"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___en",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/diamond-clarity-grading.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"en"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___en",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/diamond-clarity.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"en"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___en",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/diamond-colour.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"en"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___en",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/diamond-cut-grading.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"en"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___en",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/diamond-cut-process.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"en"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___en",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/diamond-cut.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"en"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___en",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/diamond-fluorescence.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"en"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___en",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/diamond-grading-labs.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"en"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___en",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/diamond-inclusions.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"en"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___en",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/diamond-shapes.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"en"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___en",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/engagement-rings/engagement-buying-guide",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"en"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___en",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/ethical-diamonds.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"en"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___en",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/fancy-coloured-diamonds.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"en"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___en",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/hearts-arrows.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"en"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___en",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/how-are-diamonds-cut.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"en"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___en",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/how-are-diamonds-formed.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"en"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___en",
    path: indexEyNi2vxOyuMeta?.path ?? "/diamond-education.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"en"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___en",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/our-story",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"en"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___en",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/engagement-rings/ring-size-chart",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"en"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___en",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/asscher-cut-diamonds.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"en"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___en",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/cushion-cut-diamonds.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"en"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___en",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/emerald-cut-diamonds.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"en"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___en",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/heart-cut-diamonds.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"en"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___en",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/marquise-cut-diamonds.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"en"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___en",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/oval-cut-diamonds.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"en"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___en",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/pear-shape-diamonds.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"en"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___en",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/princess-cut-diamonds.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"en"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___en",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/radiant-cut-diamonds.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"en"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___en",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/round-diamonds.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"en"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___en",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/trillion-cut-diamonds.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"en"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___en",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/testimonials.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"en"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___en",
    path: indexwXj5m7cZh6Meta?.path ?? "/type-of-diamond-cuts.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"en"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___en",
    path: introductionioT4hAg28aMeta?.path ?? "/introduction.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"en"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___en",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/mixed-cut.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"en"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___en",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/modified-brilliant-cut.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"en"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___en",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/old-mine-old-european-cut.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"en"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___en",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/round-brilliant-cut.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"en"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___en",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/step-cut.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"en"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/fi/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___fi",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/fi/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"fi"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___fi",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/fi/noin-77-timanttia",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"fi"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___fi",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/fi/timantin-anatomia",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"fi"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___fi",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/fi/palkintojen-tiedot",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"fi"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___fi",
    path: awardsYoSh66TkpzMeta?.path ?? "/fi/palkinnot",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"fi"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___fi",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/fi/kanadalaiset-timantit",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"fi"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___fi",
    path: careershFQmXK4dYZMeta?.path ?? "/fi/urat",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"fi"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___fi",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/fi/selkeytta-lisaavat-timantit",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"fi"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___fi",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/fi/vertaa-timanttisertifikaatteja-keskenaan",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"fi"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___fi",
    path: cost6ozuBJSlf2Meta?.path ?? "/fi/kustannus",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"fi"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___fi",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/fi/kihlasormukset/diamantin-osto-opas",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"fi"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___fi",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/fi/diamantin-karaattipaino",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"fi"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___fi",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/fi/diamonditodistukset",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"fi"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___fi",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/fi/diamantin-kirkkauden-asiantuntijaneuvonta",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"fi"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___fi",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/fi/diamanttien-kirkkauden-luokitus",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"fi"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___fi",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/fi/diamondin-kirkkaus",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"fi"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___fi",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/fi/diamantin-vari",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"fi"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___fi",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/fi/diamanttileikkausluokitus",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"fi"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___fi",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/fi/diamanttileikkausprosessi",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"fi"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___fi",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/fi/diamanttileikkaus",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"fi"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___fi",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/fi/diamanttifluoresenssi",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"fi"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___fi",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/fi/diamantti-luokituslaboratoriot",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"fi"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___fi",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/fi/diamantti-sulkeumat",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"fi"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___fi",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/fi/diamanttien-muodot",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"fi"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___fi",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/fi/kihlasormukset/kihlauksen-osto-opas",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"fi"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___fi",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/fi/eettiset-timantit",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"fi"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___fi",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/fi/fancy-varillisia-timantteja",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"fi"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___fi",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/fi/sydamen-nuolet",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"fi"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___fi",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/fi/miten-timantit-hiotaan?",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"fi"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___fi",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/fi/miten-timantit-muodostuvat?",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"fi"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___fi",
    path: indexEyNi2vxOyuMeta?.path ?? "/fi/diamondikoulutus",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"fi"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___fi",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/fi/tarinamme",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"fi"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___fi",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/fi/kihlasormukset/sormusten-kokotaulukko",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"fi"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___fi",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/fi/asscher-hiotut-timantit",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"fi"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___fi",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/fi/tyynyleikattuja-timantteja",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"fi"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___fi",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/fi/emerald-hiontaiset-timantit",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"fi"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___fi",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/fi/sydanhiontaiset-timantit",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"fi"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___fi",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/fi/marquise-hiontaiset-timantit",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"fi"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___fi",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/fi/ovaalihiontaiset-timantit",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"fi"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___fi",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/fi/paarynan-muotoiset-timantit",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"fi"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___fi",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/fi/prinsessahiontaiset-timantit",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"fi"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___fi",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/fi/radianttihiotut-timantit",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"fi"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___fi",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/fi/pyoreat-timantit",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"fi"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___fi",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/fi/triljoona-hiottua-timanttia",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"fi"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___fi",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/fi/esimerkkeja",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"fi"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___fi",
    path: indexwXj5m7cZh6Meta?.path ?? "/fi/timanttileikkaustyyppi",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"fi"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___fi",
    path: introductionioT4hAg28aMeta?.path ?? "/fi/esittelyssa",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"fi"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___fi",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/fi/sekoitettu-leikkaus",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"fi"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___fi",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/fi/muokattu-loistava-leikkaus",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"fi"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___fi",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/fi/vanha-kaivos-vanha-eurooppalainen-leikkaus",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"fi"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___fi",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/fi/pyoristetty-briljanttihionta",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"fi"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___fi",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/fi/vaihe-leikkaus",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"fi"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/fr/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___fr",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/fr/77diamonds-presse.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"fr"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___fr",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/fr/a-propos-de-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"fr"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___fr",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/fr/anatomie-d-un-diamant.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"fr"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___fr",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/fr/details-recompenses.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"fr"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___fr",
    path: awardsYoSh66TkpzMeta?.path ?? "/fr/prix",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"fr"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___fr",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/fr/diamants-canadiens.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"fr"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___fr",
    path: careershFQmXK4dYZMeta?.path ?? "/fr/carrieres.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"fr"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___fr",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/fr/diamants-purete-amelioree.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"fr"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___fr",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/fr/comparer-diamant-certificats.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"fr"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___fr",
    path: cost6ozuBJSlf2Meta?.path ?? "/fr/prix.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"fr"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___fr",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/fr/bagues-de-fiancailles/guide-d-achat-de-diamants",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"fr"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___fr",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/fr/diamant-carat-poids.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"fr"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___fr",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/fr/diamant-certificat.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"fr"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___fr",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/fr/conseil-expert-purete-diamant.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"fr"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___fr",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/fr/classification-purete-diamant.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"fr"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___fr",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/fr/diamant-purete.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"fr"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___fr",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/fr/couleur-diamant.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"fr"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___fr",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/fr/diamants-taille-classification.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"fr"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___fr",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/fr/diamants-processus-taille.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"fr"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___fr",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/fr/diamants-taille.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"fr"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___fr",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/fr/diamant-fluorescence.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"fr"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___fr",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/fr/laboratoires-de-classification-de-diamant.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"fr"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___fr",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/fr/diamant-inclusions.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"fr"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___fr",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/fr/formes-diamant.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"fr"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___fr",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/fr/bagues-de-fiancailles/guide-d-achat-de-bagues-de-fiancailles",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"fr"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___fr",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/fr/diamants-ethiques.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"fr"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___fr",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/fr/diamants-couleur-fantaisie.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"fr"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___fr",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/fr/coeur-fleches.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"fr"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___fr",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/fr/comment-sont-tailles-les-diamants.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"fr"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___fr",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/fr/comment-sont-formes-les-diamants.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"fr"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___fr",
    path: indexEyNi2vxOyuMeta?.path ?? "/fr/diamant-education.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"fr"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___fr",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/fr/notre-histoire",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"fr"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___fr",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/fr/bagues-de-fiancailles/tableau-taille-bague",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"fr"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___fr",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/fr/asscher-taille-diamants.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"fr"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___fr",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/fr/diamants-taille-coussin.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"fr"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___fr",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/fr/diamants-taille-emeraude.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"fr"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___fr",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/fr/diamants-taille-coeur.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"fr"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___fr",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/fr/diamants-taille-marquise.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"fr"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___fr",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/fr/diamants-taille-ovale.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"fr"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___fr",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/fr/diamants-taille-poire.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"fr"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___fr",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/fr/diamants-taille-princesse.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"fr"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___fr",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/fr/diamants-taille-radiant.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"fr"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___fr",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/fr/diamants-ronds.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"fr"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___fr",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/fr/diamants-taille-triangle.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"fr"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___fr",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/fr/temoignages.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"fr"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___fr",
    path: indexwXj5m7cZh6Meta?.path ?? "/fr/types-de-taille-diamant.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"fr"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___fr",
    path: introductionioT4hAg28aMeta?.path ?? "/fr/introduction.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"fr"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___fr",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/fr/taille-mixte.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"fr"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___fr",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/fr/modifie-brillant-taille.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"fr"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___fr",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/fr/ancienne-mine-ancienne-taille-europeenne.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"fr"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___fr",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/fr/taille-brillant-rond.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"fr"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___fr",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/fr/taille-a-degres.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"fr"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/de/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___de",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/de/77diamonds-presse.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"de"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___de",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/de/ueber-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"de"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___de",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/de/anatomie-eines-diamanten.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"de"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___de",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/de/auszeichnungen-details.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"de"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___de",
    path: awardsYoSh66TkpzMeta?.path ?? "/de/auszeichnungen",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"de"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___de",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/de/kanadische-diamanten.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"de"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___de",
    path: careershFQmXK4dYZMeta?.path ?? "/de/karriere.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"de"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___de",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/de/reinheitsverbesserte-diamanten.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"de"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___de",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/de/diamantzertifikate-vergleichen.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"de"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___de",
    path: cost6ozuBJSlf2Meta?.path ?? "/de/kosten.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"de"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___de",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/de/verlobungsring/diamanten-kaufberatung",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"de"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___de",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/de/diamant-karat-gewicht.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"de"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___de",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/de/diamantzertifikate.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"de"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___de",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/de/diamantreinheit-expertenrat.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"de"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___de",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/de/diamanten-reinheitsgrade.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"de"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___de",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/de/diamantreinheit.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"de"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___de",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/de/diamanten-farbgraduierung.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"de"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___de",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/de/diamanten-schliffbewertung.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"de"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___de",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/de/diamanten-schliffverfahren.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"de"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___de",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/de/diamant-schliff.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"de"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___de",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/de/fluoreszierende-diamanten.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"de"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___de",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/de/gemmologische-labore.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"de"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___de",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/de/diamanten-einschluesse.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"de"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___de",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/de/diamant-formen.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"de"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___de",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/de/verlobungsring/kaufberatung",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"de"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___de",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/de/blutdiamanten.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"de"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___de",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/de/farbige-diamanten.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"de"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___de",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/de/herzen-pfeile.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"de"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___de",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/de/so-werden-diamanten-geschliffen.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"de"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___de",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/de/so-entstehen-diamanten.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"de"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___de",
    path: indexEyNi2vxOyuMeta?.path ?? "/de/diamanten-wissen.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"de"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___de",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/de/unsere-geschichte",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"de"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___de",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/de/verlobungsring/ringgrobe",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"de"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___de",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/de/diamanten-mit-asscher-schliff.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"de"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___de",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/de/diamanten-mit-cushion-schliff.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"de"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___de",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/de/diamanten-mit-smaragd-schliff.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"de"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___de",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/de/diamanten-mit-herzschliff.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"de"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___de",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/de/diamanten-mit-marquise-schliff.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"de"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___de",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/de/diamanten-mit-ovalschliff.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"de"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___de",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/de/diamanten-mit-tropfenschliff.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"de"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___de",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/de/diamanten-prinzess-schliff.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"de"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___de",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/de/diamanten-mit-radiant-schliff.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"de"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___de",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/de/runde-diamanten.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"de"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___de",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/de/diamanten-mit-trillion-schliff.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"de"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___de",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/de/referenzen.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"de"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___de",
    path: indexwXj5m7cZh6Meta?.path ?? "/de/diamantformen.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"de"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___de",
    path: introductionioT4hAg28aMeta?.path ?? "/de/einfuehrung.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"de"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___de",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/de/gemischter-schliff.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"de"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___de",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/de/modifizierter-brillantschliff.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"de"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___de",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/de/alte-mine-alter-europaischer-schnitt.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"de"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___de",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/de/brilliantschliff-rund.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"de"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___de",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/de/treppenschliff.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"de"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/it/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___it",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/it/77diamonds-stampa.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"it"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___it",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/it/riguardo-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"it"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___it",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/it/anatomia-di-un-diamante.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"it"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___it",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/it/premi-dettagli.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"it"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___it",
    path: awardsYoSh66TkpzMeta?.path ?? "/it/premi",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"it"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___it",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/it/diamanti-canadesi.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"it"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___it",
    path: careershFQmXK4dYZMeta?.path ?? "/it/carriere.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"it"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___it",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/it/purezza-migliorata-diamanti.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"it"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___it",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/it/confronta-certificati-diamanti.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"it"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___it",
    path: cost6ozuBJSlf2Meta?.path ?? "/it/costo.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"it"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___it",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/it/anelli-fidanzamento/guida-acquisto-diamante",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"it"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___it",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/it/peso-diamanti-carati.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"it"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___it",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/it/certificati-diamanti.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"it"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___it",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/it/consiglio-esperto-purezza-diamante.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"it"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___it",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/it/valutazione-purezza-diamante.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"it"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___it",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/it/purezza-diamante.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"it"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___it",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/it/colore-diamante.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"it"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___it",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/it/valutazione-taglio-diamante.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"it"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___it",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/it/processo-taglio-diamanti.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"it"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___it",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/it/taglio-diamante.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"it"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___it",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/it/fluorescenza-diamanti.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"it"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___it",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/it/categorie-valutazione-diamanti.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"it"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___it",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/it/aggiungere-diamanti.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"it"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___it",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/it/forme-diamante.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"it"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___it",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/it/anelli-fidanzamento/guida-acquisto-fidanzamento",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"it"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___it",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/it/diamanti-etici.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"it"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___it",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/it/diamanti-fancy-colorati.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"it"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___it",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/it/frecce-cuore.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"it"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___it",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/it/come-vengono-tagliati-diamanti.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"it"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___it",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/it/come-si-formano-diamanti.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"it"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___it",
    path: indexEyNi2vxOyuMeta?.path ?? "/it/educazione-diamante.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"it"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___it",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/it/la-nostra-storia",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"it"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___it",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/it/anelli-fidanzamento/tabella-misura-anello",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"it"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___it",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/it/diamanti-taglio-asscher.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"it"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___it",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/it/diamanti-taglio-cuscino.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"it"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___it",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/it/diamanti-taglio-smeraldo.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"it"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___it",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/it/diamanti-taglio-cuore.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"it"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___it",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/it/diamanti-taglio-marquise.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"it"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___it",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/it/diamanti-taglio-ovale.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"it"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___it",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/it/diamanti-taglio-goccia.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"it"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___it",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/it/diamanti-taglio-princess.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"it"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___it",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/it/diamanti-taglio-radiante.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"it"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___it",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/it/diamanti-rotondi.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"it"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___it",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/it/diamanti-taglio-trillion.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"it"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___it",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/it/testimonials.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"it"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___it",
    path: indexwXj5m7cZh6Meta?.path ?? "/it/tipologia-di-taglio-diamante.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"it"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___it",
    path: introductionioT4hAg28aMeta?.path ?? "/it/introduzione.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"it"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___it",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/it/taglio-misto.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"it"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___it",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/it/taglio-brillante-modificato.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"it"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___it",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/it/taglio-old-mine-europeo-antico.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"it"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___it",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/it/taglio-rotondo-brillante.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"it"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___it",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/it/taglio-scalato.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"it"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/cn/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___cn",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/cn/diamondsxin-wen-bao-dao.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"cn"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___cn",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/cn/guan-yu-diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"cn"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___cn",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/cn/zuan-shi-jie-gou-xiang-jie.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"cn"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___cn",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/cn/jiang-xiang-xiang-qing.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"cn"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___cn",
    path: awardsYoSh66TkpzMeta?.path ?? "/cn/jiang-xiang",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"cn"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___cn",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/cn/jia-na-da-zuan-shi.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"cn"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___cn",
    path: careershFQmXK4dYZMeta?.path ?? "/cn/zhi-ye-ji-hui.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"cn"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___cn",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/cn/jing-du-zeng-qiang-xing-zuan-shi.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"cn"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___cn",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/cn/dui-bi-zuan-shi-zheng-shu.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"cn"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___cn",
    path: cost6ozuBJSlf2Meta?.path ?? "/cn/cheng-ben.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"cn"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___cn",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/cn/ding-hun-jie-zhi/zuan-shi-gou-mai-zhi-nan",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"cn"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___cn",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/cn/zuan-shi-ke-la-chong.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"cn"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___cn",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/cn/zuan-shi-zheng-shu.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"cn"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___cn",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/cn/zuan-shi-jing-du-zhuan-jia-jian-yi.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"cn"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___cn",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/cn/zuan-shi-jing-du-fen-ji.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"cn"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___cn",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/cn/zuan-shi-jing-du.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"cn"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___cn",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/cn/zuan-shi-yan-se.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"cn"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___cn",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/cn/zuan-shi-qie-gong-fen-ji.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"cn"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___cn",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/cn/zuan-shi-qie-ge-guo-cheng.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"cn"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___cn",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/cn/zuan-shi-qie-gong.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"cn"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___cn",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/cn/zuan-shi-ying-guang.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"cn"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___cn",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/cn/zuan-shi-fen-ji-shi-yan-shi.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"cn"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___cn",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/cn/zuan-shi-nei-han-wu.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"cn"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___cn",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/cn/zuan-shi-xing-zhuang.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"cn"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___cn",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/cn/ding-hun-jie-zhi/ding-hun-gou-wu-zhi-nan",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"cn"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___cn",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/cn/dao-de-zuan-shi.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"cn"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___cn",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/cn/zuan-shi-zhi-shi/cai-se-zuan-shi.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"cn"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___cn",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/cn/ba-xin-ba-jian.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"cn"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___cn",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/cn/zuan-shi-shi-ru-he-qie-ge-de.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"cn"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___cn",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/cn/zuan-shi-shi-ru-he-xing-cheng-de.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"cn"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___cn",
    path: indexEyNi2vxOyuMeta?.path ?? "/cn/zuan-shi-zhi-shi.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"cn"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___cn",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/cn/wo-men-de-gu-shi",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"cn"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___cn",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/cn/ding-hun-jie-zhi/jie-zhi-chi-cun-can-zhao-biao",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"cn"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___cn",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/cn/A-si-qie-xing-qie-gong-zuan-shi.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"cn"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___cn",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/cn/dian-xing-qie-gong-zuan-shi.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"cn"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___cn",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/cn/zu-mu-lu-xing-qie-gong-zuan-shi.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"cn"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___cn",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/cn/xin-xing-qie-gong-zuan-shi.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"cn"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___cn",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/cn/ma-yan-xing-qie-gong-zuan-shi.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"cn"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___cn",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/cn/tuo-yuan-xing-qie-gong-zuan-shi.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"cn"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___cn",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/cn/li-xing-xing-zhuang-zuan-shi.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"cn"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___cn",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/cn/gong-zhu-fang-xing-qie-gong-zuan-shi.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"cn"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___cn",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/cn/lei-di-en-xing-qie-gong-zuan-shi.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"cn"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___cn",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/cn/yuan-xing-zuan-shi.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"cn"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___cn",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/cn/san-jiao-xing-qie-gong-zuan-shi.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"cn"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___cn",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/cn/gan-xie-xin.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"cn"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___cn",
    path: indexwXj5m7cZh6Meta?.path ?? "/cn/zuan-shi-qie-gong-lei-xing.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"cn"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___cn",
    path: introductionioT4hAg28aMeta?.path ?? "/cn/jie-shao.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"cn"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___cn",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/cn/hun-he-qie-gong.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"cn"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___cn",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/cn/gai-liang-xing-ming-liang-shi-qie-gong.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"cn"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___cn",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/cn/lao-kuang-shi-lao-ou-zhou-shi-qie-gong.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"cn"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___cn",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/cn/yuan-xing-ming-liang-shi-qie-gong.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"cn"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___cn",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/cn/jie-ti-shi-qie-gong.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"cn"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/no/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___no",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/no/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"no"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___no",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/no/omtrent-77-diamanter",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"no"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___no",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/no/anatomien-av-en-diamant",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"no"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___no",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/no/detaljer-om-utmerkelsene",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"no"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___no",
    path: awardsYoSh66TkpzMeta?.path ?? "/no/utmerkelser",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"no"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___no",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/no/kanadiske-diamanter",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"no"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___no",
    path: careershFQmXK4dYZMeta?.path ?? "/no/karriere",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"no"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___no",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/no/-klarhetsforsterkede-diamanter",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"no"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___no",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/no/-sammenligne-diamantsertifikater",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"no"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___no",
    path: cost6ozuBJSlf2Meta?.path ?? "/no/kostnad",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"no"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___no",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/no/forlovelsesringer/diamantkjoepsguide",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"no"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___no",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/no/diamantkaratvekt",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"no"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___no",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/no/diamantsertifikater",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"no"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___no",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/no/-ekspertrad-om-klarhet-i-diamanter",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"no"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___no",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/no/klassifisering-av-diamanters-klarhet",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"no"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___no",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/no/-klarhet-i-diamanter",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"no"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___no",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/no/diamantfarge",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"no"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___no",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/no/diamantslipt-gradering",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"no"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___no",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/no/diamantslipeprosessen",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"no"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___no",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/no/diamantsliping",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"no"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___no",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/no/diamantfluorescens",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"no"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___no",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/no/laboratorier-for-diamantsortering",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"no"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___no",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/no/inneslutninger-av-diamanter",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"no"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___no",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/no/diamantformer",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"no"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___no",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/no/forlovelsesringer/guide-til-kjoep-av-forlovelsesringer",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"no"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___no",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/no/etiske-diamanter",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"no"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___no",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/no/fancy-fargede-diamanter",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"no"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___no",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/no/hjertets-piler",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"no"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___no",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/no/hvordan-slipes-diamanter?",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"no"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___no",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/no/hvordan-dannes-diamanter?",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"no"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___no",
    path: indexEyNi2vxOyuMeta?.path ?? "/no/diamantutdanning",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"no"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___no",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/no/var-historie",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"no"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___no",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/no/forlovelsesringer/ringstoerrelsestabell",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"no"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___no",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/no/asscherslipte-diamanter",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"no"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___no",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/no/-puteslipte-diamanter",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"no"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___no",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/no/emeraldslipte-diamanter",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"no"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___no",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/no/hjerteslipte-diamanter",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"no"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___no",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/no/marquiseslipte-diamanter",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"no"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___no",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/no/-ovalslipte-diamanter",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"no"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___no",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/no/-diamanter-i-preform",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"no"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___no",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/no/prinsesseslipte-diamanter",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"no"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___no",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/no/-diamanter-med-stralende-sliping",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"no"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___no",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/no/runde-diamanter",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"no"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___no",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/no/-trillionslipte-diamanter",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"no"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___no",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/no/uttalelser",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"no"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___no",
    path: indexwXj5m7cZh6Meta?.path ?? "/no/type-diamantsliping",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"no"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___no",
    path: introductionioT4hAg28aMeta?.path ?? "/no/innledning",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"no"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___no",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/no/blandet-snitt",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"no"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___no",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/no/modifisert-briljant-klipping",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"no"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___no",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/no/old-mine-old-european-cut",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"no"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___no",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/no/-rund-briljantsliping",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"no"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___no",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/no/trinn-kutt",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"no"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/pl/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___pl",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/pl/77diamonds-dla-prasy.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"pl"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___pl",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/pl/o-firmie-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"pl"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___pl",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/pl/anatomia-diamentu.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"pl"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___pl",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/pl/nagrody-szczegoly.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"pl"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___pl",
    path: awardsYoSh66TkpzMeta?.path ?? "/pl/nagrody",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"pl"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___pl",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/pl/diamenty-kanadyjskie.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"pl"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___pl",
    path: careershFQmXK4dYZMeta?.path ?? "/pl/praca.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"pl"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___pl",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/pl/diamenty-o-poprawionej-czystosci.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"pl"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___pl",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/pl/porownaj-certyfikaty-diamentow.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"pl"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___pl",
    path: cost6ozuBJSlf2Meta?.path ?? "/pl/koszt.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"pl"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___pl",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/pl/pierscionki-zareczynowe/przewodnik-po-kupowaniu-diamentow",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"pl"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___pl",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/pl/diament-waga-w-karatach.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"pl"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___pl",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/pl/certyfikaty-diamentow.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"pl"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___pl",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/pl/diamenty-czystosc-rada-eksperta.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"pl"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___pl",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/pl/klasyfikacja-czystosci-diamentow.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"pl"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___pl",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/pl/czystosc-diamentu.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"pl"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___pl",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/pl/barwa-diamentu.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"pl"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___pl",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/pl/klasyfikacja-szlifow-diamentow.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"pl"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___pl",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/pl/diamenty-proces-szlifowania.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"pl"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___pl",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/pl/szlif-diamentu.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"pl"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___pl",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/pl/diamenty-fluorescencja.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"pl"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___pl",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/pl/diamenty-laboratoria-oceniajace.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"pl"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___pl",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/pl/diamenty-inkluzje.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"pl"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___pl",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/pl/ksztalty-diamentow.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"pl"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___pl",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/pl/pierscionki-zareczynowe/poradnik-kupowania-zareczynowego",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"pl"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___pl",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/pl/diamenty-etyczne.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"pl"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___pl",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/pl/diamenty-kolory-fantazyjne.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"pl"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___pl",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/pl/hearts-arrows.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"pl"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___pl",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/pl/jak-szlifowane-sa-diamenty.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"pl"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___pl",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/pl/jak-powstaja-diamenty.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"pl"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___pl",
    path: indexEyNi2vxOyuMeta?.path ?? "/pl/edukacja-na-temat-diamentow.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"pl"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___pl",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/pl/77diamonds-nasza-historia.html",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"pl"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___pl",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/pl/pierscionki-zareczynowe/tabela-rozmiarow-pierscionkow",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"pl"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___pl",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/pl/diamenty-szlif-asscher.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"pl"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___pl",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/pl/diamenty-szlif-poduszka.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"pl"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___pl",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/pl/diamenty-szlif-szmaragdowy.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"pl"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___pl",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/pl/diamenty-szlif-serce.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"pl"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___pl",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/pl/diamenty-szlif-markiza.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"pl"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___pl",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/pl/diamenty-szlif-owalny.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"pl"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___pl",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/pl/diamenty-szlif-gruszka.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"pl"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___pl",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/pl/diamenty-szlif-princessa.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"pl"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___pl",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/pl/diamenty-szlif-radiant.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"pl"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___pl",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/pl/diamenty-okragle.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"pl"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___pl",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/pl/diamenty-szlif-trillion.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"pl"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___pl",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/pl/opinie.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"pl"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___pl",
    path: indexwXj5m7cZh6Meta?.path ?? "/pl/typy-szlifow-diamentowych.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"pl"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___pl",
    path: introductionioT4hAg28aMeta?.path ?? "/pl/wprowadzenie.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"pl"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___pl",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/pl/szlif-mieszany.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"pl"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___pl",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/pl/szlif-brylantowy-zmodyfikowany.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"pl"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___pl",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/pl/szlif-old-european-mine.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"pl"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___pl",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/pl/round-brilliant-cut.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"pl"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___pl",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/pl/diamenty-szlif-schodkowy.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"pl"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/pt/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___pt",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/pt/77diamantes-press.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"pt"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___pt",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/pt/about-77diamantes.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"pt"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___pt",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/pt/anatomy-of-a-diamond.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"pt"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___pt",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/pt/awards-details.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"pt"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___pt",
    path: awardsYoSh66TkpzMeta?.path ?? "/pt/premios",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"pt"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___pt",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/pt/canadian-diamantes.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"pt"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___pt",
    path: careershFQmXK4dYZMeta?.path ?? "/pt/careers.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"pt"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___pt",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/pt/clarity-enhanced-diamantes.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"pt"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___pt",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/pt/compare-diamond-certificates.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"pt"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___pt",
    path: cost6ozuBJSlf2Meta?.path ?? "/pt/cost.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"pt"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___pt",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/pt/aneis-de-noivado/guia-compra-diamantes",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"pt"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___pt",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/pt/diamond-carat-weight.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"pt"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___pt",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/pt/diamond-certificates.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"pt"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___pt",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/pt/diamond-clarity-expert-advice.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"pt"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___pt",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/pt/diamond-clarity-grading.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"pt"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___pt",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/pt/diamond-clarity.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"pt"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___pt",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/pt/diamond-colour.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"pt"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___pt",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/pt/diamond-cut-grading.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"pt"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___pt",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/pt/diamond-cut-process.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"pt"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___pt",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/pt/diamond-cut.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"pt"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___pt",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/pt/diamond-fluorescence.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"pt"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___pt",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/pt/diamond-grading-labs.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"pt"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___pt",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/pt/diamond-inclusions.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"pt"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___pt",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/pt/diamond-shapes.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"pt"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___pt",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/pt/aneis-de-noivado/guia-compra-aneis-de-noivado",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"pt"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___pt",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/pt/ethical-diamantes.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"pt"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___pt",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/pt/fancy-coloured-diamantes.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"pt"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___pt",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/pt/coracaos-arrows.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"pt"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___pt",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/pt/how-are-diamantes-cut.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"pt"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___pt",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/pt/how-are-diamantes-formed.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"pt"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___pt",
    path: indexEyNi2vxOyuMeta?.path ?? "/pt/diamond-educacao.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"pt"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___pt",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/pt/a-nossa-historia",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"pt"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___pt",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/pt/aneis-de-noivado/chart-tamanhos-de-aneis",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"pt"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___pt",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/pt/asscher-cut-diamantes.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"pt"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___pt",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/pt/almofada-cut-diamantes.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"pt"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___pt",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/pt/esmeralda-cut-diamantes.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"pt"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___pt",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/pt/coracao-cut-diamantes.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"pt"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___pt",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/pt/marquise-cut-diamantes.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"pt"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___pt",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/pt/oval-cut-diamantes.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"pt"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___pt",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/pt/pera-shape-diamantes.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"pt"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___pt",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/pt/princesa-cut-diamantes.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"pt"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___pt",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/pt/radiante-cut-diamantes.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"pt"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___pt",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/pt/redondo-diamantes.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"pt"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___pt",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/pt/trillion-cut-diamantes.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"pt"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___pt",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/pt/testimonials.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"pt"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___pt",
    path: indexwXj5m7cZh6Meta?.path ?? "/pt/type-of-diamond-cuts.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"pt"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___pt",
    path: introductionioT4hAg28aMeta?.path ?? "/pt/introduction.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"pt"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___pt",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/pt/mixed-cut.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"pt"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___pt",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/pt/modified-brilliant-cut.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"pt"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___pt",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/pt/old-mine-old-european-cut.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"pt"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___pt",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/pt/redondo-brilliant-cut.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"pt"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___pt",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/pt/step-cut.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"pt"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/ro/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___ro",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/ro/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ro"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___ro",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/ro/aproximativ-77diamante",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ro"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___ro",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/ro/anatomia-unui-diamant",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ro"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___ro",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/ro/detalii-premii",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ro"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___ro",
    path: awardsYoSh66TkpzMeta?.path ?? "/ro/premii",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ro"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___ro",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/ro/diamante-canadiene",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ro"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___ro",
    path: careershFQmXK4dYZMeta?.path ?? "/ro/cariere",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ro"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___ro",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/ro/diamante-de-claritate-imbunatatita",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ro"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___ro",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/ro/comparati-certificatele-de-diamant",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ro"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___ro",
    path: cost6ozuBJSlf2Meta?.path ?? "/ro/cost",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ro"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___ro",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/ro/aneluri-de-logodna/ghid-de-cumparare-a-diamantelor",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ro"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___ro",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/ro/greutatea-in-carate-a-diamantului",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ro"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___ro",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/ro/certificate-de-diamant",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ro"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___ro",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/ro/consiliere-de-specialitate-in-materie-de-claritate-a-diamantelor",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ro"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___ro",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/ro/clasa-de-claritate-a-diamantului",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ro"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___ro",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/ro/claritate-diamant",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ro"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___ro",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/ro/culoare-diamant",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ro"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___ro",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/ro/diamant-taiat-gradat",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ro"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___ro",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/ro/procesul-de-taiere-a-diamantelor",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ro"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___ro",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/ro/diamant-taiat",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ro"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___ro",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/ro/fluorescenta-diamantului",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ro"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___ro",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/ro/laboratoare-de-clasificare-a-diamantelor",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ro"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___ro",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/ro/incluziuni-de-diamant",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ro"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___ro",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/ro/forme-de-diamant",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ro"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___ro",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/ro/aneluri-de-logodna/ghid-de-cumparaturi-pentru-logodna",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ro"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___ro",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/ro/diamante-etice",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ro"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___ro",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/ro/diamante-colorate-de-lux",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ro"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___ro",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/ro/sageti-de-inima",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ro"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___ro",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/ro/cum-se-taie-diamantele",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ro"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___ro",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/ro/cum-se-formeaza-diamantele",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ro"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___ro",
    path: indexEyNi2vxOyuMeta?.path ?? "/ro/educatie-cu-diamante",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ro"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___ro",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/ro/povestea-noastra",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ro"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___ro",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/ro/aneluri-de-logodna/dimensiuni-inele-de-logodna",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ro"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___ro",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/ro/diamantele-taiate-in-forma-de-ascher",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ro"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___ro",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/ro/diamantele-taiate-in-forma-de-perna",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ro"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___ro",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/ro/diamante-taiate-smarald",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ro"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___ro",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/ro/diamante-taiate-in-forma-de-inima",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ro"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___ro",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/ro/diamante-taiate-in-forma-de-marchiza",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ro"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___ro",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/ro/diamante-taiate-oval",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ro"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___ro",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/ro/diamante-in-forma-de-para",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ro"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___ro",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/ro/diamante-cu-taietura-printesa",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ro"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___ro",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/ro/diamante-taiate-radiant",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ro"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___ro",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/ro/diamante-rotunde",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ro"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___ro",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/ro/diamantele-cu-taietura-trilionara",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ro"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___ro",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/ro/testimoniale",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ro"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___ro",
    path: indexwXj5m7cZh6Meta?.path ?? "/ro/tipuri-de-diamante-taiate",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ro"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___ro",
    path: introductionioT4hAg28aMeta?.path ?? "/ro/introducere",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ro"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ro",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/ro/mediu-taiat",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ro"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ro",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/ro/modified-brilliant-cut",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ro"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ro",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/ro/old-mine-old-european-cut",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ro"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ro",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/ro/-rotund-brilliant-cut",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ro"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___ro",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/ro/pasul-de-taiere",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ro"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/ru/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___ru",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/ru/77diamonds-pressa.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ru"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___ru",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/ru/o-nas-77-diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ru"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___ru",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/ru/anatomija-brillianta.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ru"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___ru",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/ru/nagrady-podrobnosti.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ru"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___ru",
    path: awardsYoSh66TkpzMeta?.path ?? "/ru/nagrady",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ru"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___ru",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/ru/kanadskie-brillianty.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ru"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___ru",
    path: careershFQmXK4dYZMeta?.path ?? "/ru/vakansii.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ru"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___ru",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/ru/modifikacija-chistoty-brillianta.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ru"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___ru",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/ru/sravnit--sertifikaty.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ru"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___ru",
    path: cost6ozuBJSlf2Meta?.path ?? "/ru/stoimost-.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ru"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___ru",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/ru/pomolvochnye-kol-ca/rukovodstvo-po-pokupke-brillianta",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ru"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___ru",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/ru/karatnyj-ves-brillianta.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ru"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___ru",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/ru/sertifikat-brillianta.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ru"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___ru",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/ru/chistota-brillianta-sovet-eksperta.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ru"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___ru",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/ru/brilliant-chistota.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ru"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___ru",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/ru/chistota-brillianta.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ru"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___ru",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/ru/cvet-brillianta.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ru"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___ru",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/ru/ocenka-brilliantovoj-ogranki.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ru"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___ru",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/ru/process-ogranki-brilliantov.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ru"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___ru",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/ru/ogranka-brillianta.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ru"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___ru",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/ru/fluorescencija-brillianta.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ru"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___ru",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/ru/laboratorii-ocenivajushhie-brillianty.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ru"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___ru",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/ru/vkljuchenija-v-brilliantah.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ru"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___ru",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/ru/forma-brillianta.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ru"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___ru",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/ru/pomolvochnye-kol-ca/rukovodstvo-po-pokupke.html",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ru"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___ru",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/ru/eticheskie-brillianty.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ru"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___ru",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/ru/brillianty-fantazijnogo-okrasa.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ru"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___ru",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/ru/serdca-i-strely.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ru"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___ru",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/ru/kak-granjat-almazy.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ru"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___ru",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/ru/kak-obrazujutsja-almazy.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ru"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___ru",
    path: indexEyNi2vxOyuMeta?.path ?? "/ru/vsjo-o-brilliantah",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ru"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___ru",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/ru/our-story",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ru"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___ru",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/ru/pomolvochnye-kol-ca/tablica-razmerov.html",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ru"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___ru",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/ru/brillianty-ogranki-asher.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ru"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___ru",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/ru/brillianty-ogranki-kushon.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ru"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___ru",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/ru/brillianty-ogranki-izumrud.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ru"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___ru",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/ru/brillianty-ogranki-serdce.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ru"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___ru",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/ru/brillianty-ogranki-markiz.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ru"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___ru",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/ru/brillianty-ogranki-oval.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ru"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___ru",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/ru/brillianty-ogranki-kaplja.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ru"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___ru",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/ru/brillianty-ogranki-princessa.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ru"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___ru",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/ru/brillianty-ogranki-radiant.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ru"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___ru",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/ru/kruglye-brillianty.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ru"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___ru",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/ru/brillianty-ogranki-trillion.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ru"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___ru",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/ruotzyvy.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ru"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___ru",
    path: indexwXj5m7cZh6Meta?.path ?? "/ru/tipy-ogranki-brillianta.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ru"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___ru",
    path: introductionioT4hAg28aMeta?.path ?? "/ru/vvedenie.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ru"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ru",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/ru/smeshannaja-ogranka..html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ru"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ru",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/ru/izmenennaja-ogranka-brilliant.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ru"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ru",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/ru/brillianty-starinnoj-ogranki.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ru"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ru",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/ru/brillianty-ogranki-krug.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ru"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___ru",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/rustupenchataja-ogranka.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ru"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/es/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___es",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/es/77diamonds-prensa",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"es"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___es",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/es/sobre-77diamonds",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"es"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___es",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/es/anatomia-del-diamante",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"es"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___es",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/es/premios-detalles",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"es"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___es",
    path: awardsYoSh66TkpzMeta?.path ?? "/es/premios",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"es"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___es",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/es/diamantes-canadienses",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"es"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___es",
    path: careershFQmXK4dYZMeta?.path ?? "/es/carreras",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"es"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___es",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/es/diamantes-con-claridad-mejorada",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"es"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___es",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/es/compare-certificados-de-diamantes",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"es"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___es",
    path: cost6ozuBJSlf2Meta?.path ?? "/es/costo",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"es"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___es",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/es/anillos-de-compromiso/guia-de-compra-del-diamante",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"es"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___es",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/es/peso-en-quilates-del-diamante",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"es"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___es",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/es/certificados-de-diamantes",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"es"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___es",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/es/diamantes-pureza-consejos-expertos",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"es"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___es",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/es/grados-de-pureza-del-diamante",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"es"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___es",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/es/claridad-del-diamante",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"es"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___es",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/es/diamante-coloreado",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"es"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___es",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/es/clasificacion-del-corte-del-diamante",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"es"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___es",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/es/proceso-de-corte-del-diamante",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"es"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___es",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/es/corte-del-diamante",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"es"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___es",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/es/diamantes-fluorescencia",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"es"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___es",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/es/laboratorios-calificacion-diamantes",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"es"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___es",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/es/inclusiones-del-diamante",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"es"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___es",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/es/formas-del-diamantes",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"es"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___es",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/es/anillos-compromiso/guia-compra-compromiso",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"es"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___es",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/es/diamantes-eticos",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"es"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___es",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/es/diamantes-fantasia",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"es"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___es",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/es/hearts-arrows",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"es"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___es",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/es/como-se-cortan-los-diamantes",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"es"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___es",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/es/como-se-forman-los-diamantes",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"es"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___es",
    path: indexEyNi2vxOyuMeta?.path ?? "/es/educacion-diamante",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"es"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___es",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/es/nuestra-historia",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"es"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___es",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/es/anillos-de-compromiso/tabla-de-tallas-de-anillos",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"es"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___es",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/es/diamantes-corte-asscher",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"es"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___es",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/es/diamantes-corte-cojin",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"es"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___es",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/es/diamantes-corte-esmeralda",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"es"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___es",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/es/diamantes-talla-corazon",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"es"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___es",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/es/diamantes-corte-marquesa",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"es"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___es",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/es/diamantes-corte-oval",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"es"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___es",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/es/diamantes-forma-pera",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"es"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___es",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/es/diamantes-corte-princesa",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"es"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___es",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/es/diamantes-corte-radiante",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"es"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___es",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/es/diamantes-redondo",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"es"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___es",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/es/diamantes-corte-triangular",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"es"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___es",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/es/testimonios",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"es"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___es",
    path: indexwXj5m7cZh6Meta?.path ?? "/es/tipos-de-corte-de-diamante",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"es"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___es",
    path: introductionioT4hAg28aMeta?.path ?? "/es/introduccion",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"es"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___es",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/es/cortes-mixtos",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"es"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___es",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/es/corte-brillante-modificado",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"es"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___es",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/es/corte-antiguo-de-mina-corte-europeo",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"es"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___es",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/es/corte-brillante-redondo",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"es"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___es",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/es/corte-escalonado",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"es"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/se/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___se",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/se/77diamonds-press.html",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"se"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___se",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/se/om-77diamonds.html",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"se"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___se",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/se/uppbyggnaden-av-en-diamant.html",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"se"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___se",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/se/utmarkelser-detaljer.html",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"se"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___se",
    path: awardsYoSh66TkpzMeta?.path ?? "/se/utmarkelser",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"se"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___se",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/se/kanadensiska-diamanter.html",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"se"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___se",
    path: careershFQmXK4dYZMeta?.path ?? "/se/karriar.html",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"se"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___se",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/se/klarhet-enhanced-diamanter.html",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"se"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___se",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/se/jamfor-diamant-certifikat.html",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"se"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___se",
    path: cost6ozuBJSlf2Meta?.path ?? "/se/pris.html",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"se"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___se",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/se/forlovningsringar/diamant-kop-guide",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"se"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___se",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/se/diamant-karat-vikt.html",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"se"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___se",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/se/diamant-certifikat.html",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"se"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___se",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/se/diamant-klarhet-expert-rad.html",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"se"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___se",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/se/diamant-klarhets-grad.html",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"se"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___se",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/se/diamant-klarhet.html",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"se"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___se",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/se/diamant-farg.html",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"se"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___se",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/se/diamant-skarningar-gradering.html",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"se"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___se",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/se/diamant-skurning-process.html",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"se"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___se",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/se/diamant-skarningar.html",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"se"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___se",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/se/diamant-fluorescens.html",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"se"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___se",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/se/diamant-graderingar-labb.html",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"se"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___se",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/se/diamant-inneslutningar.html",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"se"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___se",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/se/diamant-form.html",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"se"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___se",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/se/forlovningsringar/forlovning-kop-guide",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"se"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___se",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/se/etiska-diamanter.html",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"se"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___se",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/se/fancy-fargade-diamanter.html",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"se"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___se",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/se/hjartats-vinklar.html",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"se"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___se",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/se/hur-ar-diamanter-skurna.html",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"se"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___se",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/se/hur-ar-diamanter-formade.html",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"se"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___se",
    path: indexEyNi2vxOyuMeta?.path ?? "/se/diamant-utbildningar.html",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"se"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___se",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/se/var-story",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"se"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___se",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/se/forlovningsringar/ringstorlek",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"se"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___se",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/se/asscher-skurna-diamanter.html",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"se"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___se",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/se/kudde-skurna-diamanter.html",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"se"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___se",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/se/smaragd-skurna-diamanter.html",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"se"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___se",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/se/hjarta-skurna-diamanter.html",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"se"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___se",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/se/markis-skurna-diamanter.html",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"se"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___se",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/se/oval-skurna-diamanter.html",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"se"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___se",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/se/paron-skurna-diamanter.html",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"se"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___se",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/se/prinsessa-skurning-diamanter.html",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"se"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___se",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/se/stralande-skurna-diamanter.html",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"se"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___se",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/se/rund-diamanter.html",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"se"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___se",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/se/trillion-skurna-diamanter.html",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"se"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___se",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/se/kundreferenser.html",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"se"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___se",
    path: indexwXj5m7cZh6Meta?.path ?? "/se/typer-av-diamant-skarningar.html",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"se"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___se",
    path: introductionioT4hAg28aMeta?.path ?? "/se/introduktion.html",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"se"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___se",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/se/mixad-skurning.html",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"se"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___se",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/se/modifierad-brilliant-skurning.html",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"se"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___se",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/se/gammal-europeisk-skurning.html",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"se"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___se",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/se/rund-stralande-skurning.html",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"se"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___se",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/se/step-skurning.html",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"se"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    path: diamond_45educationMW6QW1Y00NMeta?.path ?? "/hu/education/diamond-education",
    children: [
  {
    name: _77diamonds_45press1HaEIkx1wYMeta?.name ?? "54_education-diamond-education-77diamonds-press___hu",
    path: _77diamonds_45press1HaEIkx1wYMeta?.path ?? "/hu/77diamonds-press",
    meta: {...(_77diamonds_45press1HaEIkx1wYMeta || {}), ...{"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"hu"}},
    alias: _77diamonds_45press1HaEIkx1wYMeta?.alias || [],
    redirect: _77diamonds_45press1HaEIkx1wYMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue").then(m => m.default || m)
  },
  {
    name: about_4577diamondsw3d5Fwoq4bMeta?.name ?? "53_education-diamond-education-about-77diamonds___hu",
    path: about_4577diamondsw3d5Fwoq4bMeta?.path ?? "/hu/kabe-77gyemantrol",
    meta: {...(about_4577diamondsw3d5Fwoq4bMeta || {}), ...{"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"hu"}},
    alias: about_4577diamondsw3d5Fwoq4bMeta?.alias || [],
    redirect: about_4577diamondsw3d5Fwoq4bMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.name ?? "79_education-diamond-education-anatomy-of-a-diamond___hu",
    path: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.path ?? "/hu/a-gyemant-anatomiaja",
    meta: {...(anatomy_45of_45a_45diamondKDwHf7DGzNMeta || {}), ...{"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"hu"}},
    alias: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.alias || [],
    redirect: anatomy_45of_45a_45diamondKDwHf7DGzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue").then(m => m.default || m)
  },
  {
    name: awards_45detailsxMKpp7x4pjMeta?.name ?? "70_education-diamond-education-awards-details___hu",
    path: awards_45detailsxMKpp7x4pjMeta?.path ?? "/hu/jutalmak-reszletei",
    meta: {...(awards_45detailsxMKpp7x4pjMeta || {}), ...{"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"hu"}},
    alias: awards_45detailsxMKpp7x4pjMeta?.alias || [],
    redirect: awards_45detailsxMKpp7x4pjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards-details.vue").then(m => m.default || m)
  },
  {
    name: awardsYoSh66TkpzMeta?.name ?? "55_education-diamond-education-awards___hu",
    path: awardsYoSh66TkpzMeta?.path ?? "/hu/jutalmak",
    meta: {...(awardsYoSh66TkpzMeta || {}), ...{"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"hu"}},
    alias: awardsYoSh66TkpzMeta?.alias || [],
    redirect: awardsYoSh66TkpzMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/awards.vue").then(m => m.default || m)
  },
  {
    name: canadian_45diamonds2VOU9iCF2pMeta?.name ?? "82_education-diamond-education-canadian-diamonds___hu",
    path: canadian_45diamonds2VOU9iCF2pMeta?.path ?? "/hu/kanadai-gyemantok",
    meta: {...(canadian_45diamonds2VOU9iCF2pMeta || {}), ...{"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"hu"}},
    alias: canadian_45diamonds2VOU9iCF2pMeta?.alias || [],
    redirect: canadian_45diamonds2VOU9iCF2pMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue").then(m => m.default || m)
  },
  {
    name: careershFQmXK4dYZMeta?.name ?? "56_education-diamond-education-careers___hu",
    path: careershFQmXK4dYZMeta?.path ?? "/hu/karrierek",
    meta: {...(careershFQmXK4dYZMeta || {}), ...{"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"hu"}},
    alias: careershFQmXK4dYZMeta?.alias || [],
    redirect: careershFQmXK4dYZMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/careers.vue").then(m => m.default || m)
  },
  {
    name: clarity_45enhanced_45diamondscV4P7me4guMeta?.name ?? "87_education-diamond-education-clarity-enhanced-diamonds___hu",
    path: clarity_45enhanced_45diamondscV4P7me4guMeta?.path ?? "/hu/klariumos-gyemantok",
    meta: {...(clarity_45enhanced_45diamondscV4P7me4guMeta || {}), ...{"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"hu"}},
    alias: clarity_45enhanced_45diamondscV4P7me4guMeta?.alias || [],
    redirect: clarity_45enhanced_45diamondscV4P7me4guMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue").then(m => m.default || m)
  },
  {
    name: compare_45diamond_45certificatesxEsX9RG1oAMeta?.name ?? "84_education-diamond-education-compare-diamond-certificates___hu",
    path: compare_45diamond_45certificatesxEsX9RG1oAMeta?.path ?? "/hu/gyemant-tanusitvanyok-osszehasonlitasa",
    meta: {...(compare_45diamond_45certificatesxEsX9RG1oAMeta || {}), ...{"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"hu"}},
    alias: compare_45diamond_45certificatesxEsX9RG1oAMeta?.alias || [],
    redirect: compare_45diamond_45certificatesxEsX9RG1oAMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: cost6ozuBJSlf2Meta?.name ?? "91_education-diamond-education-cost___hu",
    path: cost6ozuBJSlf2Meta?.path ?? "/hu/koltseg",
    meta: {...(cost6ozuBJSlf2Meta || {}), ...{"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"hu"}},
    alias: cost6ozuBJSlf2Meta?.alias || [],
    redirect: cost6ozuBJSlf2Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/cost.vue").then(m => m.default || m)
  },
  {
    name: diamond_45buying_45guideGZh1b8WPHiMeta?.name ?? "96_education-diamond-education-diamond-buying-guide___hu",
    path: diamond_45buying_45guideGZh1b8WPHiMeta?.path ?? "/hu/jegygyuruk/gyemantvasarlasi-utmutato",
    meta: {...(diamond_45buying_45guideGZh1b8WPHiMeta || {}), ...{"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"hu"}},
    alias: diamond_45buying_45guideGZh1b8WPHiMeta?.alias || [],
    redirect: diamond_45buying_45guideGZh1b8WPHiMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: diamond_45carat_45weighta3NL3YESYyMeta?.name ?? "83_education-diamond-education-diamond-carat-weight___hu",
    path: diamond_45carat_45weighta3NL3YESYyMeta?.path ?? "/hu/gyemant-karat-sulya",
    meta: {...(diamond_45carat_45weighta3NL3YESYyMeta || {}), ...{"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"hu"}},
    alias: diamond_45carat_45weighta3NL3YESYyMeta?.alias || [],
    redirect: diamond_45carat_45weighta3NL3YESYyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue").then(m => m.default || m)
  },
  {
    name: diamond_45certificatesFor2vqS7ZEMeta?.name ?? "85_education-diamond-education-diamond-certificates___hu",
    path: diamond_45certificatesFor2vqS7ZEMeta?.path ?? "/hu/gyemant-tanusitvanyok",
    meta: {...(diamond_45certificatesFor2vqS7ZEMeta || {}), ...{"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"hu"}},
    alias: diamond_45certificatesFor2vqS7ZEMeta?.alias || [],
    redirect: diamond_45certificatesFor2vqS7ZEMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.name ?? "102_education-diamond-education-diamond-clarity-expert-advice___hu",
    path: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.path ?? "/hu/gyemant-tisztasagi-szakertoi-tanacsadas",
    meta: {...(diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta || {}), ...{"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"hu"}},
    alias: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.alias || [],
    redirect: diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.name ?? "88_education-diamond-education-diamond-clarity-grading___hu",
    path: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.path ?? "/hu/gyemant-tisztasagi-osztalyzat",
    meta: {...(diamond_45clarity_45gradingVaHtQ0Cum7Meta || {}), ...{"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"hu"}},
    alias: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.alias || [],
    redirect: diamond_45clarity_45gradingVaHtQ0Cum7Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45clarity6TuJiBiLm8Meta?.name ?? "86_education-diamond-education-diamond-clarity___hu",
    path: diamond_45clarity6TuJiBiLm8Meta?.path ?? "/hu/gyemant-tisztasaga",
    meta: {...(diamond_45clarity6TuJiBiLm8Meta || {}), ...{"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"hu"}},
    alias: diamond_45clarity6TuJiBiLm8Meta?.alias || [],
    redirect: diamond_45clarity6TuJiBiLm8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue").then(m => m.default || m)
  },
  {
    name: diamond_45colourZxx2jRBd2MMeta?.name ?? "89_education-diamond-education-diamond-colour___hu",
    path: diamond_45colourZxx2jRBd2MMeta?.path ?? "/hu/gyemant-szine",
    meta: {...(diamond_45colourZxx2jRBd2MMeta || {}), ...{"pageId":89,"aspRoute":"/Education/color.aspx","locale":"hu"}},
    alias: diamond_45colourZxx2jRBd2MMeta?.alias || [],
    redirect: diamond_45colourZxx2jRBd2MMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45gradingAmAANIKe6JMeta?.name ?? "94_education-diamond-education-diamond-cut-grading___hu",
    path: diamond_45cut_45gradingAmAANIKe6JMeta?.path ?? "/hu/gyemantvagas-osztalyozasa",
    meta: {...(diamond_45cut_45gradingAmAANIKe6JMeta || {}), ...{"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"hu"}},
    alias: diamond_45cut_45gradingAmAANIKe6JMeta?.alias || [],
    redirect: diamond_45cut_45gradingAmAANIKe6JMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut_45process1c1Q83k8V5Meta?.name ?? "115_education-diamond-education-diamond-cut-process___hu",
    path: diamond_45cut_45process1c1Q83k8V5Meta?.path ?? "/hu/gyemantvagasi-eljaras",
    meta: {...(diamond_45cut_45process1c1Q83k8V5Meta || {}), ...{"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"hu"}},
    alias: diamond_45cut_45process1c1Q83k8V5Meta?.alias || [],
    redirect: diamond_45cut_45process1c1Q83k8V5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue").then(m => m.default || m)
  },
  {
    name: diamond_45cut7O4tfrzeGnMeta?.name ?? "93_education-diamond-education-diamond-cut___hu",
    path: diamond_45cut7O4tfrzeGnMeta?.path ?? "/hu/gyemantvagas",
    meta: {...(diamond_45cut7O4tfrzeGnMeta || {}), ...{"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"hu"}},
    alias: diamond_45cut7O4tfrzeGnMeta?.alias || [],
    redirect: diamond_45cut7O4tfrzeGnMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue").then(m => m.default || m)
  },
  {
    name: diamond_45fluorescence78IftR77O9Meta?.name ?? "104_education-diamond-education-diamond-fluorescence___hu",
    path: diamond_45fluorescence78IftR77O9Meta?.path ?? "/hu/gyemant-fluoreszcencia",
    meta: {...(diamond_45fluorescence78IftR77O9Meta || {}), ...{"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"hu"}},
    alias: diamond_45fluorescence78IftR77O9Meta?.alias || [],
    redirect: diamond_45fluorescence78IftR77O9Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue").then(m => m.default || m)
  },
  {
    name: diamond_45grading_45labsFUVIJ3Cms8Meta?.name ?? "109_education-diamond-education-diamond-grading-labs___hu",
    path: diamond_45grading_45labsFUVIJ3Cms8Meta?.path ?? "/hu/gyemant-osztalyozo-laboratoriumok",
    meta: {...(diamond_45grading_45labsFUVIJ3Cms8Meta || {}), ...{"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"hu"}},
    alias: diamond_45grading_45labsFUVIJ3Cms8Meta?.alias || [],
    redirect: diamond_45grading_45labsFUVIJ3Cms8Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue").then(m => m.default || m)
  },
  {
    name: diamond_45inclusionsFZ76RqsMSyMeta?.name ?? "98_education-diamond-education-diamond-inclusions___hu",
    path: diamond_45inclusionsFZ76RqsMSyMeta?.path ?? "/hu/gyemant-zarvanyok",
    meta: {...(diamond_45inclusionsFZ76RqsMSyMeta || {}), ...{"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"hu"}},
    alias: diamond_45inclusionsFZ76RqsMSyMeta?.alias || [],
    redirect: diamond_45inclusionsFZ76RqsMSyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue").then(m => m.default || m)
  },
  {
    name: diamond_45shapesh5NiDRFuzeMeta?.name ?? "90_education-diamond-education-diamond-shapes___hu",
    path: diamond_45shapesh5NiDRFuzeMeta?.path ?? "/hu/gyemant-alakzatok",
    meta: {...(diamond_45shapesh5NiDRFuzeMeta || {}), ...{"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"hu"}},
    alias: diamond_45shapesh5NiDRFuzeMeta?.alias || [],
    redirect: diamond_45shapesh5NiDRFuzeMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue").then(m => m.default || m)
  },
  {
    name: engagement_45buying_45guideu9ZaZfmYrTMeta?.name ?? "100_education-diamond-education-engagement-buying-guide___hu",
    path: engagement_45buying_45guideu9ZaZfmYrTMeta?.path ?? "/hu/jegygyuruk/jegygyuruvasarlasi-utmutato",
    meta: {...(engagement_45buying_45guideu9ZaZfmYrTMeta || {}), ...{"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"hu"}},
    alias: engagement_45buying_45guideu9ZaZfmYrTMeta?.alias || [],
    redirect: engagement_45buying_45guideu9ZaZfmYrTMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue").then(m => m.default || m)
  },
  {
    name: ethical_45diamonds92QNeSVTOgMeta?.name ?? "101_education-diamond-education-ethical-diamonds___hu",
    path: ethical_45diamonds92QNeSVTOgMeta?.path ?? "/hu/etikai-gyemantok",
    meta: {...(ethical_45diamonds92QNeSVTOgMeta || {}), ...{"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"hu"}},
    alias: ethical_45diamonds92QNeSVTOgMeta?.alias || [],
    redirect: ethical_45diamonds92QNeSVTOgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue").then(m => m.default || m)
  },
  {
    name: fancy_45coloured_45diamondsV32sAu20k5Meta?.name ?? "103_education-diamond-education-fancy-coloured-diamonds___hu",
    path: fancy_45coloured_45diamondsV32sAu20k5Meta?.path ?? "/hu/fancy-szines-gyemantok",
    meta: {...(fancy_45coloured_45diamondsV32sAu20k5Meta || {}), ...{"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"hu"}},
    alias: fancy_45coloured_45diamondsV32sAu20k5Meta?.alias || [],
    redirect: fancy_45coloured_45diamondsV32sAu20k5Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue").then(m => m.default || m)
  },
  {
    name: hearts_45arrowswfOY44xKKxMeta?.name ?? "106_education-diamond-education-hearts-arrows___hu",
    path: hearts_45arrowswfOY44xKKxMeta?.path ?? "/hu/szivek-nyilai",
    meta: {...(hearts_45arrowswfOY44xKKxMeta || {}), ...{"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"hu"}},
    alias: hearts_45arrowswfOY44xKKxMeta?.alias || [],
    redirect: hearts_45arrowswfOY44xKKxMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45cutpj69rrIjOvMeta?.name ?? "107_education-diamond-education-how-are-diamonds-cut___hu",
    path: how_45are_45diamonds_45cutpj69rrIjOvMeta?.path ?? "/hu/hogyan-csiszoljak-a-gyemantot?",
    meta: {...(how_45are_45diamonds_45cutpj69rrIjOvMeta || {}), ...{"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"hu"}},
    alias: how_45are_45diamonds_45cutpj69rrIjOvMeta?.alias || [],
    redirect: how_45are_45diamonds_45cutpj69rrIjOvMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue").then(m => m.default || m)
  },
  {
    name: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.name ?? "108_education-diamond-education-how-are-diamonds-formed___hu",
    path: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.path ?? "/hu/hogyan-keletkeznek-a-gyemantok?",
    meta: {...(how_45are_45diamonds_45formed5C6zMSF9GtMeta || {}), ...{"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"hu"}},
    alias: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.alias || [],
    redirect: how_45are_45diamonds_45formed5C6zMSF9GtMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue").then(m => m.default || m)
  },
  {
    name: indexEyNi2vxOyuMeta?.name ?? "97_education-diamond-education___hu",
    path: indexEyNi2vxOyuMeta?.path ?? "/hu/diamant-oktatas",
    meta: {...(indexEyNi2vxOyuMeta || {}), ...{"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"hu"}},
    alias: indexEyNi2vxOyuMeta?.alias || [],
    redirect: indexEyNi2vxOyuMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyVpRIh9HvHkMeta?.name ?? "69_education-diamond-education-our-story___hu",
    path: our_45storyVpRIh9HvHkMeta?.path ?? "/hu/tortenetunk",
    meta: {...(our_45storyVpRIh9HvHkMeta || {}), ...{"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"hu"}},
    alias: our_45storyVpRIh9HvHkMeta?.alias || [],
    redirect: our_45storyVpRIh9HvHkMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/our-story.vue").then(m => m.default || m)
  },
  {
    name: ring_45size_45chartEQaQIyTSabMeta?.name ?? "118_education-diamond-education-ring-size-chart___hu",
    path: ring_45size_45chartEQaQIyTSabMeta?.path ?? "/hu/jegygyuruk/gyurumerettablazat",
    meta: {...(ring_45size_45chartEQaQIyTSabMeta || {}), ...{"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"hu"}},
    alias: ring_45size_45chartEQaQIyTSabMeta?.alias || [],
    redirect: ring_45size_45chartEQaQIyTSabMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue").then(m => m.default || m)
  },
  {
    name: asscher_45cut_45diamondsq10W5OJc38Meta?.name ?? "80_education-diamond-education-shapes-asscher-cut-diamonds___hu",
    path: asscher_45cut_45diamondsq10W5OJc38Meta?.path ?? "/hu/asscher-csiszolasu-gyemantok",
    meta: {...(asscher_45cut_45diamondsq10W5OJc38Meta || {}), ...{"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"hu"}},
    alias: asscher_45cut_45diamondsq10W5OJc38Meta?.alias || [],
    redirect: asscher_45cut_45diamondsq10W5OJc38Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: cushion_45cut_45diamondsu9bB9UWG2aMeta?.name ?? "92_education-diamond-education-shapes-cushion-cut-diamonds___hu",
    path: cushion_45cut_45diamondsu9bB9UWG2aMeta?.path ?? "/hu/parnavagott-gyemantok",
    meta: {...(cushion_45cut_45diamondsu9bB9UWG2aMeta || {}), ...{"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"hu"}},
    alias: cushion_45cut_45diamondsu9bB9UWG2aMeta?.alias || [],
    redirect: cushion_45cut_45diamondsu9bB9UWG2aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: emerald_45cut_45diamondsUjVMFP8BVWMeta?.name ?? "99_education-diamond-education-shapes-emerald-cut-diamonds___hu",
    path: emerald_45cut_45diamondsUjVMFP8BVWMeta?.path ?? "/hu/emerald-csiszolasu-gyemantok",
    meta: {...(emerald_45cut_45diamondsUjVMFP8BVWMeta || {}), ...{"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"hu"}},
    alias: emerald_45cut_45diamondsUjVMFP8BVWMeta?.alias || [],
    redirect: emerald_45cut_45diamondsUjVMFP8BVWMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: heart_45cut_45diamondsO0iXVVR7roMeta?.name ?? "105_education-diamond-education-shapes-heart-cut-diamonds___hu",
    path: heart_45cut_45diamondsO0iXVVR7roMeta?.path ?? "/hu/szivbe-vagott-gyemantok",
    meta: {...(heart_45cut_45diamondsO0iXVVR7roMeta || {}), ...{"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"hu"}},
    alias: heart_45cut_45diamondsO0iXVVR7roMeta?.alias || [],
    redirect: heart_45cut_45diamondsO0iXVVR7roMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: marquise_45cut_45diamondswGUzXwhTvgMeta?.name ?? "111_education-diamond-education-shapes-marquise-cut-diamonds___hu",
    path: marquise_45cut_45diamondswGUzXwhTvgMeta?.path ?? "/hu/marquise-csiszolasu-gyemantok",
    meta: {...(marquise_45cut_45diamondswGUzXwhTvgMeta || {}), ...{"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"hu"}},
    alias: marquise_45cut_45diamondswGUzXwhTvgMeta?.alias || [],
    redirect: marquise_45cut_45diamondswGUzXwhTvgMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: oval_45cut_45diamondsWKywHggYmjMeta?.name ?? "113_education-diamond-education-shapes-oval-cut-diamonds___hu",
    path: oval_45cut_45diamondsWKywHggYmjMeta?.path ?? "/hu/ovalis-csiszolasu-gyemantok",
    meta: {...(oval_45cut_45diamondsWKywHggYmjMeta || {}), ...{"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"hu"}},
    alias: oval_45cut_45diamondsWKywHggYmjMeta?.alias || [],
    redirect: oval_45cut_45diamondsWKywHggYmjMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: pear_45shape_45diamondsjNfS1pVLPrMeta?.name ?? "114_education-diamond-education-shapes-pear-shape-diamonds___hu",
    path: pear_45shape_45diamondsjNfS1pVLPrMeta?.path ?? "/hu/gyemant-alaku-gyemantok",
    meta: {...(pear_45shape_45diamondsjNfS1pVLPrMeta || {}), ...{"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"hu"}},
    alias: pear_45shape_45diamondsjNfS1pVLPrMeta?.alias || [],
    redirect: pear_45shape_45diamondsjNfS1pVLPrMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue").then(m => m.default || m)
  },
  {
    name: princess_45cut_45diamonds7eeGYHoXWKMeta?.name ?? "116_education-diamond-education-shapes-princess-cut-diamonds___hu",
    path: princess_45cut_45diamonds7eeGYHoXWKMeta?.path ?? "/hu/princess-csiszolt-gyemantok",
    meta: {...(princess_45cut_45diamonds7eeGYHoXWKMeta || {}), ...{"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"hu"}},
    alias: princess_45cut_45diamonds7eeGYHoXWKMeta?.alias || [],
    redirect: princess_45cut_45diamonds7eeGYHoXWKMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: radiant_45cut_45diamondsASR0NsemcqMeta?.name ?? "117_education-diamond-education-shapes-radiant-cut-diamonds___hu",
    path: radiant_45cut_45diamondsASR0NsemcqMeta?.path ?? "/hu/radians-csiszolasu-gyemantok",
    meta: {...(radiant_45cut_45diamondsASR0NsemcqMeta || {}), ...{"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"hu"}},
    alias: radiant_45cut_45diamondsASR0NsemcqMeta?.alias || [],
    redirect: radiant_45cut_45diamondsASR0NsemcqMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: round_45diamondsHMSruvXralMeta?.name ?? "120_education-diamond-education-shapes-round-diamonds___hu",
    path: round_45diamondsHMSruvXralMeta?.path ?? "/hu/kerek-gyemantok",
    meta: {...(round_45diamondsHMSruvXralMeta || {}), ...{"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"hu"}},
    alias: round_45diamondsHMSruvXralMeta?.alias || [],
    redirect: round_45diamondsHMSruvXralMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue").then(m => m.default || m)
  },
  {
    name: trillion_45cut_45diamondsoVzGPJdptLMeta?.name ?? "123_education-diamond-education-shapes-trillion-cut-diamonds___hu",
    path: trillion_45cut_45diamondsoVzGPJdptLMeta?.path ?? "/hu/trillio-csiszolasu-gyemantok",
    meta: {...(trillion_45cut_45diamondsoVzGPJdptLMeta || {}), ...{"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"hu"}},
    alias: trillion_45cut_45diamondsoVzGPJdptLMeta?.alias || [],
    redirect: trillion_45cut_45diamondsoVzGPJdptLMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue").then(m => m.default || m)
  },
  {
    name: testimonialsOP6UGSIAzNMeta?.name ?? "77_education-diamond-education-testimonials___hu",
    path: testimonialsOP6UGSIAzNMeta?.path ?? "/hu/beszamolok",
    meta: {...(testimonialsOP6UGSIAzNMeta || {}), ...{"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"hu"}},
    alias: testimonialsOP6UGSIAzNMeta?.alias || [],
    redirect: testimonialsOP6UGSIAzNMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/testimonials.vue").then(m => m.default || m)
  },
  {
    name: indexwXj5m7cZh6Meta?.name ?? "95_education-diamond-education-type-of-diamond-cuts___hu",
    path: indexwXj5m7cZh6Meta?.path ?? "/hu/gyemantvagasok-tipusa",
    meta: {...(indexwXj5m7cZh6Meta || {}), ...{"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"hu"}},
    alias: indexwXj5m7cZh6Meta?.alias || [],
    redirect: indexwXj5m7cZh6Meta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue").then(m => m.default || m)
  },
  {
    name: introductionioT4hAg28aMeta?.name ?? "110_education-diamond-education-type-of-diamond-cuts-introduction___hu",
    path: introductionioT4hAg28aMeta?.path ?? "/hu/bevezetes",
    meta: {...(introductionioT4hAg28aMeta || {}), ...{"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"hu"}},
    alias: introductionioT4hAg28aMeta?.alias || [],
    redirect: introductionioT4hAg28aMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue").then(m => m.default || m)
  },
  {
    name: mixed_45cutKWTO0JrL7DMeta?.name ?? "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___hu",
    path: mixed_45cutKWTO0JrL7DMeta?.path ?? "/hu/kevert-vagas",
    meta: {...(mixed_45cutKWTO0JrL7DMeta || {}), ...{"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"hu"}},
    alias: mixed_45cutKWTO0JrL7DMeta?.alias || [],
    redirect: mixed_45cutKWTO0JrL7DMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue").then(m => m.default || m)
  },
  {
    name: modified_45brilliant_45cut4W7QXTA9YyMeta?.name ?? "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___hu",
    path: modified_45brilliant_45cut4W7QXTA9YyMeta?.path ?? "/hu/modositott-brilians-vagas",
    meta: {...(modified_45brilliant_45cut4W7QXTA9YyMeta || {}), ...{"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"hu"}},
    alias: modified_45brilliant_45cut4W7QXTA9YyMeta?.alias || [],
    redirect: modified_45brilliant_45cut4W7QXTA9YyMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.name ?? "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___hu",
    path: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.path ?? "/hu/old-mine-old-european-cut",
    meta: {...(old_45mine_45old_45european_45cutMoBfHQBlGhMeta || {}), ...{"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"hu"}},
    alias: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.alias || [],
    redirect: old_45mine_45old_45european_45cutMoBfHQBlGhMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue").then(m => m.default || m)
  },
  {
    name: round_45brilliant_45cutyrMYHzCbVVMeta?.name ?? "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___hu",
    path: round_45brilliant_45cutyrMYHzCbVVMeta?.path ?? "/hu/kerek-brilians-vagas",
    meta: {...(round_45brilliant_45cutyrMYHzCbVVMeta || {}), ...{"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"hu"}},
    alias: round_45brilliant_45cutyrMYHzCbVVMeta?.alias || [],
    redirect: round_45brilliant_45cutyrMYHzCbVVMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue").then(m => m.default || m)
  },
  {
    name: step_45cutqoz9sE34rXMeta?.name ?? "122_education-diamond-education-type-of-diamond-cuts-step-cut___hu",
    path: step_45cutqoz9sE34rXMeta?.path ?? "/hu/lepes-vagas",
    meta: {...(step_45cutqoz9sE34rXMeta || {}), ...{"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"hu"}},
    alias: step_45cutqoz9sE34rXMeta?.alias || [],
    redirect: step_45cutqoz9sE34rXMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue").then(m => m.default || m)
  }
],
    name: diamond_45educationMW6QW1Y00NMeta?.name ?? undefined,
    meta: diamond_45educationMW6QW1Y00NMeta || {},
    alias: diamond_45educationMW6QW1Y00NMeta?.alias || [],
    redirect: diamond_45educationMW6QW1Y00NMeta?.redirect || undefined,
    component: () => import("/src/pages/education/diamond-education.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___ar",
    path: failedbrcbjYHVDlMeta?.path ?? "/ar/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"ar"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___bg",
    path: failedbrcbjYHVDlMeta?.path ?? "/bg/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"bg"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___cs",
    path: failedbrcbjYHVDlMeta?.path ?? "/cs/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"cs"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___dk",
    path: failedbrcbjYHVDlMeta?.path ?? "/dk/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"dk"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___nl",
    path: failedbrcbjYHVDlMeta?.path ?? "/nl/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"nl"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___en",
    path: failedbrcbjYHVDlMeta?.path ?? "/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"en"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___fi",
    path: failedbrcbjYHVDlMeta?.path ?? "/fi/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"fi"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___fr",
    path: failedbrcbjYHVDlMeta?.path ?? "/fr/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"fr"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___de",
    path: failedbrcbjYHVDlMeta?.path ?? "/de/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"de"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___it",
    path: failedbrcbjYHVDlMeta?.path ?? "/it/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"it"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___cn",
    path: failedbrcbjYHVDlMeta?.path ?? "/cn/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"cn"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___no",
    path: failedbrcbjYHVDlMeta?.path ?? "/no/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"no"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___pl",
    path: failedbrcbjYHVDlMeta?.path ?? "/pl/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"pl"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___pt",
    path: failedbrcbjYHVDlMeta?.path ?? "/pt/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"pt"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___ro",
    path: failedbrcbjYHVDlMeta?.path ?? "/ro/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"ro"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___ru",
    path: failedbrcbjYHVDlMeta?.path ?? "/ru/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"ru"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___es",
    path: failedbrcbjYHVDlMeta?.path ?? "/es/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"es"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___se",
    path: failedbrcbjYHVDlMeta?.path ?? "/se/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"se"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: failedbrcbjYHVDlMeta?.name ?? "19_failed___hu",
    path: failedbrcbjYHVDlMeta?.path ?? "/hu/failed.aspx",
    meta: {...(failedbrcbjYHVDlMeta || {}), ...{"pageId":19,"aspRoute":"/failed.aspx","locale":"hu"}},
    alias: failedbrcbjYHVDlMeta?.alias || [],
    redirect: failedbrcbjYHVDlMeta?.redirect || undefined,
    component: () => import("/src/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___ar",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/ar/finance-information",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ar"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___bg",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/bg/finansova-informaciya",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"bg"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___cs",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/cs/financni-informace",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"cs"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___dk",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/dk/afbetaling.html",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"dk"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___nl",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/nl/financiering-informatie",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"nl"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___en",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/finance-information",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"en"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___fi",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/fi/rahoitustiedot",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"fi"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___fr",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/fr/financement-information",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"fr"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___de",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/de/finanzinformationen",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"de"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___it",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/it/informazioni-finanziamento",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"it"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___cn",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/cn/dai-kuan-xin-xi",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"cn"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___no",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/no/finansinformasjon",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"no"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___pl",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/pl/finansowanie.html",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"pl"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___pt",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/pt/finance-information",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"pt"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___ro",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/ro/informatii-financiare",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ro"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___ru",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/ru/finansovaya-informaciya",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ru"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___es",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/es/informacion-financiera",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"es"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___se",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/se/finansieringsinformation",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"se"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: finance_45informationRY9DSBrTx3Meta?.name ?? "61_finance-information___hu",
    path: finance_45informationRY9DSBrTx3Meta?.path ?? "/hu/-penzugyi-informaciok",
    meta: {...(finance_45informationRY9DSBrTx3Meta || {}), ...{"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"hu"}},
    alias: finance_45informationRY9DSBrTx3Meta?.alias || [],
    redirect: finance_45informationRY9DSBrTx3Meta?.redirect || undefined,
    component: () => import("/src/pages/finance-information.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___ar",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/ar/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ar"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___bg",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/bg/zabravena-parola",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"bg"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___cs",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/cs/zapomnel-jsem-heslo",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"cs"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___dk",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/dk/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"dk"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___nl",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/nl/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"nl"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___en",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"en"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___fi",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/fi/unohdin-salasanan",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"fi"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___fr",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/fr/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"fr"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___de",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/de/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"de"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___it",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/it/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"it"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___cn",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/cn/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"cn"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___no",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/no/glemte-passord",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"no"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___pl",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/pl/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"pl"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___pt",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/pt/esqueci-me-da-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"pt"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___ro",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/ro/a-uitat-parola",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ro"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___ru",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/ru/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ru"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___es",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/es/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"es"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___se",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/se/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"se"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordhkQDFGWAYaMeta?.name ?? "761_forgot-password___hu",
    path: forgot_45passwordhkQDFGWAYaMeta?.path ?? "/hu/forgot-password",
    meta: {...(forgot_45passwordhkQDFGWAYaMeta || {}), ...{"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"hu"}},
    alias: forgot_45passwordhkQDFGWAYaMeta?.alias || [],
    redirect: forgot_45passwordhkQDFGWAYaMeta?.redirect || undefined,
    component: () => import("/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___ar",
    path: guideoeMlliXxVDMeta?.path ?? "/ar/alahgar-alkarima/alzomrod/dalil",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ar"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___bg",
    path: guideoeMlliXxVDMeta?.path ?? "/bg/sk-pocenni-kam-ni/emerald/p-tevoditel",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"bg"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___cs",
    path: guideoeMlliXxVDMeta?.path ?? "/cs/kameny/emerald/pruvodce",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"cs"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___dk",
    path: guideoeMlliXxVDMeta?.path ?? "/dk/delsten/smaragder/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"dk"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___nl",
    path: guideoeMlliXxVDMeta?.path ?? "/nl/edelstenen/smaragd/handleiding",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"nl"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___en",
    path: guideoeMlliXxVDMeta?.path ?? "/gemstones/emerald/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"en"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___fi",
    path: guideoeMlliXxVDMeta?.path ?? "/fi/jalokivet/emerald/opas",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"fi"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___fr",
    path: guideoeMlliXxVDMeta?.path ?? "/fr/pierresprecieuses/emeraude/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"fr"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___de",
    path: guideoeMlliXxVDMeta?.path ?? "/de/edelsteine/smaragd/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"de"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___it",
    path: guideoeMlliXxVDMeta?.path ?? "/it/pietre-preziose/smeraldo/guida",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"it"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___cn",
    path: guideoeMlliXxVDMeta?.path ?? "/cn/bao-shi/zu-mu-lv/zhi-nan",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"cn"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___no",
    path: guideoeMlliXxVDMeta?.path ?? "/no/-edelstener/emerald/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"no"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___pl",
    path: guideoeMlliXxVDMeta?.path ?? "/pl/kamienie-szlachetne/szmaragd/przewodnik",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"pl"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___pt",
    path: guideoeMlliXxVDMeta?.path ?? "/pt/pedras-preciosas/esmeralda/guia",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"pt"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___ro",
    path: guideoeMlliXxVDMeta?.path ?? "/ro/piese-pretioase/emerald/ghid",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ro"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___ru",
    path: guideoeMlliXxVDMeta?.path ?? "/ru/dragocennye-kamni/izumrudy/rukovodstvo",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ru"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___es",
    path: guideoeMlliXxVDMeta?.path ?? "/es/piedras-preciosas/esmeralda/gua",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"es"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___se",
    path: guideoeMlliXxVDMeta?.path ?? "/se/adelstenar/smaragder/guide",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"se"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guideoeMlliXxVDMeta?.name ?? "620_gemstones-emerald-guide___hu",
    path: guideoeMlliXxVDMeta?.path ?? "/hu/gyemantkovek/emerald/utmutato",
    meta: {...(guideoeMlliXxVDMeta || {}), ...{"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"hu"}},
    alias: guideoeMlliXxVDMeta?.alias || [],
    redirect: guideoeMlliXxVDMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/emerald/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___ar",
    path: guide80BdYUPhY2Meta?.path ?? "/ar/alahgar-alkarima/dalil",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ar"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___bg",
    path: guide80BdYUPhY2Meta?.path ?? "/bg/sk-pocenni-kam-ni/p-tevoditel",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"bg"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___cs",
    path: guide80BdYUPhY2Meta?.path ?? "/cs/pruvodce-drahymi-kameny",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"cs"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___dk",
    path: guide80BdYUPhY2Meta?.path ?? "/dk/delsten/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"dk"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___nl",
    path: guide80BdYUPhY2Meta?.path ?? "/nl/edelstenen/handleiding",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"nl"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___en",
    path: guide80BdYUPhY2Meta?.path ?? "/gemstones/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"en"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___fi",
    path: guide80BdYUPhY2Meta?.path ?? "/fi/jalokivet/opas",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"fi"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___fr",
    path: guide80BdYUPhY2Meta?.path ?? "/fr/pierresprecieuses/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"fr"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___de",
    path: guide80BdYUPhY2Meta?.path ?? "/de/edelsteine/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"de"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___it",
    path: guide80BdYUPhY2Meta?.path ?? "/it/pietre-preziose/guida",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"it"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___cn",
    path: guide80BdYUPhY2Meta?.path ?? "/cn/bao-shi/zhi-nan",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"cn"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___no",
    path: guide80BdYUPhY2Meta?.path ?? "/no/-edelstener/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"no"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___pl",
    path: guide80BdYUPhY2Meta?.path ?? "/pl/kamienie-szlachetne/przewodnik",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"pl"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___pt",
    path: guide80BdYUPhY2Meta?.path ?? "/pt/pedras-preciosas/guia",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"pt"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___ro",
    path: guide80BdYUPhY2Meta?.path ?? "/ro/piese-pretioase/ghid",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ro"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___ru",
    path: guide80BdYUPhY2Meta?.path ?? "/ru/dragocennye-kamni/rukovodstvo",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ru"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___es",
    path: guide80BdYUPhY2Meta?.path ?? "/es/piedras-preciosas/gua",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"es"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___se",
    path: guide80BdYUPhY2Meta?.path ?? "/se/adelstenar/guide",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"se"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guide80BdYUPhY2Meta?.name ?? "617_gemstones-guide___hu",
    path: guide80BdYUPhY2Meta?.path ?? "/hu/gyemantkovek/utmutato",
    meta: {...(guide80BdYUPhY2Meta || {}), ...{"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"hu"}},
    alias: guide80BdYUPhY2Meta?.alias || [],
    redirect: guide80BdYUPhY2Meta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___ar",
    path: guideESek2eBSWXMeta?.path ?? "/ar/alahgar-alkarima/alyaqout/dalil",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ar"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___bg",
    path: guideESek2eBSWXMeta?.path ?? "/bg/sk-pocenni-kam-ni/rubi/r-kovodstvo",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"bg"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___cs",
    path: guideESek2eBSWXMeta?.path ?? "/cs/kameny/ruby/pruvodce",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"cs"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___dk",
    path: guideESek2eBSWXMeta?.path ?? "/dk/delsten/rubin/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"dk"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___nl",
    path: guideESek2eBSWXMeta?.path ?? "/nl/edelstenen/robijn/handleiding",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"nl"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___en",
    path: guideESek2eBSWXMeta?.path ?? "/gemstones/ruby/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"en"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___fi",
    path: guideESek2eBSWXMeta?.path ?? "/fi/jalokivet/ruby/opas",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"fi"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___fr",
    path: guideESek2eBSWXMeta?.path ?? "/fr/pierresprecieuses/rubis/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"fr"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___de",
    path: guideESek2eBSWXMeta?.path ?? "/de/edelsteine/rubin/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"de"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___it",
    path: guideESek2eBSWXMeta?.path ?? "/it/pietre-preziose/rubino/guida",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"it"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___cn",
    path: guideESek2eBSWXMeta?.path ?? "/cn/bao-shi/hong-bao-shi/zhi-nan",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"cn"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___no",
    path: guideESek2eBSWXMeta?.path ?? "/no/-edelstener/ruby/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"no"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___pl",
    path: guideESek2eBSWXMeta?.path ?? "/pl/kamienie-szlachetne/rubin/przewodnik",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"pl"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___pt",
    path: guideESek2eBSWXMeta?.path ?? "/pt/pedras-preciosas/rubi/guia",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"pt"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___ro",
    path: guideESek2eBSWXMeta?.path ?? "/ro/pietre-pretioase/ruby/ghid",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ro"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___ru",
    path: guideESek2eBSWXMeta?.path ?? "/ru/dragocennye-kamni/rubiny/rukovodstvo",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ru"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___es",
    path: guideESek2eBSWXMeta?.path ?? "/es/piedras-preciosas/rub/gua",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"es"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___se",
    path: guideESek2eBSWXMeta?.path ?? "/se/adelstenar/rubiner/guide",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"se"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideESek2eBSWXMeta?.name ?? "618_gemstones-ruby-guide___hu",
    path: guideESek2eBSWXMeta?.path ?? "/hu/gyemantkovek/rubin/utmutato",
    meta: {...(guideESek2eBSWXMeta || {}), ...{"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"hu"}},
    alias: guideESek2eBSWXMeta?.alias || [],
    redirect: guideESek2eBSWXMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/ruby/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___ar",
    path: guideas5mkEiMedMeta?.path ?? "/ar/alahgar-alkarima/alzfir/dalil",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ar"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___bg",
    path: guideas5mkEiMedMeta?.path ?? "/bg/sk-pocenni-kam-ni/sapfir/r-kovodstvo",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"bg"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___cs",
    path: guideas5mkEiMedMeta?.path ?? "/cs/drahokamy/safir/pruvodce",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"cs"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___dk",
    path: guideas5mkEiMedMeta?.path ?? "/dk/delsten/safirer/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"dk"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___nl",
    path: guideas5mkEiMedMeta?.path ?? "/nl/edelstenen/saffier/handleiding",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"nl"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___en",
    path: guideas5mkEiMedMeta?.path ?? "/gemstones/sapphire/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"en"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___fi",
    path: guideas5mkEiMedMeta?.path ?? "/fi/jalokivet/safiiri/opas",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"fi"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___fr",
    path: guideas5mkEiMedMeta?.path ?? "/fr/pierresprecieuses/saphire/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"fr"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___de",
    path: guideas5mkEiMedMeta?.path ?? "/de/edelsteine/saphir/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"de"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___it",
    path: guideas5mkEiMedMeta?.path ?? "/it/pietre-preziose/zaffiro/guida",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"it"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___cn",
    path: guideas5mkEiMedMeta?.path ?? "/cn/bao-shi/lan-bao-shi/zhi-nan",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"cn"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___no",
    path: guideas5mkEiMedMeta?.path ?? "/no/-edelstener/-safir/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"no"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___pl",
    path: guideas5mkEiMedMeta?.path ?? "/pl/kamienie-szlachetne/szafir/przewodnik",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"pl"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___pt",
    path: guideas5mkEiMedMeta?.path ?? "/pt/pedras-preciosas/sapphire/guia",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"pt"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___ro",
    path: guideas5mkEiMedMeta?.path ?? "/ro/pietre-pretioase/sapfir/ghid",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ro"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___ru",
    path: guideas5mkEiMedMeta?.path ?? "/ru/dragocennye-kamni/sapfiry/rukovodstvo",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ru"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___es",
    path: guideas5mkEiMedMeta?.path ?? "/es/piedras-preciosas/zafiro/gua",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"es"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___se",
    path: guideas5mkEiMedMeta?.path ?? "/se/adelstenar/safirer/guide",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"se"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: guideas5mkEiMedMeta?.name ?? "619_gemstones-sapphire-guide___hu",
    path: guideas5mkEiMedMeta?.path ?? "/hu/gyemantkovek/szafir/utmutato",
    meta: {...(guideas5mkEiMedMeta || {}), ...{"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"hu"}},
    alias: guideas5mkEiMedMeta?.alias || [],
    redirect: guideas5mkEiMedMeta?.redirect || undefined,
    component: () => import("/src/pages/gemstones/sapphire/guide.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___ar",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/ar/haa-qad-att-almofaagaa",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ar"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___bg",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/bg/tuk-idva-iznenadata",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"bg"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___cs",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/cs/-tady-prichazi-prekvapeni",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"cs"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___dk",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/dk/here-comes-the-suprise",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"dk"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___nl",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/nl/hier-komt-de-verrassing",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"nl"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___en",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/here-comes-the-suprise",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"en"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___fi",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/fi/tassa-tulee-yllatys",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"fi"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___fr",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/fr/voici-la-surprise",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"fr"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___de",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/de/here-comes-the-suprise",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"de"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___it",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/it/arriva-la-sorpresa",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"it"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___cn",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/cn/zhe-li-you-jing-xi",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"cn"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___no",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/no/na-kommer-overraskelsen",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"no"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___pl",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/pl/przygotuj-sie-na-niespodzianke",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"pl"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___pt",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/pt/here-comes-the-suprise",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"pt"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___ro",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/ro/aici-vine-surpriza",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ro"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___ru",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/ru/a-vot-i-sjurpriz",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ru"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___es",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/es/aqui-viene-la-sorpresa",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"es"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___se",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/se/har-kommer-overraskningen",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"se"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: here_45comes_45the_45supriseGsU0MlTvfeMeta?.name ?? "43_here-comes-the-suprise___hu",
    path: here_45comes_45the_45supriseGsU0MlTvfeMeta?.path ?? "/hu/es-most-jon-a-meglepetes",
    meta: {...(here_45comes_45the_45supriseGsU0MlTvfeMeta || {}), ...{"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"hu"}},
    alias: here_45comes_45the_45supriseGsU0MlTvfeMeta?.alias || [],
    redirect: here_45comes_45the_45supriseGsU0MlTvfeMeta?.redirect || undefined,
    component: () => import("/src/pages/here-comes-the-suprise.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___ar",
    path: impressum0XojDA8GKUMeta?.path ?? "/ar/idaart-attahreer.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"ar"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___bg",
    path: impressum0XojDA8GKUMeta?.path ?? "/bg/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"bg"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___cs",
    path: impressum0XojDA8GKUMeta?.path ?? "/cs/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"cs"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___dk",
    path: impressum0XojDA8GKUMeta?.path ?? "/dk/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"dk"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___nl",
    path: impressum0XojDA8GKUMeta?.path ?? "/nl/nieuws-contact.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"nl"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___en",
    path: impressum0XojDA8GKUMeta?.path ?? "/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"en"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___fi",
    path: impressum0XojDA8GKUMeta?.path ?? "/fi/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"fi"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___fr",
    path: impressum0XojDA8GKUMeta?.path ?? "/fr/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"fr"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___de",
    path: impressum0XojDA8GKUMeta?.path ?? "/de/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"de"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___it",
    path: impressum0XojDA8GKUMeta?.path ?? "/it/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"it"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___cn",
    path: impressum0XojDA8GKUMeta?.path ?? "/cn/ban-ben-shuo-ming.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"cn"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___no",
    path: impressum0XojDA8GKUMeta?.path ?? "/no/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"no"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___pl",
    path: impressum0XojDA8GKUMeta?.path ?? "/pl/stopka-redakcyjna.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"pl"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___pt",
    path: impressum0XojDA8GKUMeta?.path ?? "/pt/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"pt"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___ro",
    path: impressum0XojDA8GKUMeta?.path ?? "/ro/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"ro"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___ru",
    path: impressum0XojDA8GKUMeta?.path ?? "/ru/dannye.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"ru"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___es",
    path: impressum0XojDA8GKUMeta?.path ?? "/es/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"es"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___se",
    path: impressum0XojDA8GKUMeta?.path ?? "/se/impressum.html",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"se"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum0XojDA8GKUMeta?.name ?? "237_impressum___hu",
    path: impressum0XojDA8GKUMeta?.path ?? "/hu/impressum",
    meta: {...(impressum0XojDA8GKUMeta || {}), ...{"pageId":237,"aspRoute":"/impressum.aspx","locale":"hu"}},
    alias: impressum0XojDA8GKUMeta?.alias || [],
    redirect: impressum0XojDA8GKUMeta?.redirect || undefined,
    component: () => import("/src/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___ar",
    path: indexvsa0KwJWqiMeta?.path ?? "/ar",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"ar"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___bg",
    path: indexvsa0KwJWqiMeta?.path ?? "/bg",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"bg"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___cs",
    path: indexvsa0KwJWqiMeta?.path ?? "/cs",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"cs"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___dk",
    path: indexvsa0KwJWqiMeta?.path ?? "/dk",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"dk"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___nl",
    path: indexvsa0KwJWqiMeta?.path ?? "/nl",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"nl"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___en",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"en"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___fi",
    path: indexvsa0KwJWqiMeta?.path ?? "/fi",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"fi"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___fr",
    path: indexvsa0KwJWqiMeta?.path ?? "/fr",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"fr"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___de",
    path: indexvsa0KwJWqiMeta?.path ?? "/de",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"de"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___it",
    path: indexvsa0KwJWqiMeta?.path ?? "/it",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"it"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___cn",
    path: indexvsa0KwJWqiMeta?.path ?? "/cn",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"cn"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___no",
    path: indexvsa0KwJWqiMeta?.path ?? "/no",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"no"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___pl",
    path: indexvsa0KwJWqiMeta?.path ?? "/pl",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"pl"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___pt",
    path: indexvsa0KwJWqiMeta?.path ?? "/pt",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"pt"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___ro",
    path: indexvsa0KwJWqiMeta?.path ?? "/ro",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"ro"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___ru",
    path: indexvsa0KwJWqiMeta?.path ?? "/ru",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"ru"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___es",
    path: indexvsa0KwJWqiMeta?.path ?? "/es",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"es"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___se",
    path: indexvsa0KwJWqiMeta?.path ?? "/se",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"se"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "22_index___hu",
    path: indexvsa0KwJWqiMeta?.path ?? "/hu",
    meta: {...(indexvsa0KwJWqiMeta || {}), ...{"pageId":22,"aspRoute":"/Default.aspx","locale":"hu"}},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___ar",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/ar/almas-almostnbt-fi-almokhtbr",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ar"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___bg",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/bg/otgledani-v-laboratoriya-diamanti",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"bg"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___cs",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/cs/laboratorne-pestovane-diamanty",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"cs"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___dk",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/dk/laboratoriefremstillede-diamanter",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"dk"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___nl",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/nl/laboratorium-gekweekte-diamanten/koop",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"nl"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___en",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/lab-grown-diamonds",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"en"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___fi",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/fi/laboratoriossa-kasvatetut-timantit",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"fi"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___fr",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/fr/diamant-de-labo",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"fr"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___de",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/de/im-labor-gezchtete-diamanten",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"de"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___it",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/it/diamanti-creati-laboratorio",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"it"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___cn",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/cn/shi-yan-shi-pei-yu-zuan-shi",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"cn"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___no",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/no/-laboratoriedyrkede-diamanter",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"no"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___pl",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/pl/diamenty-syntetyczne",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"pl"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___pt",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/pt/lab-grown-diamantes",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"pt"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___ro",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/ro/diamante-cultivate-in-laborator",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ro"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___ru",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/ru/iskusstvennye-brillianty",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ru"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___es",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/es/diamantes-de-laboratorio",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"es"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___se",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/se/labbodlade-diamanter",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"se"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: lab_45grown_45diamondsSlRXO0kcG2Meta?.name ?? "621_lab-grown-diamonds___hu",
    path: lab_45grown_45diamondsSlRXO0kcG2Meta?.path ?? "/hu/laborban-termesztett-gyemantok",
    meta: {...(lab_45grown_45diamondsSlRXO0kcG2Meta || {}), ...{"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"hu"}},
    alias: lab_45grown_45diamondsSlRXO0kcG2Meta?.alias || [],
    redirect: lab_45grown_45diamondsSlRXO0kcG2Meta?.redirect || undefined,
    component: () => import("/src/pages/lab-grown-diamonds.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___ar",
    path: loginTLBFe6DICLMeta?.path ?? "/ar/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ar"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___bg",
    path: loginTLBFe6DICLMeta?.path ?? "/bg/-vkhod",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"bg"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___cs",
    path: loginTLBFe6DICLMeta?.path ?? "/cs/prihlaseni",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"cs"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___dk",
    path: loginTLBFe6DICLMeta?.path ?? "/dk/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"dk"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___nl",
    path: loginTLBFe6DICLMeta?.path ?? "/nl/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"nl"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___en",
    path: loginTLBFe6DICLMeta?.path ?? "/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"en"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___fi",
    path: loginTLBFe6DICLMeta?.path ?? "/fi/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"fi"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___fr",
    path: loginTLBFe6DICLMeta?.path ?? "/fr/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"fr"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___de",
    path: loginTLBFe6DICLMeta?.path ?? "/de/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"de"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___it",
    path: loginTLBFe6DICLMeta?.path ?? "/it/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"it"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___cn",
    path: loginTLBFe6DICLMeta?.path ?? "/cn/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"cn"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___no",
    path: loginTLBFe6DICLMeta?.path ?? "/no/logg-inn",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"no"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___pl",
    path: loginTLBFe6DICLMeta?.path ?? "/pl/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"pl"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___pt",
    path: loginTLBFe6DICLMeta?.path ?? "/pt/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"pt"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___ro",
    path: loginTLBFe6DICLMeta?.path ?? "/ro/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ro"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___ru",
    path: loginTLBFe6DICLMeta?.path ?? "/ru/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ru"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___es",
    path: loginTLBFe6DICLMeta?.path ?? "/es/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"es"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___se",
    path: loginTLBFe6DICLMeta?.path ?? "/se/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"se"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginTLBFe6DICLMeta?.name ?? "752_login___hu",
    path: loginTLBFe6DICLMeta?.path ?? "/hu/login",
    meta: {...(loginTLBFe6DICLMeta || {}), ...{"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"hu"}},
    alias: loginTLBFe6DICLMeta?.alias || [],
    redirect: loginTLBFe6DICLMeta?.redirect || undefined,
    component: () => import("/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___ar",
    path: logoutJAIe3EGR5DMeta?.path ?? "/ar/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___bg",
    path: logoutJAIe3EGR5DMeta?.path ?? "/bg/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___cs",
    path: logoutJAIe3EGR5DMeta?.path ?? "/cs/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___dk",
    path: logoutJAIe3EGR5DMeta?.path ?? "/dk/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___nl",
    path: logoutJAIe3EGR5DMeta?.path ?? "/nl/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___en",
    path: logoutJAIe3EGR5DMeta?.path ?? "/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___fi",
    path: logoutJAIe3EGR5DMeta?.path ?? "/fi/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___fr",
    path: logoutJAIe3EGR5DMeta?.path ?? "/fr/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___de",
    path: logoutJAIe3EGR5DMeta?.path ?? "/de/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___it",
    path: logoutJAIe3EGR5DMeta?.path ?? "/it/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___cn",
    path: logoutJAIe3EGR5DMeta?.path ?? "/cn/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___no",
    path: logoutJAIe3EGR5DMeta?.path ?? "/no/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___pl",
    path: logoutJAIe3EGR5DMeta?.path ?? "/pl/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___pt",
    path: logoutJAIe3EGR5DMeta?.path ?? "/pt/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___ro",
    path: logoutJAIe3EGR5DMeta?.path ?? "/ro/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___ru",
    path: logoutJAIe3EGR5DMeta?.path ?? "/ru/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___es",
    path: logoutJAIe3EGR5DMeta?.path ?? "/es/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___se",
    path: logoutJAIe3EGR5DMeta?.path ?? "/se/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout___hu",
    path: logoutJAIe3EGR5DMeta?.path ?? "/hu/logout",
    meta: logoutJAIe3EGR5DMeta || {},
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect || undefined,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___ar",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/ar/alahgar-alkarima",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ar"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___bg",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/bg/sk-pocenni-kam-ni",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"bg"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___cs",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/cs/drahokamy",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"cs"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___dk",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/dk/aedelsten",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"dk"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___nl",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/nl/edelstenen",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"nl"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___en",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/gemstones",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"en"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___fi",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/fi/jalokivet",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"fi"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___fr",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/fr/les-pierres-precieuses",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"fr"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___de",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/de/edelsteine",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"de"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___it",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/it/pietre-preziose",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"it"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___cn",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/cn/bao-shi",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"cn"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___no",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/no/-edelsteiner",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"no"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___pl",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/pl/kamienie-szlachetne",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"pl"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___pt",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/pt/pedras-preciosas",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"pt"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___ro",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/ro/pietre-pretioase",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ro"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___ru",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/ru/dragotsennyye-kamni",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ru"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___es",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/es/piedras-preciosas",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"es"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___se",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/se/adelstenar",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"se"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: loose_45gemstoneshUlS3UyeQqMeta?.name ?? "685_loose-gemstones___hu",
    path: loose_45gemstoneshUlS3UyeQqMeta?.path ?? "/hu/-dragakovek",
    meta: {...(loose_45gemstoneshUlS3UyeQqMeta || {}), ...{"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"hu"}},
    alias: loose_45gemstoneshUlS3UyeQqMeta?.alias || [],
    redirect: loose_45gemstoneshUlS3UyeQqMeta?.redirect || undefined,
    component: () => import("/src/pages/loose-gemstones.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___ar",
    path: offersBesHVGJGRAMeta?.path ?? "/ar/alaroud.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ar"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___bg",
    path: offersBesHVGJGRAMeta?.path ?? "/bg/oferti",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"bg"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___cs",
    path: offersBesHVGJGRAMeta?.path ?? "/cs/nabidky",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"cs"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___dk",
    path: offersBesHVGJGRAMeta?.path ?? "/dk/tilbud.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"dk"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___nl",
    path: offersBesHVGJGRAMeta?.path ?? "/nl/aanbiedingen.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"nl"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___en",
    path: offersBesHVGJGRAMeta?.path ?? "/offers.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"en"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___fi",
    path: offersBesHVGJGRAMeta?.path ?? "/fi/tarjoukset",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"fi"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___fr",
    path: offersBesHVGJGRAMeta?.path ?? "/fr/offres.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"fr"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___de",
    path: offersBesHVGJGRAMeta?.path ?? "/de/angebote.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"de"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___it",
    path: offersBesHVGJGRAMeta?.path ?? "/it/offerte.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"it"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___cn",
    path: offersBesHVGJGRAMeta?.path ?? "/cn/you-hui.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"cn"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___no",
    path: offersBesHVGJGRAMeta?.path ?? "/no/tilbud",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"no"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___pl",
    path: offersBesHVGJGRAMeta?.path ?? "/pl/oferty.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"pl"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___pt",
    path: offersBesHVGJGRAMeta?.path ?? "/pt/offers.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"pt"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___ro",
    path: offersBesHVGJGRAMeta?.path ?? "/ro/oferte",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ro"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___ru",
    path: offersBesHVGJGRAMeta?.path ?? "/ru/predlozhenija.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ru"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___es",
    path: offersBesHVGJGRAMeta?.path ?? "/es/ofertas",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"es"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___se",
    path: offersBesHVGJGRAMeta?.path ?? "/se/erbjudanden.html",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"se"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: offersBesHVGJGRAMeta?.name ?? "74_offers___hu",
    path: offersBesHVGJGRAMeta?.path ?? "/hu/ajanlatok",
    meta: {...(offersBesHVGJGRAMeta || {}), ...{"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"hu"}},
    alias: offersBesHVGJGRAMeta?.alias || [],
    redirect: offersBesHVGJGRAMeta?.redirect || undefined,
    component: () => import("/src/pages/offers.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___ar",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/ar/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ar"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___bg",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/bg/nashata-etika",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"bg"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___cs",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/cs/nase-etika",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"cs"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___dk",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/dk/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"dk"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___nl",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/nl/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"nl"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___en",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"en"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___fi",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/fi/eettiset-periaatteemme",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"fi"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___fr",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/fr/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"fr"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___de",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/de/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"de"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___it",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/it/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"it"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___cn",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/cn/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"cn"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___no",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/no/-var-etikk",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"no"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___pl",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/pl/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"pl"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___pt",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/pt/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"pt"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___ro",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/ro/etica-noastra",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ro"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___ru",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/ru/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ru"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___es",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/es/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"es"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___se",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/se/our-ethics",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"se"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45ethicsusrpBUV75EMeta?.name ?? "737_our-ethics___hu",
    path: our_45ethicsusrpBUV75EMeta?.path ?? "/hu/az-etikank",
    meta: {...(our_45ethicsusrpBUV75EMeta || {}), ...{"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"hu"}},
    alias: our_45ethicsusrpBUV75EMeta?.alias || [],
    redirect: our_45ethicsusrpBUV75EMeta?.redirect || undefined,
    component: () => import("/src/pages/our-ethics.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___ar",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/ar/our-promises",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ar"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___bg",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/bg/nashite-obeshchaniya",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"bg"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___cs",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/cs/nase-sliby",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"cs"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___dk",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/dk/vores-loefter",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"dk"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___nl",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/nl/onze-beloftes",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"nl"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___en",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/our-promises",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"en"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___fi",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/fi/lupauksistamme",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"fi"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___fr",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/fr/nos-promesses",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"fr"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___de",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/de/unsere-versprechen",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"de"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___it",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/it/nostre-promesse",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"it"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___cn",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/cn/our-promises",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"cn"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___no",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/no/loeftene-vare",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"no"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___pl",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/pl/gwarancjanajlepszejceny.html",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"pl"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___pt",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/pt/as-nossas-promessas",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"pt"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___ro",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/ro/-promisiunile-noastre",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ro"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___ru",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/ru/our-promises",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ru"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___es",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/es/nuestras-promesas",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"es"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___se",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/se/vara-loften",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"se"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: our_45promisesjpQkpMkdr3Meta?.name ?? "78_our-promises___hu",
    path: our_45promisesjpQkpMkdr3Meta?.path ?? "/hu/az-igereteinket",
    meta: {...(our_45promisesjpQkpMkdr3Meta || {}), ...{"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"hu"}},
    alias: our_45promisesjpQkpMkdr3Meta?.alias || [],
    redirect: our_45promisesjpQkpMkdr3Meta?.redirect || undefined,
    component: () => import("/src/pages/our-promises.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___ar",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/ar/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"ar"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___bg",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/bg/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"bg"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___cs",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/cs/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"cs"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___dk",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/dk/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"dk"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___nl",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/nl/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"nl"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___en",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"en"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___fi",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/fi/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"fi"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___fr",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/fr/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"fr"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___de",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/de/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"de"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___it",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/it/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"it"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___cn",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/cn/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"cn"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___no",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/no/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"no"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___pl",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/pl/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"pl"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___pt",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/pt/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"pt"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___ro",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/ro/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"ro"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___ru",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/ru/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"ru"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___es",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/es/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"es"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___se",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/se/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"se"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: page_45not_45found0jy11wTsNJMeta?.name ?? "14_page-not-found___hu",
    path: page_45not_45found0jy11wTsNJMeta?.path ?? "/hu/notFound.aspx",
    meta: {...(page_45not_45found0jy11wTsNJMeta || {}), ...{"pageId":14,"aspRoute":"/notFound.aspx","locale":"hu"}},
    alias: page_45not_45found0jy11wTsNJMeta?.alias || [],
    redirect: page_45not_45found0jy11wTsNJMeta?.redirect || undefined,
    component: () => import("/src/pages/page-not-found.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___ar",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/ar/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___bg",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/bg/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___cs",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/cs/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___dk",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/dk/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___nl",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/nl/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___en",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___fi",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/fi/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___fr",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/fr/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___de",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/de/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___it",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/it/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___cn",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/cn/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___no",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/no/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___pl",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/pl/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___pt",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/pt/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___ro",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/ro/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___ru",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/ru/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___es",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/es/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___se",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/se/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: paypal_45returnt7dgfnL2lhMeta?.name ?? "paypal-return___hu",
    path: paypal_45returnt7dgfnL2lhMeta?.path ?? "/hu/paypal-return",
    meta: paypal_45returnt7dgfnL2lhMeta || {},
    alias: paypal_45returnt7dgfnL2lhMeta?.alias || [],
    redirect: paypal_45returnt7dgfnL2lhMeta?.redirect || undefined,
    component: () => import("/src/pages/paypal-return.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___ar",
    path: guide34XRnI4JRSMeta?.path ?? "/ar/lali/dalil",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ar"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___bg",
    path: guide34XRnI4JRSMeta?.path ?? "/bg/perli/r-kovodstvo",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"bg"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___cs",
    path: guide34XRnI4JRSMeta?.path ?? "/cs/perly/pruvodce",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"cs"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___dk",
    path: guide34XRnI4JRSMeta?.path ?? "/dk/perler/guide",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"dk"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___nl",
    path: guide34XRnI4JRSMeta?.path ?? "/nl/parels/gids",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"nl"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___en",
    path: guide34XRnI4JRSMeta?.path ?? "/pearls/guide",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"en"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___fi",
    path: guide34XRnI4JRSMeta?.path ?? "/fi/helmia/opas",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"fi"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___fr",
    path: guide34XRnI4JRSMeta?.path ?? "/fr/perles/guide",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"fr"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___de",
    path: guide34XRnI4JRSMeta?.path ?? "/de/leitfaden/perlen",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"de"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___it",
    path: guide34XRnI4JRSMeta?.path ?? "/it/perle/guida",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"it"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___cn",
    path: guide34XRnI4JRSMeta?.path ?? "/cn/zhenzhu/zhi-nan",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"cn"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___no",
    path: guide34XRnI4JRSMeta?.path ?? "/no/perler/guide",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"no"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___pl",
    path: guide34XRnI4JRSMeta?.path ?? "/pl/perla/przewodnik",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"pl"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___pt",
    path: guide34XRnI4JRSMeta?.path ?? "/pt/perolas/guia",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"pt"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___ro",
    path: guide34XRnI4JRSMeta?.path ?? "/ro/perle/ghid",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ro"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___ru",
    path: guide34XRnI4JRSMeta?.path ?? "/ru/zhemchug/rukovodstvo",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ru"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___es",
    path: guide34XRnI4JRSMeta?.path ?? "/es/perlas/guia",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"es"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___se",
    path: guide34XRnI4JRSMeta?.path ?? "/se/parlor/guide",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"se"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: guide34XRnI4JRSMeta?.name ?? "751_pearls-guide___hu",
    path: guide34XRnI4JRSMeta?.path ?? "/hu/gyongyok/utmutato",
    meta: {...(guide34XRnI4JRSMeta || {}), ...{"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"hu"}},
    alias: guide34XRnI4JRSMeta?.alias || [],
    redirect: guide34XRnI4JRSMeta?.redirect || undefined,
    component: () => import("/src/pages/pearls/guide.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___ar",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/ar/khasousya-77daaymoundz.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ar"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___bg",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/bg/zashchita-na-lichnite-danni-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"bg"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___cs",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/cs/privacy-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"cs"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___dk",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/dk/privatliv-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"dk"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___nl",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/nl/privacy-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"nl"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___en",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/privacy-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"en"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___fi",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/fi/privacy-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"fi"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___fr",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/fr/confidentialite-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"fr"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___de",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/de/datenschutz-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"de"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___it",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/it/privacy-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"it"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___cn",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/cn/yin-si-diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"cn"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___no",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/no/-personvern-77diamanter",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"no"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___pl",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/pl/77diamonds-prywatnosc.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"pl"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___pt",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/pt/privacy-77diamantes.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"pt"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___ro",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/ro/privacy-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ro"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___ru",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/ru/konfidencial-nost--77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ru"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___es",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/es/privacidad-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"es"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___se",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/se/integritet-77diamonds.html",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"se"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: privacy_4577diamondsgsAtERu5cXMeta?.name ?? "72_privacy-77diamonds___hu",
    path: privacy_4577diamondsgsAtERu5cXMeta?.path ?? "/hu/privacy-77diamonds",
    meta: {...(privacy_4577diamondsgsAtERu5cXMeta || {}), ...{"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"hu"}},
    alias: privacy_4577diamondsgsAtERu5cXMeta?.alias || [],
    redirect: privacy_4577diamondsgsAtERu5cXMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy-77diamonds.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___ar",
    path: register9mc1FDQpDSMeta?.path ?? "/ar/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"ar"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___bg",
    path: register9mc1FDQpDSMeta?.path ?? "/bg/registraciya",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"bg"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___cs",
    path: register9mc1FDQpDSMeta?.path ?? "/cs/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"cs"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___dk",
    path: register9mc1FDQpDSMeta?.path ?? "/dk/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"dk"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___nl",
    path: register9mc1FDQpDSMeta?.path ?? "/nl/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"nl"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___en",
    path: register9mc1FDQpDSMeta?.path ?? "/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"en"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___fi",
    path: register9mc1FDQpDSMeta?.path ?? "/fi/rekisteroi",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"fi"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___fr",
    path: register9mc1FDQpDSMeta?.path ?? "/fr/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"fr"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___de",
    path: register9mc1FDQpDSMeta?.path ?? "/de/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"de"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___it",
    path: register9mc1FDQpDSMeta?.path ?? "/it/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"it"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___cn",
    path: register9mc1FDQpDSMeta?.path ?? "/cn/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"cn"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___no",
    path: register9mc1FDQpDSMeta?.path ?? "/no/registrer-deg",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"no"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___pl",
    path: register9mc1FDQpDSMeta?.path ?? "/pl/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"pl"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___pt",
    path: register9mc1FDQpDSMeta?.path ?? "/pt/registar",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"pt"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___ro",
    path: register9mc1FDQpDSMeta?.path ?? "/ro/inregistreaza-te",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"ro"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___ru",
    path: register9mc1FDQpDSMeta?.path ?? "/ru/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"ru"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___es",
    path: register9mc1FDQpDSMeta?.path ?? "/es/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"es"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___se",
    path: register9mc1FDQpDSMeta?.path ?? "/se/register",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"se"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: register9mc1FDQpDSMeta?.name ?? "754_register___hu",
    path: register9mc1FDQpDSMeta?.path ?? "/hu/regisztracio",
    meta: {...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"}},
    alias: register9mc1FDQpDSMeta?.alias || [],
    redirect: register9mc1FDQpDSMeta?.redirect || undefined,
    component: () => import("/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___ar",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/ar/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"ar"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___bg",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/bg/otmyana-na-parolata",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"bg"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___cs",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/cs/resetovat-heslo",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"cs"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___dk",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/dk/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"dk"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___nl",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/nl/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"nl"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___en",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"en"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___fi",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/fi/reset-password-(nollaa-salasana)",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"fi"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___fr",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/fr/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"fr"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___de",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/de/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"de"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___it",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/it/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"it"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___cn",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/cn/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"cn"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___no",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/no/-tilbakestill-passord",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"no"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___pl",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/pl/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"pl"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___pt",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/pt/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"pt"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___ro",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/ro/reset-parola",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"ro"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___ru",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/ru/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"ru"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___es",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/es/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"es"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___se",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/se/reset-password",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"se"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwGIRYTe8GpMeta?.name ?? "760_reset-password___hu",
    path: reset_45passwordwGIRYTe8GpMeta?.path ?? "/hu/jelszo-alaphelyzetbe-allitasa",
    meta: {...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"}},
    alias: reset_45passwordwGIRYTe8GpMeta?.alias || [],
    redirect: reset_45passwordwGIRYTe8GpMeta?.redirect || undefined,
    component: () => import("/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___ar",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/ar/masat-kyubid-almithalia",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ar"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___bg",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/bg/cupids-ideal-diamonds",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"bg"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___cs",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/cs/cupids-idealni-diamanty",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"cs"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___dk",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/dk/cupids-ideal-diamanter",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"dk"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___nl",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/nl/cupids-ideal-diamanten",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"nl"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___en",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/cupids-ideal-diamonds",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"en"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___fi",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/fi/kupit-ihanteelliset-timantit",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"fi"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___fr",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/fr/l-ideal-de-cupidon",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"fr"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___de",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/de/cupids-ideal-diamanten",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"de"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___it",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/it/diamanti-ideali-di-cupido",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"it"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___cn",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/cn/qiubite-wanmei-zuanshi",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"cn"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___no",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/no/cupids-ideelle-diamanter",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"no"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___pl",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/pl/cupids-ideal-diamonds",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"pl"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___pt",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/pt/cupids-ideal-diamantes",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"pt"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___ro",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/ro/cupidele-diamantelor-ideale",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ro"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___ru",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/ru/ideal-kupidona-brillianty",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ru"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___es",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/es/los-diamantes-ideales-de-cupido",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"es"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___se",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/se/diamanterna-cupids-ideal",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"se"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: cupids_45Ideali5m78ucfVsMeta?.name ?? "717_seo-pages-cupids-Ideal___hu",
    path: cupids_45Ideali5m78ucfVsMeta?.path ?? "/hu/cupids-idealis-gyemantok",
    meta: {...(cupids_45Ideali5m78ucfVsMeta || {}), ...{"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"hu"}},
    alias: cupids_45Ideali5m78ucfVsMeta?.alias || [],
    redirect: cupids_45Ideali5m78ucfVsMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___ar",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/ar/yaqut-jrinland",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ar"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___bg",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/bg/zeleni-rubini",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"bg"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___cs",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/cs/greenland-rubiny",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"cs"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___dk",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/dk/gronlandske-rubiner",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"dk"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___nl",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/nl/robijnen-uit-groenland",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"nl"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___en",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/greenland-rubies",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"en"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___fi",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/fi/vihrean-maan-rubiinit",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"fi"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___fr",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/fr/rubis-du-groenland",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"fr"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___de",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/de/gronland-rubine",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"de"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___it",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/it/rubini-della-groenlandia",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"it"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___cn",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/cn/gelinglan-hongbaoshi",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"cn"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___no",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/no/greenland-rubies",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"no"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___pl",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/pl/rubiny-grenlandzkie",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"pl"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___pt",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/pt/greenland-rubies",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"pt"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___ro",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/ro/greenland-rubine",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ro"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___ru",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/ru/grenlandskiye-rubiny",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ru"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___es",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/es/rubies-de-groenlandia",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"es"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___se",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/se/gronlandska-rubiner",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"se"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: greenland_45rubiesc3Qqr92tB3Meta?.name ?? "713_seo-pages-greenland-rubies___hu",
    path: greenland_45rubiesc3Qqr92tB3Meta?.path ?? "/hu/greenland-rubinok",
    meta: {...(greenland_45rubiesc3Qqr92tB3Meta || {}), ...{"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"hu"}},
    alias: greenland_45rubiesc3Qqr92tB3Meta?.alias || [],
    redirect: greenland_45rubiesc3Qqr92tB3Meta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___ar",
    path: shapeOKFO3FtHaAMeta?.path ?? "/ar/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ar"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___bg",
    path: shapeOKFO3FtHaAMeta?.path ?? "/bg/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"bg"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___cs",
    path: shapeOKFO3FtHaAMeta?.path ?? "/cs/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"cs"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___dk",
    path: shapeOKFO3FtHaAMeta?.path ?? "/dk/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"dk"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___nl",
    path: shapeOKFO3FtHaAMeta?.path ?? "/nl/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"nl"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___en",
    path: shapeOKFO3FtHaAMeta?.path ?? "/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"en"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___fi",
    path: shapeOKFO3FtHaAMeta?.path ?? "/fi/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"fi"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___fr",
    path: shapeOKFO3FtHaAMeta?.path ?? "/fr/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"fr"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___de",
    path: shapeOKFO3FtHaAMeta?.path ?? "/de/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"de"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___it",
    path: shapeOKFO3FtHaAMeta?.path ?? "/it/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"it"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___cn",
    path: shapeOKFO3FtHaAMeta?.path ?? "/cn/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"cn"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___no",
    path: shapeOKFO3FtHaAMeta?.path ?? "/no/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"no"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___pl",
    path: shapeOKFO3FtHaAMeta?.path ?? "/pl/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"pl"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___pt",
    path: shapeOKFO3FtHaAMeta?.path ?? "/pt/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"pt"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___ro",
    path: shapeOKFO3FtHaAMeta?.path ?? "/ro/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ro"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___ru",
    path: shapeOKFO3FtHaAMeta?.path ?? "/ru/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ru"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___es",
    path: shapeOKFO3FtHaAMeta?.path ?? "/es/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"es"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___se",
    path: shapeOKFO3FtHaAMeta?.path ?? "/se/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"se"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shapeOKFO3FtHaAMeta?.name ?? "132_seo-pages-shape___hu",
    path: shapeOKFO3FtHaAMeta?.path ?? "/hu/SEOPages/shape.aspx",
    meta: {...(shapeOKFO3FtHaAMeta || {}), ...{"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"hu"}},
    alias: shapeOKFO3FtHaAMeta?.alias || [],
    redirect: shapeOKFO3FtHaAMeta?.redirect || undefined,
    component: () => import("/src/pages/seo-pages/shape.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___ar",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/ar/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___bg",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/bg/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___cs",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/cs/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___dk",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/dk/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___nl",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/nl/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___en",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___fi",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/fi/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___fr",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/fr/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___de",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/de/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___it",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/it/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___cn",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/cn/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___no",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/no/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___pl",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/pl/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___pt",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/pt/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___ro",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/ro/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___ru",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/ru/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___es",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/es/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___se",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/se/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    name: shop_45bagTYRQ3TpGKfMeta?.name ?? "shop-bag___hu",
    path: shop_45bagTYRQ3TpGKfMeta?.path ?? "/hu/shop-bag",
    meta: shop_45bagTYRQ3TpGKfMeta || {},
    alias: shop_45bagTYRQ3TpGKfMeta?.alias || [],
    redirect: shop_45bagTYRQ3TpGKfMeta?.redirect || undefined,
    component: () => import("/src/pages/shop-bag.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/ar/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/saree.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm-alkhotba/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_alabdya.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_alzwaaj.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-khwaatm-alkhotba_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khwaatm-alkhotba/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-khawaatm_zwaaj_klaaseekya.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_zwaaj_klaaseekya.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-khawaatm_zwaaj_maasyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_zwaaj_maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/dalaayaat_maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-dalaayaat_almaarkaat.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/dalaayaat_almaarkaat.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/dabousee_maasee.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-aqraat_halaqyah_maasyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/aqraat_halaqyah_maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--aqraat_motadlya_maasyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/-aqraat_motadlya_maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--aqraat_almaarkaat_aldbousya.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/-aqraat_almaarkaat_aldbousya.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_alkoukteel.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-asaawer_maasyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/asaawer_maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-khawaatm_alabdyah_tarsee_mkhlbee.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_alabdyah_tarsee_mkhlbee.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-khawaatm_alabdyat_trsee_qanah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_alabdyat_trsee_qanah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-khawaatm_maasyah_mrsoufa.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_maasyah_mrsoufa.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-khawaatm_maasyah_maarkh.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm_maasyah_maarkh.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat_saaiba.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-majmouat_alabgdyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/majmouat_alabgdyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-majmouat_gaalaaxee.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/majmouat_gaalaaxee.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-mostdeer.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-mostdeer.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-brinses.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-brinses.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-zomorodee.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-zomorodee.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-asher.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-asher.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-komthree.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-komthree.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-mostateel.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-mostateel.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-baydaawee.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-baydaawee.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-wasaada.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-wasaada.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-qalb.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-qalb.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat-qata-maarkeez.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat-qata-maarkeez.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-azwaaj_almaasaat_saabah.htm_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/azwaaj_almaasaat_saabah.htm/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaasaat_mlouna.html_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaasaat_mlouna.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-azraar-akmaam.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/azraar-akmaam.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-majmouat_kounfeetee.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/majmouat_kounfeetee.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-khwaatm-alkhotouba_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khwaatm-alkhotouba/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-mgmouat-almaas_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/mgmouat-almaas/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-khwaatm-alkhotbah_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khwaatm-alkhotbah/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-magmoaat-almaas_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/magmoaat-almaas/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-aqaad-maasyah_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/aqaad-maasyah/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-aqraat-maasyah.html_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/aqraat-maasyah.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-almogouhraat-almaasya_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/almogouhraat-almaasya/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almaas_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almaas/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/mojoohraat/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "11_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almagmouat-almasih/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-khawatm-khotuba_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawatm-khotuba/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-khawaatm-zafaaf_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm-zafaaf/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-khawaatm-alkholoud_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/khawaatm-alkholoud/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almas_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/almas/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-alahgar-alkarima_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/alahgar-alkarima/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-almagmouat-almasya_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/almagmouat-almasya/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-ashshahadat_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/ashshahadat/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-mas-sayb-mstnbt-fy-almukhtabr_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/mas-sayb-mstnbt-fy-almukhtabr/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-engagement-rings_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___ar",
    path: indexVxo5R7N7rlMeta?.path ?? "/ar/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--ذهب-ابيض_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/-ذهب-ابيض/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--ذهب-اصفر_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/-ذهب-اصفر/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--ذهب-وردي_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/-ذهب-وردي/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamond-necklaces_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/diamond-necklaces/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-jewellery_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/diamond-jewellery/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamond-earrings_slug___ar",
    path: indexSK19o12TcXMeta?.path ?? "/ar/diamond-earrings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/bg/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/-godezhni-pr-steni/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/pr-steni-na-vechnostta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/svatbeni-pr-steni/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-angazhirashchi-pr-steni_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/angazhirashchi-pr-steni/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-brachni-khalki_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/brachni-khalki/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/diamanteni-og-rlici/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/diamanteni-obeci/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-obeci-s-diamanti_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/obeci-s-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/bizhuta-s-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3--pr-steni-na-vechnostta_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/-pr-steni-na-vechnostta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-kolekcii-ot-diamanti_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/kolekcii-ot-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kr-gloshlifovani-diamanti_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/kr-gloshlifovani-diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-shlifovka-princesa_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-shlifovka-princesa/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-diamanteno-shlifovane_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-diamanteno-shlifovane/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti,-shlifovani-po-metoda-na-asher_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti,-shlifovani-po-metoda-na-asher/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-perleno-shlifovane_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-perleno-shlifovane/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-radiantsko-shlifovane_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-radiantsko-shlifovane/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ovalno-shlifovani-diamanti_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/ovalno-shlifovani-diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-shlifovana-v-zglavnica_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-shlifovana-v-zglavnica/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-shlifovano-s-rce_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-shlifovano-s-rce/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-s-brilyantna-shlifovka_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/diamanti-s-brilyantna-shlifovka/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-brachni-pr-steni_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/brachni-pr-steni/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--obeci-s-diamanti_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/-obeci-s-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/bizhuta/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-sk-pocenni-kam-ni_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/sk-pocenni-kam-ni/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-prepor-ki_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/prepor-ki/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-ocenki_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/ocenki/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-svobodni-laboratorno-otgledani-diamanti_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/svobodni-laboratorno-otgledani-diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-stari-shlifovani-diamanti_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/stari-shlifovani-diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--khv-rchila-i-shchitove-diamanti_slug___bg",
    path: indexVxo5R7N7rlMeta?.path ?? "/bg/-khv-rchila-i-shchitove-diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-kolekcii-s-diamanti_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/kolekcii-s-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamanteni-kolieta_slug___bg",
    path: indexSK19o12TcXMeta?.path ?? "/bg/diamanteni-kolieta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/cs/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/snubni-prsteny/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/kruhy-vecnosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/svatebni-prsteny/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamantove-nahrdelniky/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamantove-nausnice/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamantove-sperky/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-prstenu-vecnosti_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/prstenu-vecnosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-vecne-prsteny_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/vecne-prsteny/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamantove-sbirky_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamantove-sbirky/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kulate-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/kulate-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-princess-cut-diamonds_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/princess-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emeraldove-brousene-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/emeraldove-brousene-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscheruv-brus_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/asscheruv-brus/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brousene-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/brousene-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radiantni-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/radiantni-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ovalny-brus-diamantu_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/ovalny-brus-diamantu/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brousene-diamanty-ve-tvaru-polstare_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/brousene-diamanty-ve-tvaru-polstare/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-cut-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/marquise-cut-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1--zasnubni-prsteny_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/-zasnubni-prsteny/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-snubni-prsteny-_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/snubni-prsteny-/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/sperky/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-prsteny-vecnosti_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/prsteny-vecnosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-prsten-vecnosti_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/prsten-vecnosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-drahokamy_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/drahokamy/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kruhove-kameny_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/kruhove-kameny/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kameny_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/kameny/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-drahe-kameny_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/drahe-kameny/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-zkusenosti_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/zkusenosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-volne-laboratorne-pestovane-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/volne-laboratorne-pestovane-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-stare-brousene-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/stare-brousene-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-drahokamy-a-stity-diamanty_slug___cs",
    path: indexVxo5R7N7rlMeta?.path ?? "/cs/drahokamy-a-stity-diamanty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamantove-kolekce_slug___cs",
    path: indexSK19o12TcXMeta?.path ?? "/cs/diamantove-kolekce/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/dk/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/ekspres/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/forlovelsesringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/allianceringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/vielsesringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamanthalskaeder/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamantoreringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamantsmykker/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pavé_diamantringe.html_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/pavé_diamantringe.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamant-kollektioner_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamant-kollektioner/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-rund-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/rund-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-prinsesse-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/prinsesse-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-smaragd-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/smaragd-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/asscher-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-paere-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/paere-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radiant-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/radiant-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-oval-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/oval-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pude-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/pude-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-hjerte-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/hjerte-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-slebet-diamant_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/marquise-slebet-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/smykker/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamantkollektioner_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamantkollektioner/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-aedelsten_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/aedelsten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gemstones_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/gemstones/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-kundereferencer_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/kundereferencer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-lose-laboratorieskabte-diamanter_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/lose-laboratorieskabte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-forlovelses-ringe_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/forlovelses-ringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___dk",
    path: indexVxo5R7N7rlMeta?.path ?? "/dk/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamanthalskæder_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamanthalskæder/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantøreringe_slug___dk",
    path: indexSK19o12TcXMeta?.path ?? "/dk/diamantøreringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/nl/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/expres/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/verlovingsringen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/eternity-ringen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/trouwringen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten-halskettingen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten-oorbellen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten-sieraden/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamant_ringen.html_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/pave_diamant_ringen.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamant-collecties_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamant-collecties/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-rond-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/rond-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-princess-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/princess-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emerald-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/emerald-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/asscher-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-peer-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/peer-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radiant-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/radiant-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ovaal-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/ovaal-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-cushion-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/cushion-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-hart-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/hart-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-geslepen-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/marquise-geslepen-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamanten_halskettingen_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten_halskettingen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/sieraden/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamant-collectie_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamant-collectie/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-edelstenen_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/edelstenen/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamanten-collecties_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten-collecties/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-getuigenissen_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/getuigenissen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-laboratorium-gekweekte-diamanten_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/laboratorium-gekweekte-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___nl",
    path: indexVxo5R7N7rlMeta?.path ?? "/nl/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamanten-kettingen_slug___nl",
    path: indexSK19o12TcXMeta?.path ?? "/nl/diamanten-kettingen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/eternity-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/diamond-necklaces/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/diamond-earrings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/diamond-jewellery/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings.html_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/pave_diamond_rings.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamond-collections_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-round-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/round-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-princess-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/princess-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emerald-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/emerald-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/asscher-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pear-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/pear-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radiant-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/radiant-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-oval-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/oval-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-cushion-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/cushion-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-heart-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/heart-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/marquise-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/jewellery/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gemstones_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/gemstones/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___en",
    path: indexSK19o12TcXMeta?.path ?? "/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-loose-lab-grown-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/loose-lab-grown-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___en",
    path: indexVxo5R7N7rlMeta?.path ?? "/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/fi/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/kihlasormukset/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/ikuisuusrenkaat/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/haasormukset/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamantti-kaulakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/timantti-korvakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantti-korvakorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamantti-korvakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamantti-korut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamondikorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamondikorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamondikokoelmat_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamondikokoelmat/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pyoristetyt-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/pyoristetyt-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-prinsessahiontaiset-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/prinsessahiontaiset-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emerald-hiontaiset-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/emerald-hiontaiset-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-hiotut-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/asscher-hiotut-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kipinahiottuja-timantteja_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/kipinahiottuja-timantteja/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radianttihiotut-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/radianttihiotut-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ovaalihiontaiset-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/ovaalihiontaiset-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-tyynyleikattuja-timantteja_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/tyynyleikattuja-timantteja/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-sydanhiontaiset-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/sydanhiontaiset-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-hiontaiset-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/marquise-hiontaiset-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamondikaulakorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamondikaulakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--timanttikorvakorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/-timanttikorvakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6--timanttikorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/-timanttikorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/korut/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-ikuisuussormukset_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/ikuisuussormukset/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-ikuisuuden-sormukset_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/ikuisuuden-sormukset/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-jalokivet_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/jalokivet/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-jalokivia_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/jalokivia/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-loose-lab-growing-diamonds_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/loose-lab-growing-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-vanhoja-hiottuja-timantteja_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/vanhoja-hiottuja-timantteja/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kiteet-ja-kilvet-timantit_slug___fi",
    path: indexVxo5R7N7rlMeta?.path ?? "/fi/kiteet-ja-kilvet-timantit/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6--timanttikokoelmat_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/-timanttikokoelmat/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-timantti-kaulakorut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/timantti-kaulakorut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-timantti-korut_slug___fi",
    path: indexSK19o12TcXMeta?.path ?? "/fi/timantti-korut/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/fr/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/bagues-de-fiancailles/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/bagues-eternite/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/alliances/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-bague-de-fiancailles_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/bague-de-fiancailles/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/colliers-en-diamant/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/boucles-oreilles-en-diamant/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/bijoux-en-diamant/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-bague_diamant_pave.html_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/bague_diamant_pave.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamants/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collections-de-diamants_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/collections-de-diamants/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-rond_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-rond/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-princesse_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-princesse/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-emeraude_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-emeraude/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-asscher_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-asscher/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-poire_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-poire/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-radiant_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-radiant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-ovale_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-ovale/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-coussin_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-coussin/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-coeur_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-coeur/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamant-taille-marquise_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamant-taille-marquise/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-collections-diamants_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/collections-diamants/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/bijoux/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-les-pierres-precieuses_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/les-pierres-precieuses/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-temoignages_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/temoignages/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamants-de-labo-en-vrac_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/diamants-de-labo-en-vrac/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___fr",
    path: indexVxo5R7N7rlMeta?.path ?? "/fr/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-colliers-diamants_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/colliers-diamants/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-boucles-d'oreilles-diamant_slug___fr",
    path: indexSK19o12TcXMeta?.path ?? "/fr/boucles-d'oreilles-diamant/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/de/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/verlobungsringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/ewigkeitsringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/eheringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamant-halsketten/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamant-ohrringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamantschmuck/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pavefassung_diamantringe.html_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/pavefassung_diamantringe.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamant-kollektionen_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamant-kollektionen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-brillant-schliff.html_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-brillant-schliff.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-prinzess-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-prinzess-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-smaragd-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-smaragd-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-asscher-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-asscher-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-tropfen-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-tropfen-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-radiant-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-radiant-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-oval-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-oval-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-cushion-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-cushion-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-herz-schliff.html_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-herz-schliff.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanten-im-marquise-schliff_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/diamanten-im-marquise-schliff/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/schmuck/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-kollektionen_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamond-kollektionen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-edelsteine_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/edelsteine/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-referenzen_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/referenzen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-lose-im-labor-gezuchtete-diamanten_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/lose-im-labor-gezuchtete-diamanten/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-Verlobungsringe_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/Verlobungsringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___de",
    path: indexVxo5R7N7rlMeta?.path ?? "/de/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamantenhalsketten_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamantenhalsketten/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamantenschmuck_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamantenschmuck/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantenohrringe_slug___de",
    path: indexSK19o12TcXMeta?.path ?? "/de/diamantenohrringe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/it/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/express.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli-fidanzamento/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_eternity/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/fedi_nuziali/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-fedi_nuziali_classiche.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/fedi_nuziali_classiche.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-fedi_nuziali_diamanti.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/fedi_nuziali_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/ciondoli_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-ciondoli_designer.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/ciondoli_designer.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini_lobo_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini_cerchio_diamanti.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini_cerchio_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini_goccia_diamanti.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini_goccia_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini_lobo_designer.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini_lobo_designer.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_cocktail.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-bracciali_diamanti.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/bracciali_diamanti.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anelli_eternity_incastonatura_griffes.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_eternity_incastonatura_griffes.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anelli_eternity_incastonatura_binario.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_eternity_incastonatura_binario.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anelli_diamanti_pave.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_diamanti_pave.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anelli_diamanti_designer.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli_diamanti_designer.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamanti_sfusi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collezione_monologo.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collezione_monologo.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-collezione-galassia.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collezione-galassia.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-rotondo.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-rotondo.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-princess.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-princess.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-smeraldo.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-smeraldo.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-asscher.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-asscher.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-goccia.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-goccia.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-radiante.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-radiante.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-ovale.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-ovale.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-cuscino.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-cuscino.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-cuore.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-cuore.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taglio-marquise.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamante-taglio-marquise.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-coppie_diamanti_sfusi.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/coppie_diamanti_sfusi.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti_colorati.html_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamanti_colorati.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-gemelli.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/gemelli.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-collezione-coriandoli.html_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collezione-coriandoli.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-collezioni-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collezioni-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collane_diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collane_diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-gioielli-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/gioielli-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamanti/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/gioielli/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "11_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamanti-collezioni/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-fedi-nuziali_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/fedi-nuziali/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anelli-eternity_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/anelli-eternity/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pietre-preziose_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/pietre-preziose/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanti-creati-in-laboratorio-sfusi_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/diamanti-creati-in-laboratorio-sfusi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___it",
    path: indexVxo5R7N7rlMeta?.path ?? "/it/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--collane-di-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/-collane-di-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collane-di-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/collane-di-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-gioielli-con-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/gioielli-con-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini-con-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini-con-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-orecchini-di-diamanti_slug___it",
    path: indexSK19o12TcXMeta?.path ?? "/it/orecchini-di-diamanti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/cn/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/kuai-jie.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/ding-hun-jie-zhi/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/yong-heng-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/jie-hun-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-jing-dian-jie-hun-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/jing-dian-jie-hun-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-zuan-shi-jie-hun-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-jie-hun-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-diao-zhui.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-she-ji-shi-diao-zhui.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/she-ji-shi-diao-zhui.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-er-ding.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-zuan-shi-er-huan.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-er-huan.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-zuan-shi-er-zhui.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-er-zhui.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-she-ji-shi-er-ding.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/she-ji-shi-er-ding.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/ji-wei-jiu-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-zuan-shi-shou-lian.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-shou-lian.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-zhua-xiang-yong-heng-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zhua-xiang-yong-heng-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-cao-xiang-yong-heng-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/cao-xiang-yong-heng-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-mi-ding-zuan-shi-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/mi-ding-zuan-shi-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-she-ji-shi-zuan-shi-jie-zhi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/she-ji-shi-zuan-shi-jie-zhi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/luo-zuan.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-zi-mu-xi-lie.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zi-mu-xi-lie.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-yin-he-xi-lie.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/yin-he-xi-lie.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-yuan-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/yuan-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gong-zhu-fang-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/gong-zhu-fang-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-zu-mu-lu-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/zu-mu-lu-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-a-si-qie-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/a-si-qie-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-li-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/li-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-lei-di-en-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/lei-di-en-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-tuo-yuan-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/tuo-yuan-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-dian-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/dian-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-xin-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/xin-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ma-yan-xing-qie-gong-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/ma-yan-xing-qie-gong-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-luo-zuan-dui-zuan.html_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/luo-zuan-dui-zuan.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-cai-se-zuan-shi.html_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/cai-se-zuan-shi.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-xiu-kou.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/xiu-kou.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-wu-cai-zhi-xi-lie.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/wu-cai-zhi-xi-lie.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-ding-hun-jie-zhi-nan-shi-ding-hun-jie-zhi?topbanner=show_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/ding-hun-jie-zhi-nan-shi-ding-hun-jie-zhi?topbanner=show/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-zuan-shi-kuan-xi_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-kuan-xi/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-zuan-shi-xiang-lian.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-xiang-lian.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-zuan-shi-er-shi.html_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-er-shi.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-zuan-shi-zhu-bao_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zuan-shi-zhu-bao/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/zhu-bao/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-jie-hun-jie-zhi_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/jie-hun-jie-zhi/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-yong-heng-jie-zhi_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/yong-heng-jie-zhi/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-bao-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/bao-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-ke-hu-fan-kui_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/ke-hu-fan-kui/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-luo-zuan-shi-yan-shi-pei-yu-zuan-shi_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/luo-zuan-shi-yan-shi-pei-yu-zuan-shi/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___cn",
    path: indexVxo5R7N7rlMeta?.path ?? "/cn/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamond-necklaces_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/diamond-necklaces/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--钻石项链_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/-钻石项链/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-zhaoshi-zhubao_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/zhaoshi-zhubao/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6--钻石珠宝_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/-钻石珠宝/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--钻石耳环_slug___cn",
    path: indexSK19o12TcXMeta?.path ?? "/cn/-钻石耳环/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/no/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/forlovelsesringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/-evighetsringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/bryllupsringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamanthalskjeder/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamantkjeder_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantkjeder/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantoeredobber/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantsmykker/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamantsamlinger_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantsamlinger/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--rundslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-rundslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-prinsesseslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/prinsesseslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emeraldslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/emeraldslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscherslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/asscherslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--pæreslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-pæreslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--diamanter-med-stralende-sliping_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-diamanter-med-stralende-sliping/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--ovalslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-ovalslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--puteslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-puteslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-hjerteslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/hjerteslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquiseslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/marquiseslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamantkolleksjoner_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantkolleksjoner/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamant-halskjeder_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamant-halskjeder/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantoereringer_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantoereringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-smykker/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1--forlovelsesringer_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/-forlovelsesringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--edelstener_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-edelstener/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-vitnesbyrd_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/vitnesbyrd/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-uttalelser_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/uttalelser/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7--loese-laboratoriedyrkede-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/-loese-laboratoriedyrkede-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gamleslipte-diamanter_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/gamleslipte-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamanter-i-drager-og-skjold_slug___no",
    path: indexVxo5R7N7rlMeta?.path ?? "/no/diamanter-i-drager-og-skjold/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantøredobber_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantøredobber/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-diamantøreringer_slug___no",
    path: indexSK19o12TcXMeta?.path ?? "/no/diamantøreringer/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/pl/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/ekspresowo/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/pierscionki-zareczynowe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/pierscionki-wiecznej-milosci.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/obraczki-slubne/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-engagement-rings_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/naszyjniki-z-diamentami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/kolczyki-z-diamentami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/bizuteria-z-diamentami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pierscionki-wiecznej-milosci_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/pierscionki-wiecznej-milosci/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings.html_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/pave_diamond_rings.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-kolekcje-diamentowe_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/kolekcje-diamentowe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-okragly_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-okragly/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-szmaragdowy_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-szmaragdowy/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-asscher_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-asscher/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-gruszka_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-gruszka/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-radiant_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-radiant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-owalny_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-owalny/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-poduszka_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-poduszka/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-serce_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-serce/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-szlif-markiza_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-szlif-markiza/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-naszyjniki_z_diamentami.html_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/naszyjniki_z_diamentami.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/bizuteria/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kamienie-szlachetne_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/kamienie-szlachetne/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-opinie_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/opinie/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-recenzje_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/recenzje/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamenty-syntetyczne-luzem_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/diamenty-syntetyczne-luzem/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___pl",
    path: indexVxo5R7N7rlMeta?.path ?? "/pl/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--naszyjniki-z-diamentami_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/-naszyjniki-z-diamentami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-naszyjniki-diamentowe_slug___pl",
    path: indexSK19o12TcXMeta?.path ?? "/pl/naszyjniki-diamentowe/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/pt/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/aneis-de-noivado/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/aneis-de-eternidade/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/aliancas/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/colares-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/brincos-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/joias-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings.html_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/pave_diamond_rings.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-colecoes-diamantes_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/colecoes-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-redondo-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/redondo-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-princesa-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/princesa-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-esmeralda-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/esmeralda-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/asscher-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pera-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/pera-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radiante-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/radiante-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-oval-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/oval-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-almofada-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/almofada-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-coracao-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/coracao-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/marquise-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/jewellery/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pedras-preciosas_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/pedras-preciosas/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantes-criados-em-laboratorio_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/diamantes-criados-em-laboratorio/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/old-cut-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamantes_slug___pt",
    path: indexVxo5R7N7rlMeta?.path ?? "/pt/kites-and-shields-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-joias-com-diamantes_slug___pt",
    path: indexSK19o12TcXMeta?.path ?? "/pt/joias-com-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/ro/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/anule-de-logodna/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/inelele-eternitatii/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/anulete-de-nunta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1--inele-de-logodna_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/-inele-de-logodna/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-anule-de-nunta_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/anule-de-nunta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/coliere-cu-diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/-cercei-cu-diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-picaturi-de-diamante_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/picaturi-de-diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/bijuterii-cu-diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3--inele-de-eternitate_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/-inele-de-eternitate/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-aneluri-de-eternitate_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/aneluri-de-eternitate/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-colectii-de-diamante_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/colectii-de-diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-rotund_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-rotund/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-cu-taietura-printesa_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-cu-taietura-printesa/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-smarald_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-smarald/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantele-taiate-in-forma-de-ascher_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamantele-taiate-in-forma-de-ascher/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-in-forma-de-para_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-in-forma-de-para/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-radiant_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-radiant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-oval_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-oval/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantele-taiate-in-forma-de-perna_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamantele-taiate-in-forma-de-perna/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-in-forma-de-inima_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-in-forma-de-inima/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-taiate-in-forma-de-marchiza_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-taiate-in-forma-de-marchiza/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--cercei-de-diamant_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/-cercei-de-diamant/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/bijuterii/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-aneluri-de-logodna_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/aneluri-de-logodna/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2--inele-de-nunta_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/-inele-de-nunta/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pietre-pretioase_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/pietre-pretioase/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-pietre-de-piatra-pretioasa_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/pietre-de-piatra-pretioasa/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-testimoniale_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/testimoniale/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-de-laborator-libere-crescute-in-laborator_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-de-laborator-libere-crescute-in-laborator/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-vechi-taiate_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/diamante-vechi-taiate/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-dragonele-zmeilor-si-scuturilor_slug___ro",
    path: indexVxo5R7N7rlMeta?.path ?? "/ro/dragonele-zmeilor-si-scuturilor/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___ro",
    path: indexSK19o12TcXMeta?.path ?? "/ro/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/ru/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/ekspress.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/kolca-vechnosti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/obruchalnye-kolca/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-pomolvochnye-kol-ca_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/pomolvochnye-kol-ca/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-klassicheskie_obruchal-nye_kol-ca.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/klassicheskie_obruchal-nye_kol-ca.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-brilliantovye_obruchal-nye_kol-ca.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_obruchal-nye_kol-ca.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_podveski.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-dizajnerskie_podveski.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/dizajnerskie_podveski.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_ser-gi-pusety(gvozdiki).html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-brilliantovye_ser-gi-kol-ca.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_ser-gi-kol-ca.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-brilliantovye_ser-gi-podveski.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_ser-gi-podveski.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-dizajnerskie_ser-gi-pusety(gvozdiki).html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/dizajnerskie_ser-gi-pusety(gvozdiki).html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/koktejl-nye_kol-ca.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-brilliantovye_braslety.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye_braslety.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-kol-ca-vechnosti-s-kraponovoj-zakrepkoj.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/kol-ca-vechnosti-s-kraponovoj-zakrepkoj.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-kol-ca-vechnosti-s-kanal-noj-zakrepkoj.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/kol-ca-vechnosti-s-kanal-noj-zakrepkoj.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-kol-ca-vechnosti-s-zakrepkoj-pave.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/kol-ca-vechnosti-s-zakrepkoj-pave.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-dizajnerskie_brilliantovye_kol-ca.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/dizajnerskie_brilliantovye_kol-ca.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brillianty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-podveski_bukvy-s-brilliantami_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/podveski_bukvy-s-brilliantami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-galaktika-kollekcija.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/galaktika-kollekcija.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-krugloj-ogranki.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-krugloj-ogranki.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-princessa.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-princessa.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-izumrud.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-izumrud.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-asher.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-asher.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-kaplja.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-kaplja.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-radiant.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-radiant.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-oval.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-oval.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-kushonjuhtml_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-kushonjuhtml/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-serdce.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-serdce.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-brilliant-ogranki-markiz.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/brilliant-ogranki-markiz.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-parnye-brillianty.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/parnye-brillianty.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-cvetnye_brillianty.html_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/cvetnye_brillianty.html/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-zaponki.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/zaponki.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-konfetti-kollekcija.html_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/konfetti-kollekcija.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-brilliantovye-kollekcii_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye-kollekcii/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-brilliantovaja-kollekcija_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovaja-kollekcija/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-brilliantovoe-kole_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovoe-kole/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-brilliantovye-sergi_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/brilliantovye-sergi/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-jewellery_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/diamond-jewellery/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamonds_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/jewellery/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "11_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/diamond-collections/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-eternity-rings_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/eternity-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-dragotsennyye-kamni_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/dragotsennyye-kamni/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gemstones_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/gemstones/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-otzyvy_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/otzyvy/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-laboratornye-brillianty_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/laboratornye-brillianty/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___ru",
    path: indexVxo5R7N7rlMeta?.path ?? "/ru/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-pomolvochnye-kolca_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/pomolvochnye-kolca/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4--бриллиантовые-ожерелья_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/-бриллиантовые-ожерелья/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-yuvelirnye-izdeliya-s-brilliantami_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/yuvelirnye-izdeliya-s-brilliantami/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6--ювелирные-изделия-с-бриллиантами_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/-ювелирные-изделия-с-бриллиантами/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5--серьги-с-бриллиантами_slug___ru",
    path: indexSK19o12TcXMeta?.path ?? "/ru/-серьги-с-бриллиантами/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/es/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos-de-compromiso/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos-de-eternidad/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/alianzas_de_boda/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-alianzas_clasicas_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/alianzas_clasicas/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-alianzas_de_diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/alianzas_de_diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/colgantes_de_diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-colgantes_de_diseno_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/colgantes_de_diseno/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/botones_de_diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-pendientes_aro_de_diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/pendientes_aro_de_diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-pendientes_gota_de_diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/pendientes_gota_de_diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-botones_de_diseno_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/botones_de_diseno/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos_de_cocktail/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-brazaletes_de_diamante_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/brazaletes_de_diamante/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anillos_de_eternidad_engaste_en_garra_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos_de_eternidad_engaste_en_garra/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anillos_de_eternidad_engaste_en_carril_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos_de_eternidad_engaste_en_carril/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anillos_de_diamantes_en_pave_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos_de_diamantes_en_pave/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-anillos_de_diamantes_de_diseno_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/anillos_de_diamantes_de_diseno/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamantes_sueltos/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-coleccion_monologo_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/coleccion_monologo/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-coleccion-galaxy_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/coleccion-galaxy/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-redondo_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-redondo/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-princesa_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-princesa/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-esmeralda_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-esmeralda/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-asscher_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-asscher/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-pera_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-pera/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-radiante_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-radiante/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-oval_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-oval/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-cojin_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-cojin/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-corazon_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-corazon/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamante-corte-marquesa_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamante-corte-marquesa/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantes_sueltos_pares_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamantes_sueltos_pares/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantes_colores_fantasia_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamantes_colores_fantasia/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-gemelos_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/gemelos/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-coleccion-confetti_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/coleccion-confetti/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-wedding-rings_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/wedding-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-colecciones-de-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/colecciones-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-colecciones-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/colecciones-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collares_de_diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/collares_de_diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-pendientes-de-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/pendientes-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-joyeria-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/joyeria-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantes_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/joyeria/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "11_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/colección-diamantes/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-alianzas-de-boda_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/alianzas-de-boda/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-piedras-preciosas_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/piedras-preciosas/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonios_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/testimonios/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-diamantes-de-laboratorio-sueltos_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/diamantes-de-laboratorio-sueltos/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___es",
    path: indexVxo5R7N7rlMeta?.path ?? "/es/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-collares-de-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/collares-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-joyas-con-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/joyas-con-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-joyas-de-diamantes_slug___es",
    path: indexSK19o12TcXMeta?.path ?? "/es/joyas-de-diamantes/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/se/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/forlovningsringar/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/alliansringar/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/vigselringar/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/diamanthalsband/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/diamantorhangen/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/diamantsmycken/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamant_ringar.html_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/pave_diamant_ringar.html/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-diamant-kollektioner_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/diamant-kollektioner/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-rund-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/rund-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-prinsessa-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/prinsessa-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-smaragd-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/smaragd-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/asscher-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-paron-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/paron-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-stralande-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/stralande-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-oval-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/oval-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kudde-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/kudde-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-hjarta-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/hjarta-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-markis-skuren-diamant_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/markis-skuren-diamant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/smycken/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-adelstenar_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/adelstenar/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gemstones_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/gemstones/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-kundreferenser_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/kundreferenser/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-losa-labbodlade-diamanter_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/losa-labbodlade-diamanter/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-old-cut-diamonds_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/old-cut-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-kites-and-shields-diamonds_slug___se",
    path: indexVxo5R7N7rlMeta?.path ?? "/se/kites-and-shields-diamonds/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-engagement-rings_slug___se",
    path: indexSK19o12TcXMeta?.path ?? "/se/engagement-rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    path: shop2DZ7gStQw1Meta?.path ?? "/hu/shop",
    children: [
  {
    name: indexVxo5R7N7rlMeta?.name ?? "12_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/express/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/jegygyuruk/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/orokkevalosagi-gyuruk/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/eskuvoi-gyuruk/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/gyemant-nyaklancok/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/gyemant-fulbevalo/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "5-gyemant-fulbevalok_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/gyemant-fulbevalok/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/gyemant-ekszerek/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "3-pave_diamond_rings_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/pave_diamond_rings/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-gyemantgyujtemenyek_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/gyemantgyujtemenyek/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-csiszolt-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/csiszolt-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-princess-csiszolt-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/princess-csiszolt-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-emerald-csiszolasu-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/emerald-csiszolasu-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-asscher-csiszolasu-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/asscher-csiszolasu-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gyemant-gyongyvagott-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/gyemant-gyongyvagott-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-radians-csiszolasu-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/radians-csiszolasu-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-ovalis-csiszolasu-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/ovalis-csiszolasu-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-parnavagott-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/parnavagott-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-szivbe-vagott-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/szivbe-vagott-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-marquise-csiszolasu-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/marquise-csiszolasu-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-eskuvogyuruk_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/eskuvogyuruk/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gyemant_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/gyemant/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "10_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/ekszerek/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "2-menyasszonyi-gyuruk_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/menyasszonyi-gyuruk/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-gyemantkovek_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/gyemantkovek/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "1-testimonials_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/testimonials/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "4-beszamolok_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/beszamolok/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-laza-laboratoriumi-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/laza-laboratoriumi-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-regen-vagott-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/regen-vagott-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexVxo5R7N7rlMeta?.name ?? "7-sarkanyok-es-pajzsok-gyemantok_slug___hu",
    path: indexVxo5R7N7rlMeta?.path ?? "/hu/sarkanyok-es-pajzsok-gyemantok/:slug(.*)*",
    meta: indexVxo5R7N7rlMeta || {},
    alias: indexVxo5R7N7rlMeta?.alias || [],
    redirect: indexVxo5R7N7rlMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/diamonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamond-collections_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/diamond-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexSK19o12TcXMeta?.name ?? "6-diamant-collections_slug___hu",
    path: indexSK19o12TcXMeta?.path ?? "/hu/diamant-collections/:slug(.*)*",
    meta: indexSK19o12TcXMeta || {},
    alias: indexSK19o12TcXMeta?.alias || [],
    redirect: indexSK19o12TcXMeta?.redirect || undefined,
    component: () => import("/src/pages/shop/index.vue").then(m => m.default || m)
  }
],
    name: shop2DZ7gStQw1Meta?.name ?? undefined,
    meta: shop2DZ7gStQw1Meta || {},
    alias: shop2DZ7gStQw1Meta?.alias || [],
    redirect: shop2DZ7gStQw1Meta?.redirect || undefined,
    component: () => import("/src/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/salat-alard/mayfir",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/salat-alard/maanshstr",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/salat-alard/faraankfourt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/salat-ard/berline",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___ar",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ar/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ar"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/shourum/cyurikh",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/shourum/myunkhen",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/stokkholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___bg",
    path: showroomAQgyL4X8EGMeta?.path ?? "/bg/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"bg"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___cs",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cs/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"cs"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___dk",
    path: showroomAQgyL4X8EGMeta?.path ?? "/dk/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"dk"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/Berlijn",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/munchen",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___nl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/nl/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"nl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___en",
    path: showroomAQgyL4X8EGMeta?.path ?? "/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"en"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/esittelyhuone/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttelyhuone/manchesterissa",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttelyhuone/frankfurtissa",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttelyhuone/berliini",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttely/urich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttely/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/nayttely/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___fi",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fi/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"fi"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/salle-d-exposition/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/salle-d-exposition/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/salle-d-exposition/francfort",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/salle-d-exposition/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___fr",
    path: showroomAQgyL4X8EGMeta?.path ?? "/fr/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"fr"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/ausstellungsraum/munchen",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___de",
    path: showroomAQgyL4X8EGMeta?.path ?? "/de/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"de"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/francoforte",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/berlino",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/zurigo",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/monaco",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___it",
    path: showroomAQgyL4X8EGMeta?.path ?? "/it/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"it"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/mei-fei-er",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/man-qie-si-te",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/fa-lan-ke-fu",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/bai-lin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/su-li-shi",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/zhan-ting/mu-ni-hei",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___cn",
    path: showroomAQgyL4X8EGMeta?.path ?? "/cn/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"cn"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/urich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___no",
    path: showroomAQgyL4X8EGMeta?.path ?? "/no/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"no"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___pl",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pl/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"pl"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___pt",
    path: showroomAQgyL4X8EGMeta?.path ?? "/pt/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"pt"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/urich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/municipiu",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___ro",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ro/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ro"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___ru",
    path: showroomAQgyL4X8EGMeta?.path ?? "/ru/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ru"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/salon-de-exposiciones/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___es",
    path: showroomAQgyL4X8EGMeta?.path ?? "/es/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"es"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/munchen",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___se",
    path: showroomAQgyL4X8EGMeta?.path ?? "/se/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"se"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "727_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/mayfair",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "728_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/manchester",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "729_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/frankfurt",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "730_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/berlin",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "731_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/zurich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "732_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/munich",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "733_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/stockholm",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "784_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/glasgow",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "785_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/dubai",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "786_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/geneva",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomAQgyL4X8EGMeta?.name ?? "788_showroom___hu",
    path: showroomAQgyL4X8EGMeta?.path ?? "/hu/showroom/milan",
    meta: {...(showroomAQgyL4X8EGMeta || {}), ...{"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"hu"}},
    alias: showroomAQgyL4X8EGMeta?.alias || [],
    redirect: showroomAQgyL4X8EGMeta?.redirect || undefined,
    component: () => import("/src/pages/showroom.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___ar",
    path: showroomsogvA3KQX1HMeta?.path ?? "/ar/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ar"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___bg",
    path: showroomsogvA3KQX1HMeta?.path ?? "/bg/izlozhbeni-zali",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"bg"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___cs",
    path: showroomsogvA3KQX1HMeta?.path ?? "/cs/predvadeci-mistnosti",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"cs"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___dk",
    path: showroomsogvA3KQX1HMeta?.path ?? "/dk/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"dk"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___nl",
    path: showroomsogvA3KQX1HMeta?.path ?? "/nl/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"nl"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___en",
    path: showroomsogvA3KQX1HMeta?.path ?? "/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"en"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___fi",
    path: showroomsogvA3KQX1HMeta?.path ?? "/fi/esittelytilat",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"fi"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___fr",
    path: showroomsogvA3KQX1HMeta?.path ?? "/fr/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"fr"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___de",
    path: showroomsogvA3KQX1HMeta?.path ?? "/de/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"de"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___it",
    path: showroomsogvA3KQX1HMeta?.path ?? "/it/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"it"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___cn",
    path: showroomsogvA3KQX1HMeta?.path ?? "/cn/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"cn"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___no",
    path: showroomsogvA3KQX1HMeta?.path ?? "/no/utstillingslokaler",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"no"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___pl",
    path: showroomsogvA3KQX1HMeta?.path ?? "/pl/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"pl"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___pt",
    path: showroomsogvA3KQX1HMeta?.path ?? "/pt/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"pt"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___ro",
    path: showroomsogvA3KQX1HMeta?.path ?? "/ro/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ro"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___ru",
    path: showroomsogvA3KQX1HMeta?.path ?? "/ru/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ru"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___es",
    path: showroomsogvA3KQX1HMeta?.path ?? "/es/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"es"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___se",
    path: showroomsogvA3KQX1HMeta?.path ?? "/se/showrooms",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"se"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: showroomsogvA3KQX1HMeta?.name ?? "726_showrooms___hu",
    path: showroomsogvA3KQX1HMeta?.path ?? "/hu/bemutatotermek",
    meta: {...(showroomsogvA3KQX1HMeta || {}), ...{"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"hu"}},
    alias: showroomsogvA3KQX1HMeta?.alias || [],
    redirect: showroomsogvA3KQX1HMeta?.redirect || undefined,
    component: () => import("/src/pages/showrooms.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___ar",
    path: successrwNTsVp6BfMeta?.path ?? "/ar/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"ar"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___bg",
    path: successrwNTsVp6BfMeta?.path ?? "/bg/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"bg"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___cs",
    path: successrwNTsVp6BfMeta?.path ?? "/cs/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"cs"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___dk",
    path: successrwNTsVp6BfMeta?.path ?? "/dk/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"dk"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___nl",
    path: successrwNTsVp6BfMeta?.path ?? "/nl/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"nl"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___en",
    path: successrwNTsVp6BfMeta?.path ?? "/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"en"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___fi",
    path: successrwNTsVp6BfMeta?.path ?? "/fi/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"fi"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___fr",
    path: successrwNTsVp6BfMeta?.path ?? "/fr/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"fr"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___de",
    path: successrwNTsVp6BfMeta?.path ?? "/de/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"de"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___it",
    path: successrwNTsVp6BfMeta?.path ?? "/it/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"it"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___cn",
    path: successrwNTsVp6BfMeta?.path ?? "/cn/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"cn"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___no",
    path: successrwNTsVp6BfMeta?.path ?? "/no/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"no"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___pl",
    path: successrwNTsVp6BfMeta?.path ?? "/pl/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"pl"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___pt",
    path: successrwNTsVp6BfMeta?.path ?? "/pt/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"pt"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___ro",
    path: successrwNTsVp6BfMeta?.path ?? "/ro/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"ro"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___ru",
    path: successrwNTsVp6BfMeta?.path ?? "/ru/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"ru"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___es",
    path: successrwNTsVp6BfMeta?.path ?? "/es/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"es"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___se",
    path: successrwNTsVp6BfMeta?.path ?? "/se/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"se"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successrwNTsVp6BfMeta?.name ?? "3_success___hu",
    path: successrwNTsVp6BfMeta?.path ?? "/hu/Success.aspx",
    meta: {...(successrwNTsVp6BfMeta || {}), ...{"pageId":3,"aspRoute":"/Success.aspx","locale":"hu"}},
    alias: successrwNTsVp6BfMeta?.alias || [],
    redirect: successrwNTsVp6BfMeta?.redirect || undefined,
    component: () => import("/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___ar",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/ar/alddaraaib.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ar"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___bg",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/bg/taxduty",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"bg"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___cs",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/cs/danove-poplatky",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"cs"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___dk",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/dk/told-og-skat.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"dk"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___nl",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/nl/belasting.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"nl"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___en",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/taxduty.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"en"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___fi",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/fi/taxduty",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"fi"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___fr",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/fr/droit-de-douane.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"fr"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___de",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/de/steuern-und-abgaben.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"de"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___it",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/it/tasseimposte.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"it"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___cn",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/cn/shui-fei.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"cn"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___no",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/no/skatteplikt",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"no"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___pl",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/pl/podatekclo.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"pl"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___pt",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/pt/taxduty.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"pt"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___ro",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/ro/taxduty",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ro"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___ru",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/ru/nalogovaja_poshlina.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ru"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___es",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/es/impuesto-fiscal",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"es"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___se",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/se/skatt-tull.html",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"se"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  },
  {
    name: taxdutyBR7X1rnfxPMeta?.name ?? "75_taxduty___hu",
    path: taxdutyBR7X1rnfxPMeta?.path ?? "/hu/taxduty",
    meta: {...(taxdutyBR7X1rnfxPMeta || {}), ...{"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"hu"}},
    alias: taxdutyBR7X1rnfxPMeta?.alias || [],
    redirect: taxdutyBR7X1rnfxPMeta?.redirect || undefined,
    component: () => import("/src/pages/taxduty.vue").then(m => m.default || m)
  }
]